import { SendOutlined } from "@ant-design/icons";
import { Col, Input, Row, Spin, message } from "antd";
import useAxios from "axios-hooks";
import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { useHistory } from "react-router-dom";
import fetch_ from "../../../interceptors/FetchInterceptor";
import useAnalyticsEventTracker from "../../../useAnalyticsEventTracker";

const AssistanceChat = ({ props }) => {
  const gaEventTracker = useAnalyticsEventTracker("Assistance Chat");
  const mobileView = window.innerWidth < 1200;
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [chatData, setChatData] = useState([]);
  const [{ data = [] }] = useAxios(`/register-applicants/form-fields`);

  const userInfoString = localStorage.getItem("userInfo");
  let userInfo = {};
  if (userInfoString) {
    try {
      userInfo = JSON.parse(userInfoString);
    } catch (err) {
      console.error("Error parsing userInfo:", err);
    }
  }
  const fullName = userInfo.firstName?.toUpperCase() + " " + userInfo.lastName;

  const fieldOfStudyId = data?.filter((e) => e.key === "fieldOfStudy");
  const fieldOfStudy = userInfo?.applicantFields?.filter(
    (e) => e.fieldId === fieldOfStudyId[0]?.id
  )[0];
  const highSchoolNameId = data?.filter((e) => e.key === "highSchoolName");
  const highSchoolName = userInfo?.applicantFields?.filter(
    (e) => e.fieldId === highSchoolNameId[0]?.id
  )[0];
  const highSchoolNameValue =
    highSchoolName?.field?.options[highSchoolName?.fieldValue];
  const fieldOfStudyValue =
    fieldOfStudy?.field?.options[fieldOfStudy?.fieldValue];

  const history = useHistory();

  useEffect(() => {
    if (chatData.length === 0) {
      setMsg(history?.location?.state?.question);
      onMsgSend();
    }
  });

  const onMsgSend = () => {
    if (loading || msg === "") {
      return;
    }
    setChatData([...chatData, { msg: msg, type: "USER" }]);
    writeEssay(msg);
  };

  const writeEssay = (msg) => {
    setLoading(true);

    let updateData = {
      prompt: msg,
    };

    fetch_({
      url: `/register-applicants/get-essay`,
      method: "POST",
      data: updateData,
    })
      .then(({ data }) => {
        // console.log("assistance data", data);
        // data.usage_limit_message
        //   ? message.error(
        //       "Oops!!You have reached your daily usage limit. Please try again tomorrow or contact support to increase your limit."
        //     )
        //   : "";
        if (data.usage_limit_message) {
          message.error(
            "Oops!!You have reached your daily usage limit. Please try again tomorrow or contact support to increase your limit."
          );
        }
        setChatData([
          ...chatData,
          { msg: msg, type: "USER" },
          { msg: data.content, type: "AI" },
        ]);
        setMsg("");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const msgView = (e) => {
    return (
      <Row style={{ marginBottom: "30px" }}>
        <Col style={{ width: mobileView ? "5%" : "3%" }}>
          {e.type === "USER" ? (
            <Avatar round={true} size="28" name={fullName} />
          ) : (
            <img src="/applicantImg/eyess.png" style={{ width: "30px" }} />
          )}
        </Col>
        <Col
          style={{
            maxWidth: mobileView ? "88%" : "95%",
            background: e.type === "AI" && "#F0FAFF",
            marginLeft: "15px",
            borderWidth: 1,
            borderStyle: "double",
            paddingTop: "10px",
            paddingLeft: "8px",
            paddingRight: "8px",
            paddingBottom: "10px",
            borderColor: "rgba(29, 37, 45, 0.13)",
            borderRadius: "10px",
          }}
        >
          <span style={{ marginLeft: "4px" }}>{e.msg}</span>
        </Col>
      </Row>
    );
  };

  let divheight = window.innerHeight - 150;
  return (
    <div
      style={{
        background: "#ffffff",
        padding: mobileView ? "20px" : "20px",
        width: mobileView && window.innerWidth,
        height: mobileView && "100%",
      }}
    >
      <a
        onClick={() => {
          history.goBack();
        }}
        style={{ color: "#1D252D" }}
      >
        <img
          src="/applicantImg/back.png"
          style={{ height: "20px", marginRight: "4px" }}
        />
      </a>
      <div
        style={{
          height: mobileView ? divheight + "px" : "380px",
          overflowX: "hidden",
          paddingRight: mobileView ? "0px" : "100px",
          marginTop: "20px",
          paddingBottom: mobileView && "30px",
        }}
      >
        {chatData.map((e) => msgView(e))}
        {loading && <Spin />}
      </div>
      <div
        style={{
          marginTop: "30px",
          paddingBottom: "0px",
          paddingRight: mobileView ? "10px" : "100px",
          position: mobileView && "absolute",
          bottom: mobileView && "10px",
          width: mobileView && "-webkit-fill-available",
        }}
      >
        <Input
          placeholder=" Send a message"
          disabled={loading}
          suffix={<SendOutlined onClick={onMsgSend} className="text-primary" />}
          value={msg}
          onChange={(e) => {
            setMsg(e.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default AssistanceChat;
