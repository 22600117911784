export default {
  email: [
    {
      required: true,
      message: "Please input your email address!",
    },
    {
      type: "email",
      message: "Please enter a validate email!",
    },
  ],
  firstName: [
    {
      required: true,
      message: "Please input your first name!",
    },
  ],
  lastName: [
    {
      required: true,
      message: "Please input your last name!",
    },
  ],
  contactNo: [
    {
      required: true,
      message: "Please input your phone number!",
    },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (
          !value ||
          getFieldValue("contactNo").phone === undefined ||
          getFieldValue("contactNo").phone === ""
        ) {
          return Promise.reject("Please input your phone number!");
        } else if (getFieldValue("contactNo").phone.match(/^[0-9]+$/) != null) {
          return Promise.resolve();
        }
        return Promise.reject("Please enter only digits!");
      },
    }),
  ],
  password: [
    {
      required: true,
      message: "Please input your password",
    },
  ],
  confirm: [
    {
      required: true,
      message: "Please confirm your password!",
    },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (!value || getFieldValue("password") === value) {
          return Promise.resolve();
        }
        return Promise.reject("Passwords do not match!");
      },
    }),
  ],
  citizenship: [
    {
      required: true,
      message: "Please select your citizenship!",
    },
  ],
  country: [
    {
      required: true,
      message: "Please select your country!",
    },
  ],
  ethnicity: [
    {
      required: true,
      message: "Please fill your ethnicity!",
    },
  ],
  gender: [
    {
      required: true,
      message: "Please select your gender!",
    },
  ],
  currentSchoolLevel: [
    {
      required: true,
      message: "Please fill your current school level!",
    },
  ],
  highSchoolName: [
    {
      required: true,
      message: "Please fill your high school name!",
    },
  ],

  collegeName: [
    {
      required: true,
      message: "Please fill your college name!",
    },
  ],

  fieldOfStudy: [
    {
      required: true,
      message: "Please fill your field of study!",
    },
  ],
  degreeType: [
    {
      required: true,
      message: "Please fill your degree type!",
    },
  ],
  gpa: [
    {
      required: true,
      message: "Please fill your GPA/Percentage",
    },
  ],
  gpaInput: [
    {
      required: true,
      message: "Please fill your GPA/Percentage",
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        const markingSystem = getFieldValue("markingSystem");

        if (markingSystem === "Percentage") {
          return validatePercentage(value);
        } else if (markingSystem === "10CGPA") {
          return validateGPA(value);
        }

        // Add any additional rules for userinput as needed

        // No validation errors
        return Promise.resolve();
      },
    }),
  ],

  markingSystem: [
    {
      required: true,
      message: "Please Select your Marking System!",
    },
  ],
  careerGoal: [
    {
      required: true,
      message: "Please fill your carrer goal!",
    },
  ],
  militaryAffiliation: [
    {
      required: true,
      message: "Please fill your military affiliation!",
    },
  ],
  currentSchoolLevel: [
    {
      required: true,
      message: "Please fill current school level!",
    },
  ],
  fieldOfStudy: [
    {
      required: true,
      message: "Please fill field of study!",
    },
  ],
};
const validatePercentage = (value) => {
  // Custom rule for Percentage
  if (isNaN(value) || +value < 0 || +value > 100) {
    return Promise.reject("Enter a valid percentage between 0 and 100");
  }
  // No validation errors
  return Promise.resolve();
};

const validateGPA = (value) => {
  // Custom rule for 10GPA
  if (isNaN(value) || +value < 0 || +value > 10) {
    return Promise.reject("Enter a valid GPA between 0 and 10");
  }
  // No validation errors
  return Promise.resolve();
};
