import FilterApply from "./FilterApply.js";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { Tabs } from "antd";
import queryString from "query-string";

const MyScholarship = ({ props }) => {
  const items = [
    { label: "Applied", key: "applied", children: "Content 1" },
    { label: "Draft", key: "draft", children: "Content 2" },
  ];

  const history = useHistory();
  const { search } = useLocation();
  const urlValues = queryString.parse(search);

  /*on tab change updating url*/
  const onTabClickFunc = (clickedTab) => {
    history.push({
      pathname: history.location.pathname,
      search: `?tab=${clickedTab}`,
    });
  };

  return(
  	<div style = {{alignSelf: "center"}}>
	    <Tabs
	        items={items}
	        tabBarStyle={{alignSelf: "center"}}
	        activeKey={urlValues?.tab}
	        onChange={(key) => onTabClickFunc(key)}
	      >
	        <Tabs.TabPane tab="Applied" key="applied">
	          <FilterApply type={"myscholarship/applied"} props={props} />
	        </Tabs.TabPane>
	        <Tabs.TabPane tab="Draft" key="draft">
	          <FilterApply type={"myscholarship/draft"} props={props} />
	        </Tabs.TabPane>
	     </Tabs>
     </div>
    )
};

export default MyScholarship;