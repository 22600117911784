import { Modal, Typography } from "antd";
import React from "react";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";

const ThankYouModal = ({ open, handleOnCloseModal }) => {
  const mobileView = window.innerWidth < 750;

  return (
    // <Modal visible={open} onCancel={handleOnCloseModal} footer={null}>
    //   <div
    //     style={{
    //       display: "flex",
    //       flexDirection: "column",
    //       justifyContent: "center",
    //       alignItems: "center",
    //     }}
    //   >
    //     <img
    //       className="img-fluid"
    //       src="/img/others/img-22.png"
    //       alt=""
    //       height="140"
    //       width="140"
    //     />
    //     <Typography.Title level={2} className=".my-4">
    //       Thank You!
    //     </Typography.Title>
    //     <Typography.Paragraph level={1}>
    //       Thanks for submitting the application!
    //     </Typography.Paragraph>
    //   </div>
    // </Modal>
    <>
      {open && (
        <div className="popup-overlay">
          <div
            className="popup-content"
            style={{
              minWidth: "60%",
              minHeight: "60%",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            <div
              // onClick={toggleScholarshipPopup}
              style={{
                color: "rgb(113, 122, 245)",
                fontSize: "24px",
                cursor: "pointer",
                float: "right",
                lineHeight: "normal",
                marginRight: "10px",
                marginTop: "10px",
                display: "none",
              }}
            >
              {" "}
              <CloseCircleFilled />
            </div>
            <div
              style={
                {
                  // borderRadius: "8px"
                }
              }
            >
              <div
                style={{
                  backgroundImage: "url('/images/grp-bg.png')",
                }}
              >
                <h1
                  style={{
                    textAlign: "center",
                    marginTop: "25px",
                  }}
                >
                  Congratulations{" "}
                  <span>
                    <img src="/images/cone.png" alt="" />
                  </span>{" "}
                </h1>
                <div
                  style={{
                    width: mobileView ? "200px" : "240px",
                    margin: "auto",
                  }}
                >
                  <img
                    src="/new/registerLogo.png"
                    alt="robo"
                    style={{
                      width: "100%",
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontWeight: "500",
                    color: "black",
                    fontSize: mobileView ? "16px" : "20px",
                    margin: "0 0 5px 0",
                    textAlign: "center",
                    maxWidth: "80%",
                  }}
                >
                  Thank you for applying the scholarship! We appreciate your
                  interest and wish you the best of luck.
                </p>
                <button
                  style={{
                    borderRadius: "8px",
                    width: "max-content",
                    padding: "0",
                    backgroundColor: "#8D96FF",
                    color: "white",
                    fontSize: mobileView ? "13px" : "17px",
                    fontWeight: "500",
                    padding: "10px 12px",
                    lineHeight: "normal",
                    marginBottom: "15px",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleOnCloseModal();
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ThankYouModal;
