import "./css/slideshow.css";
import React, { useState, useEffect } from "react";

const SignupSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Dynamic content that could be fetched from an API or passed as props
  const content = [
    {
      title: "🎉 Daily Reward Unlocked! 🎉",
      lines: [
        "With each daily login, you unlock 3 scholarships—completely free! ",
      ],
    },
    {
      title: "🔓 Unlock Essay Assistance with a paid Subscription! 📝",
      lines: [
        "💰 Hundreds of thousands of award money are currently available",
        "🤖 Superb Lenny helps with:",
        "Essay writing",
        "Finding Financial Aid",
        "Everything on a Students' Mind📝",
      ],
    },
    {
      title: "🤝 Refer and Earn Scholarships! 🎁",
      lines: [
        "🤝 Invite friends to Lenny.ai and unlock more opportunities! 🎓",
      ],
    },
  ];

  // Autoplay functionality: change slide every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % content.length);
    }, 3000); // Change every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [content.length]);

  return (
    <div
      className="carousel-container"
      style={{
        backgroundColor: "rgba(141, 150, 255, 0.2)",
        borderRadius: "0px 24px 24px 0px",
        border: "4px solid rgb(195 197 216)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div className="carousel">
        <div className="carousel-slide">
          <div className="carousel-card">
            <h3
              style={{
                fontWeight: "500",
                fontSize: "22px",
              }}
            >
              {content[currentIndex].title}
            </h3>
            <ul
              style={{
                listStyle: "none",
              }}
            >
              {content[currentIndex].lines.map((line, index) => (
                <li
                  key={index}
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                  }}
                >
                  {line}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      {/* Dots for carousel */}
      <div className="dots-container">
        {content.map((_, index) => (
          <div
            key={index}
            className={`dot ${currentIndex === index ? "active" : ""}`}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default SignupSlider;
