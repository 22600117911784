const mobileView = window.innerWidth < 481;

export default {
  footer: {
    padding: "3% 10%",
    background: "#1D252D",
    display: "flex",
    flexDirection: mobileView ? "column" : "row",
    justifyContent: "space-between",
  },
  footerImage: {
    height: mobileView ? "" : "30%",
    width: mobileView ? "100%" : "",
  },
  footerMenu: {
    background: "#1D252D",
    borderRight: "0",
  },
  footerText: {
    color: "white",
    fontSize: "16px",
    fontWeight: "400",
  },
};
