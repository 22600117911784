import React, { useState } from "react";
import { Row, Col, Button, Checkbox } from "antd";
import DOMPurify from "dompurify";
import moment from "moment";

export const MsgSummary = ({
  history,
  props,
  data,
  viewIdx,
  onChangeViewIdx,
}) => {
  const mobileView = window.innerWidth < 1200;

  return (
    <div
      style={{
        background: "#FFFFFF",
        padding: "15px",
      }}
    >
      {mobileView && (
        <a
          onClick={() => {
            props.setSideBars(false);
            history.goBack();
          }}
          style={{ color: "#1D252D" }}
        >
          <img
            src="/applicantImg/back.png"
            style={{ height: "18px", marginRight: "4px" }}
          />
        </a>
      )}
      <h2 style={{ fontSize: "18px", fontWeight: "600" }}>
        {data?.subject?.S}
      </h2>
      <Row
        style={{
          borderBottomWidth: "1px",
          borderBottomStyle: "double",
          borderColor: "rgba(29, 37, 45, 0.13)",
        }}
      >
        <Col span={24}>
          <div>
            <span style={{ fontSize: "14px", fontWeight: "500" }}>
              {data?.fromName?.S}
            </span>
            {data?.sentDate && (
              <span
                style={{ float: "right", fontSize: "12px", fontWeight: "100" }}
              >
                {moment(data?.sentDate?.S).format("DD MMMM, YYYY")}
              </span>
            )}
          </div>
          <p>{data?.fromEmail?.S}</p>
        </Col>
      </Row>

      <div
        style={{
          borderBottomWidth: "1px",
          borderBottomStyle: "double",
          borderColor: "rgba(29, 37, 45, 0.13)",
          height: window.innerHeight - 220,
          overflowY: "auto",
        }}
      >
        <p
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(data?.bodyAsHtml?.S),
          }}
        ></p>
      </div>
      {onChangeViewIdx && (
        <>
          <img
            src="/applicantImg/left.png"
            style={{ width: "40px", marginTop: "12px", cursor: "pointer" }}
            onClick={() => onChangeViewIdx(viewIdx - 1)}
          />
          <img
            src="/applicantImg/right.png"
            style={{
              width: "40px",
              marginTop: "12px",
              marginLeft: "10px",
              cursor: "pointer",
            }}
            onClick={() => onChangeViewIdx(viewIdx + 1)}
          />
        </>
      )}
    </div>
  );
};
