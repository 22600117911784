import { GoogleOAuthProvider } from "@react-oauth/google";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { Provider } from "react-redux";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { THEME_CONFIG } from "./configs/AppConfig";
import store from "./redux/store";
import Views from "./views";
import Hotjar from "Hotjar";

import "./interceptors/FetchInterceptor";
import { AssistanceChatBotProvider } from "context/ChatbotContext";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

const App = () => {
  return (
    <div className="App">
      <GoogleOAuthProvider clientId="965974145618-o3rgt8rb1st0dvsg0lm4rhg5lm3n598t.apps.googleusercontent.com">
        {/* <GoogleOAuthProvider clientId="952886769331-4e5dv638ol7oh495cnmej68tfcf9nsbv.apps.googleusercontent.com"> */}
        <Provider store={store}>
          <ThemeSwitcherProvider
            themeMap={themes}
            defaultTheme={THEME_CONFIG.currentTheme}
            insertionPoint="styles-insertion-point"
          >
            <Hotjar />
            <AssistanceChatBotProvider>
              <Router>
                <Switch>
                  <Route path="/" component={Views} />
                </Switch>
              </Router>
            </AssistanceChatBotProvider>
          </ThemeSwitcherProvider>
        </Provider>
      </GoogleOAuthProvider>
    </div>
  );
};

export default App;
