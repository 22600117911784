import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const HtmlPopup = ({ buttonLabel, htmlContent }) => {
  const mView = window.innerWidth < 500;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showCustomClose, setShowCustomClose] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setShowCustomClose(false);
  };

  const handleScroll = (e) => {
    const target = e.target;
    setShowCustomClose(target.scrollTop > 0);
  };

  useEffect(() => {
    const modalContent = document.querySelector(".ant-modal-body");
    if (modalContent) {
      modalContent.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (modalContent) {
        modalContent.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isModalVisible]);

  return (
    <>
      <Button
        disabled={isModalVisible}
        style={{
          marginRight: "10px",
          width: mView ? "100%" : "auto",
          // minWidth: "150px",
          float: "right",
          borderColor: "#8D96FF",
          color: "black",
          borderRadius: "8px",
          height: "40px",
          marginTop: mView && "8px",
        }}
        onClick={showModal}
      >
        {buttonLabel}
      </Button>
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null} // Remove the footer (OK and Cancel buttons)
        closeIcon={null} // Remove default close icon
        centered // Center the modal
        width="80%"
        bodyStyle={{
          height: "80vh",
          overflowY: "auto",
          padding: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #f0f0f0",
            paddingBottom: "10px",
            marginBottom: "20px",
          }}
        >
          {/* <h3 style={{ marginBottom: 0, fontWeight: 700 }}>
            {buttonLabel} */}
          {/* </h3> */}
          {false && (
            <Button
              type="text"
              icon={<CloseOutlined />}
              onClick={handleCancel}
            />
          )}
        </div>
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </Modal>
    </>
  );
};

export default HtmlPopup;
