import React from "react";
import "./css/chatbot.css";

const BouncingDotsLoader = (props) => {
  return (
    <div className="bouncing-loader">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default BouncingDotsLoader;
