/* eslint-disable import/no-anonymous-default-export */
import "../../../styleGuide/colors.less";
const mobileView = window.innerWidth < 481;
const mView = window.innerWidth < 1000;
const tabView = window.innerWidth < 961;

export default {
  column1st: tabView ? {} : { width: "40%", marginRight: "15px" },
  column2nd: tabView ? {} : { width: "40%", marginLeft: "15px" },
  buttonText: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    textAlign: "center",
    letterSpacing: "0em",
    textAlign: "center",
  },
  register2ButtonStyle: {
    width: tabView ? "" : "296px",
    height: "48px",
    textAlignLast: "center",
    backgroundColor: "#8D96FF",
    borderRadius: "0.625rem",
    margin: "4% 0",
  },
  buttonStyle: {
    width: mobileView ? "150px" : "200px",
    height: "auto",
    marginRight: tabView ? "0" : "0",
    textAlignLast: "center",
    marginTop: "12px",
    marginBottom: "10px",
    backgroundColor: "#8D96FF",
    borderColor: "#8D96FF",
    borderRadius: "8px",
    padding: "8px",
  },
  congratulations: {
    marginTop: "20px",
    fontWeight: "600",
    // fontSize: '36px',
    lineHeight: "44px",
    display: "flex",
    // marginBottom: "20px",
    justifyContent: "center",
    // width: '70%',
  },
  great: {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: "500",
    lineHeight: "36px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "black",
  },
  heading3: {
    fontFamily: "Poppins",
    fontSize: mobileView ? "26px" : "32px",
    fontWeight: "600",
    lineHeight: "38px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "black",
  },
  headingText: {
    display: "flex",
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: mobileView ? "16px" : "18px",
    lineHeight: "32px",
    color: "#1D252D",
    letterSpacing: "0em",
    textAlign: "center",
    justifyContent: "center",
  },
  headingText2: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: mobileView ? "18px" : "20px",
    lineHeight: "32px",
    color: "#1D252D",
  },
  extraInformation: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "black",
  },
  infoBox: {
    background: "#DFE1FF",
    borderRadius: "5px",
    width: "30%",
    padding: "10px",
    marginTop: "15px",
    marginRight: "10px",
  },
  boxHead: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  boxSubHead: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "21px",
    letterSpacing: "0em",
    textAlign: "right",
  },
  box: {
    border: "1px",
    borderStyle: "solid",
    borderRadius: "5px",
    width: "250px",
    padding: "10px",
    marginTop: "155px",
  },
  leftBox: {
    background: "#C0E1FF",
    position: "absolute",
    width: mobileView ? "" : "180px",
    height: mobileView ? "70px" : "",
    justifyContent: "center",
    textAlignLast: "center",
    borderColor: "#C0E1FF",
    padding: "4px",
    marginTop: !mobileView ? "430px" : "0px",
    flexDirection: "row",
  },
  circle: {
    borderStyle: "double",
    borderRadius: "41px",
    borderWidth: "1px",
    padding: "7px",
    marginTop: "10px",
    marginBottom: "10px",
    marginRight: "5px",
    marginLeft: "5px",
  },
  r3Column1: {
    width: mobileView ? "100%" : "50%",
    paddingLeft: mobileView ? "" : "20px",
  },
  detail: {
    alignItems: "center",
    padding: "10px 15px",
    fontSize: "20px",
    fontWeight: "500",
    lineHeight: "24px",
    fontFamily: "Poppins",
    lineHeight: "30px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  detailDiv: {
    marginBottom: "20px",
    marginTop: "10px",
  },
  circleImg: mobileView
    ? {
        overflow: "hidden",
        width: window.innerWidth,
        height: mobileView ? "550px" : "700px",
      }
    : {
        textAlign: "center",
        width: tabView ? "" : "50%",
      },
  maleImg: mobileView
    ? {
        position: "absolute",
        width: window.innerWidth + 50,
        left: "5px",
      }
    : {
        height: "549px",
        height: "549px",
        display: "block",
        marginBottom: "20px",
        position: "absolute",
        top: "30px",
      },
};
