import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

const useGaTracker = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      ReactGA.initialize("G-ZQ79EJ2NXJ");
    } else {
      ReactGA.initialize("G-ZQ79EJ2NXJ");
    }
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      // ReactGA.pageview(location.pathname + location.search);
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname + location.search,
      });
    }
  }, [initialized, location]);
};

export default useGaTracker;
