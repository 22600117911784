import React, { useState } from "react";
import { Row } from "antd";
import { useSelector } from "react-redux";
import { FilterList } from "./FilterList.js";

export const FilterBox = ({
  history,
  data,
  loading,
  onCheckboxChange,
  hideCheckbox,
  ...props
}) => {
  const theme = useSelector((state) => state.theme.currentTheme);
  const [screenHolder, setScreenHolder] = useState(0);

  let i = 0;

  const renderView = (el) => {
    if (
      props.type === "myscholarship/draft" &&
      el.scholarshipStatus === "Received"
    ) {
      return <div />;
    }

    if (
      props.type === "myscholarship/applied" &&
      el.scholarshipStatus === "Draft"
    ) {
      return <div />;
    }

    return (
      <FilterList
        data={el}
        history={history}
        onCheckboxChange={onCheckboxChange}
        element={i++}
        hideCheckbox={hideCheckbox}
        {...props}
      />
    );
  };

  return (
    <div style={{ textAlign: "left" }}>
      {data?.map((el) => {
        return (
          <div
            className={` ${theme === "light" ? "bg-white" : ""}`}
            style={{ marginTop: "0px" }}
          >
            <div className="d-flex flex-column h-100">{renderView(el)}</div>
          </div>
        );
      })}
    </div>
  );
};
