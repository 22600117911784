export const getInitialValues = (data) => {
  if (!data) return;

  return data?.reduce((acc, item) => {
    return {
      ...acc,
      [item?.field?.key]: item.fieldValue,
    };
  }, {});
};

export const createPayload = (values, data) => {
  const requirements = data?.requirements?.map((item) => {
    return {
      ...item,
      fieldValue:
        values?.[item?.field?.key]?.text ||
        values?.[item?.field?.key]?.url?.publicUrl ||
        values?.[item?.field?.key] ||
        values?.[item?.field?.name]?.url?.publicUrl ||
        "",
    };
  });

  return {
    requirements,
    scholarshipId: data?.id,
    status:
      Object.keys(values)?.length === data?.requirements?.length
        ? "Received"
        : "Draft",
  };
};
