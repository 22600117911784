export const API_BASE_URL = "";
export const FREE_SCHOLARSHIP_COUNT = 3;

// Config
export const ENTRY_ROUTE = "/";
export const TOKEN_PAYLOAD_KEY = "authorization";
export const PUBLIC_REQUEST_KEY = "public-request";

export const AUTH_TOKEN_KEY = "token";
export const REFRESH_TOKEN_KEY = "refresh_token";
export const USER_INFO_KEY = "userInfo";
export const USER_ACCESS_KEY = "user_access";

export const ROLE_TYPE = {
  ADMIN: "admin",
  BUSINESS: "business",
};
export const ROLES = [
  { label: "Admin", val: "admin" },
  { label: "Business", val: "business" },
];

export const SYSTEM_ROLES = [
  { label: "Admin", val: "admin" },
  { label: "Business", val: "business" },
];

export const DEFAULT_PAGE_SIZE = 10;

export const ADD = "ADD";
export const EDIT = "EDIT";

export const UPLOAD_DRAG_PROPS = {
  name: "file",
  multiple: true,
  listType: "picture-card",
  showUploadList: false,
  action: null,
};

export const API_PATH = {
  USER_LOGIN: "/users/auth/login",
  USER_GOOGLE_LOGIN: "/users/auth/google",
  USER_REGISTER: "/register-applicants",
  USER_LOGOUT: "/users/auth/logout",
  USER_PASSWORD_LINK: "/users/auth/reset-password-link",
  USER_PASSWORD: "/users/auth/reset-password",
  EligibilityStats: "/scholarships/eligible-scholarships/stats",

  UPDATE_USER_APPLICANT: (applicantId) => `/register-applicants/${applicantId}`,

  USERS_PROFILE: "/users/me",
  USERS_PROFILE_UPDATE: "/users/profile",
  USERS_ORGAISATION_UPDATE: "/users/organisation",

  SCHOLARSHIP_LIST: `/scholarships`,
  SCHOLARSHIP_DETAIL_BY_ID: (scolarshipId) => `/scholarships/${scolarshipId}`,
  SCHOLARSHIP_APPLICANTS: (scolarshipId) =>
    `/scholarships/${scolarshipId}/applicants`,
  SCHOLARSHIP_WINNERS: (scolarshipId) =>
    `/scholarships/${scolarshipId}/winners`,
  SCHOLARSHIP_APPLICANTS_EXPORT: (scolarshipId) =>
    `/scholarships/${scolarshipId}/applicants-data/export`,
  SCHOLARSHIP_DETAIL: (scolarshipSlug) =>
    `/scholarships/slug/${scolarshipSlug}`,
  SCHOLARSHIP_REQUIREMENT: (scolarshipId) =>
    `/scholarship/${scolarshipId}/requirement`,
  SCHOLARSHIP_REQUIREMENT_CRITERIA: (scolarshipId) =>
    `/scholarship/${scolarshipId}/criteria`,
  SUBMIT_APPLICATION: (scolarshipId) =>
    `scholarships/${scolarshipId}/applicants`,
  SUBMIT_INDIVIDUAL_SCHOLARSHIP: () =>
    `scholarships/submit-individual-scholarship`,

  APPLICANT_DETAIL: (scolarshipId, applicantId) =>
    `/scholarships/${scolarshipId}/applicants/${applicantId}`,
  UPDATE_APPLICANT: (scolarshipId, applicantId) =>
    `/scholarships/${scolarshipId}/applicants/${applicantId}`,
  DELETE_CRITERIA: (scolarshipId, scholarshipCriteriaId) =>
    `/scholarship/${scolarshipId}/criteria/${scholarshipCriteriaId}`,
  GET_PUBLIC_URL: `common/public-link`,
  GET_SCHOLARSHIP_REQUIREMENTS: (scolarshipId) =>
    `/scholarship/${scolarshipId}/requirement`,
  GET_SCHOLARSHIP_CRITERIA: (scolarshipId) =>
    `/scholarship/${scolarshipId}/criteria`,
  GET_SCHOLARSHIP_CRITERIA_POINTS: (scolarshipId, applicantId) =>
    `/scholarship/${scolarshipId}/applicants/${applicantId}/points`,
  SET_SCHOLARSHIP_CRITERIA_POINTS: (scolarshipId, applicantId) =>
    `/scholarship/${scolarshipId}/applicants/${applicantId}/points`,
};
