import { Alert, Button, Form, Input, Menu, Modal } from "antd";
import { motion } from "framer-motion";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { UserAuthService } from "../../../../services/user-auth.service";

export const RightMenu = ({ viaDrawer }) => {
  // console.log("🚀 ~ RightMenu ~ viaDrawer:", viaDrawer);
  const [modal1Open, setModal1Open] = useState(false);
  const mobileView = window.innerWidth < 960;
  const history = useHistory();
  const [alertShow, setAlertShow] = useState();
  const [alertMessage, setAlertMessage] = useState();
  const [alertType, setAlertType] = useState(true);

  const pathName = history.location.pathname.includes("lennyAssistance");

  const onLogin = (values) => {
    const login_info = { ...values, loginType: "register_user" };
    setAlertShow(false);
    UserAuthService.login(login_info)
      .then(({ data }) => {
        localStorage.setItem("clientIp", data?.clientIp);
        // console.log("🚀 ~ .then ~ data:", data?.clientIp);

        localStorage.removeItem("register1");
        localStorage.removeItem("register2");
        localStorage.setItem(
          "userInfo",
          typeof data.user === "string" ? data.user : JSON.stringify(data.user)
        );
        localStorage.setItem(
          "refresh_token",
          typeof data.refreshToken === "string"
            ? data.refreshToken
            : JSON.stringify(data.refreshToken)
        );
        localStorage.setItem(
          "token",
          typeof data.accessToken === "string"
            ? data.accessToken
            : JSON.stringify(data.accessToken)
        );
        localStorage.setItem(
          "isActiveSubscription",
          data.user.isActiveSubscription
        );
        history.push("/auth/dashboard");
        // window.location.reload();
      })
      .catch((info) => {
        setAlertShow(true);
        setAlertMessage(info.description || "Server Error");
        setAlertType(false);
      })
      .finally(() => {});
  };

  const setLocalSignUpData = () => {
    localStorage.setItem("login", false);

    localStorage.setItem("signUp", true);
  };
  const setLocalLoginData = () => {
    localStorage.setItem("signUp", false);

    localStorage.setItem("login", true);
  };
  return (
    <>
      <Modal
        title=""
        centered
        visible={modal1Open}
        onOk={() => setModal1Open(false)}
        footer={null}
        onCancel={() => setModal1Open(false)}
      >
        <div
          style={{
            paddingTop: "10px",
            paddingLeft: "50px",
            paddingRight: "50px",
          }}
        >
          <h2
            style={{ textAlign: "center", fontWeight: "820", fontSize: "42px" }}
          >
            LOGIN
          </h2>
          <h3
            style={{
              textAlign: "center",
              color: "#323232",
              fontWeight: "350",
              marginTop: "-10px",
            }}
          >
            Welcome Back!
          </h3>

          <div style={{ marginTop: "40px", marginBottom: "20px" }}>
            {
              <motion.div
                initial={{ opacity: 0, marginBottom: 0 }}
                animate={{
                  opacity: alertShow ? 1 : 0,
                  marginBottom: alertShow ? 20 : 0,
                }}
              >
                <Alert
                  type={alertType ? "success" : "error"}
                  showIcon
                  message={alertMessage}
                ></Alert>
              </motion.div>
            }
            <Form layout="vertical" name="login-form" onFinish={onLogin}>
              <Form.Item
                name="userName"
                placeholder="Email"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please input your username",
                  },
                ]}
              >
                <Input bordered={{ radious: "0px" }} placeholder="Email" />
              </Form.Item>
              <Form.Item
                name="password"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please input your password",
                  },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: "-webkit-fill-available",
                    marginRight: "10px",
                    backgroundColor: "#8d96ff",
                    border: "none",
                    fontFamily: "'Poppins'",
                    fontWeight: "500",
                  }}
                >
                  LOGIN
                </Button>
              </Form.Item>
              {
                <div>
                  <div className="d-flex justify-content-center mt-4">
                    <a
                      className="mr-2"
                      // icon={<CustomIcon svg={GoogleSVG}/>}
                    >
                      {false && "Forget / Reset Password?"}
                    </a>
                  </div>
                </div>
              }
            </Form>
          </div>
        </div>
        <div
          style={{
            background: "#F5F8FF",
            marginLeft: "-24px",
            marginBottom: "-24px",
            marginRight: "-24px",
            paddingBottom: "20px",
            paddingTop: "20px",
            textAlign: "center",
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
          }}
        >
          DON'T HAVE AN ACCOUNT ?{" "}
          <a
            onClick={() => {
              setModal1Open(false);
              localStorage.setItem("login", false);
              setLocalSignUpData();
              history.push("/chatbot");
              // history.push("/signup");
            }}
            style={{ color: "#8d96ff" }}
          >
            SIGN UP
          </a>
        </div>
      </Modal>
      {!mobileView ? (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button
            onClick={() => {
              // setModal1Open(true)
              // localStorage.setItem("signUp", false);

              setLocalLoginData();
              history.push("/chatbot");
            }}
            style={{
              border: "1px solid rgb(141, 150, 255)",
              color: "rgb(141, 150, 255)",
              marginRight: "8%",
              borderRadius: "8px",
              fontWeight: "550",
            }}
          >
            Login
          </Button>
          <Button
            onClick={() => {
              setLocalSignUpData();
              history.push("/chatbot");
              // history.push("/signup");
            }}
            type="primary"
            style={{
              backgroundColor: "#8d96ff",
              border: "none",
              fontFamily: "'Poppins'",
              fontWeight: "500",
              letterSpacing: "0.5px",
              borderRadius: "8px",
            }}
          >
            Signup
          </Button>
        </div>
      ) : viaDrawer ? (
        <Menu
          mode={mobileView ? "vertical" : "horizontal"}
          style={{ background: "#E6F3F9" }}
        >
          <Menu.Item key="mail">
            <a
              onClick={() => {
                // setModal1Open(true)
                // localStorage.setItem("signUp", false);
                setLocalLoginData();
                history.push("/chatbot");
              }}
            >
              Login
            </a>
          </Menu.Item>
          <Menu.Item key="app">
            <a
              href=""
              onClick={() => {
                setLocalSignUpData();
                // history.push("/signup");
                history.push("/chatbot");
              }}
            >
              Signup
            </a>
          </Menu.Item>
        </Menu>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginLeft: "8px",
            border: "1.5px solid #8d96ff",
            borderRadius: "8px",
            maxWidth: "162px",
          }}
        >
          <button
            style={{
              background: "white",
              border: "none",
              color: "#8d96ff",
              cursor: "pointer",
            }}
            onClick={() => {
              // setModal1Open(true)
              // localStorage.setItem("signUp", false);

              setLocalLoginData();
              history.push("/chatbot");
            }}
          >
            Login
          </button>
          <span
            style={{
              color: "#8d96ff",
            }}
          >
            /
          </span>
          <button
            style={{
              background: "white",
              border: "none",
              color: "#8d96ff",
              cursor: "pointer",
            }}
            onClick={() => {
              setLocalSignUpData();
              history.push("/chatbot");
              // history.push("/signup");
            }}
          >
            Signup
          </button>
        </div>
      )}
    </>
  );
};
