import React, { useState } from "react";
import { Button, Drawer } from "antd";
import "./style.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import LeftMenu from "../navbar/LeftMenu";
import { RightMenu } from "../navbar/RightMenu";

const Navbar = () => {
  const mobileView = window.innerWidth < 961;

  const history = useHistory();
  const checkToken = localStorage.getItem("token");
  const [visible, setVisible] = useState(false);
  const [viaDrawer, setViaDrawwer] = useState(false);

  const showDrawer = () => {
    setViaDrawwer(true);

    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  return (
    <div>
      <div
        className="chatNav"
        style={{
          borderBottom: "none",
          background: "transparent",
          display: "flex",
          position: "fixed",
          top: "0",
          width: "100%",
          justifyContent: "flex-start",
        }}
      >
        {mobileView && (
          <Button
            className="barsMenu"
            style={{
              width: "auto",
              background: "transparent",
              border: "none",
              position: "relative",
              // top: "-55px",
            }}
            type="primary"
            onClick={showDrawer}
          >
            <span className="barsBtn"></span>
          </Button>
        )}
        <div
          onClick={() => {
            if (checkToken) {
              history.push("/auth/dashboard");
            } else {
              history.push("/");
            }
          }}
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <img
            src="/new/oldLogo-removebg-1.png"
            alt="Lenny.AI"
            style={{
              width: window.innerWidth < 600 ? "165px" : "235px",
            }}
          />
        </div>
        <div
          style={{
            width: "60%",
            display: mobileView ? "none" : "block",
          }}
        >
          <LeftMenu />
        </div>
        <div
          style={{
            width: "20%",
            display: mobileView ? "none" : "block",
          }}
        >
          <RightMenu />
        </div>
      </div>

      <Drawer
        style={{
          zIndex: "1002",
        }}
        title="Lenny"
        placement="left"
        // closable={true}
        onClose={onClose}
        visible={visible}
      >
        <LeftMenu />
        <RightMenu viaDrawer={viaDrawer} />
      </Drawer>
    </div>
  );
};

export default Navbar;
