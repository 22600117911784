import React, { useEffect, useState, useCallback } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import Utils from "utils/common";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import fetch_ from "interceptors/FetchInterceptor";
import { HeartOutlined, HeartFilled } from "@ant-design/icons";
import MonthYearPicker from "../layout-components/MonthYearPicker";
import {
  Button,
  Col,
  Divider,
  Form,
  Radio,
  Row,
  Select,
  Input,
  Typography,
  message,
} from "antd";
import { SelectDatepicker } from "react-select-datepicker";
// import "./register.scss";
import registerStyle from "./registerStyle.js";
import rules from "./rules.js";
import i18nIsoCountries from "i18n-iso-countries";
import moment from "moment";
import { showAuthMessage, showLoading } from "redux/actions/Auth";
import { API_BASE_URL } from "configs/AppConfig.js";
import { useAssitanceChatBotContext } from "context/ChatbotContext.js";
import { debounce } from "lodash";

const UserDetail = ({
  setChatBtn,
  applyScholarship,
  setProfileUpdate,
  chatThreeDotLoading,
}) => {
  const {
    loading,
    setLoading,
    data,
    setData,
    citizenship,
    setCitizenship,
    ethnicity,
    setEthnicity,
    schoolLevel,
    setSchoolLevel,
    militaryAffiliation,
    setMilitaryAffiliation,
    gpa,
    setGpa,
    highSchoolName,
    setHighSchoolName,
    collegeName,
    setCollegeName,
    fieldOfStudy,
    setFieldOfStudy,
    degreeType,
    setDegreeType,
    careerGoal,
    setCareerGoal,
    citizenshipId,
    setCitizenshipId,
    ethnicityId,
    setEthnicityId,
    countryId,
    setCountryId,
    highSchoolNameId,
    setHighSchoolNameId,
    militaryAffiliationId,
    setMilitaryAffiliationId,
    gpaId,
    setGpaId,
    degreeTypeId,
    setDegreeTypeId,
    careerGoalId,
    setCareerGoalId,
    highSchoolGradDateId,
    setHighSchoolGradDateId,
    enrolledInCollegeId,
    setEnrolledInCollegeId,
    collegeEnrolledDateId,
    setCollegeEnrolledDateId,
    collegeNameId,
    setCollegeNameId,
    genderId,
    setGenderId,
    fieldOfStudyId,
    setFieldOfStudyId,
    currentSchoolLevelId,
    setCurrentSchoolLevelId,
    zipCodeId,
    setZipCodeId,
    collegeGraduationDateId,
    setCollegeGraduationDateId,
    interestedInOnlineId,
    setInterestedInOnlineId,
    interestedCollegesId,
    setInterestedCollegesId,
    numberForCountryName,
    setNumberForCountryName,
    country,
    setCountry,
  } = useAssitanceChatBotContext();

  const { Text } = Typography;
  const [form] = Form.useForm();
  // const { Step } = Steps;
  const history = useHistory();
  const userInfoString = localStorage.getItem("userInfo");
  let userInfo = {};
  if (userInfoString) {
    try {
      userInfo = JSON.parse(userInfoString);
    } catch (err) {
      console.error("Error parsing userInfo:", err);
    }
  }
  const userInfoString2 = localStorage.getItem("register2");

  let userInfo2 = {};
  if (userInfoString2) {
    try {
      userInfo2 = JSON.parse(userInfoString2);
    } catch (err) {
      console.error("Error parsing userInfo:", err);
    }
  }

  const storedDates = localStorage.getItem("dates");
  let parsedDates = null;
  if (storedDates) {
    parsedDates = JSON.parse(storedDates);
  }

  // const gaEventTracker = useAnalyticsEventTracker("Additional Detail");

  if (!userInfo.firstName) {
    history.push("/signup");
  }

  const [saveBtn, setSaveBtn] = useState(false);
  const [load, Setload] = useState(false);
  const [alertShow, setAlertShow] = useState();
  const [, setDisableButton] = useState(false);
  const [alertMessage, setAlertMessage] = useState();
  const [alertType, setAlertType] = useState(true);
  const [enrolledInChanged, setEnrolledInChanged] = useState(1);
  //const formValues = JSON.parse(localStorage.getItem('register3'));
  const mobileView = window.innerWidth < 1000;
  const [collegeNameValue, setCollegeNameValue] = useState();
  const [schoolNameValue, setSchoolNameValue] = useState();
  const [collegeNameShow, setCollegeNameShow] = useState(true);
  const [schoolNameShow, setSchoolNameShow] = useState(true);

  const [dob, setDob] = useState(() => {
    if (parsedDates && parsedDates.dob) {
      return new Date(parsedDates.dob);
    } else if (userInfo2?.dob) {
      return new Date(userInfo2.dob);
    } else {
      return null; // Default value if neither localStorage nor userInfo2 has dob
    }
  });

  const [collegeEnrolledDate, setCollegeEnrolledDate] = useState(() => {
    if (parsedDates && parsedDates.collegeEnrolledDate) {
      return new Date(parsedDates.collegeEnrolledDate);
    } else {
      return null; // Default value if neither localStorage nor userInfo2 has dob
    }
  });
  //const [collegeEnrolledDate, setCollegeEnrolledDate] = useState();
  const [highSchoolGradDate, setHighSchoolGradDate] = useState(() => {
    if (parsedDates && parsedDates.highSchoolGradDate) {
      return new Date(parsedDates.highSchoolGradDate);
    } else {
      return null; // Default value if neither localStorage nor userInfo2 has dob
    }
  });
  const [collegeGraduationDate, setCollegeGraduationDate] = useState(() => {
    if (parsedDates && parsedDates.collegeGraduationDate) {
      return new Date(parsedDates.collegeGraduationDate);
    } else {
      return null; // Default value if neither localStorage nor userInfo2 has dob
    }
  });

  const [markingSystem, setMarkingSystem] = useState("GPA"); // Default marking system
  const [userInput, setUserInput] = useState("");
  const [equivalentGPA, setEquivalentGPA] = useState(null);
  const [userGPA, setUserGPA] = useState(null);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    const storedData = localStorage.getItem("formData");
    let parsedData = null;
    if (storedData) {
      parsedData = JSON.parse(storedData);
    }

    const storedDates = localStorage.getItem("dates");
    let parsedDates = null;
    if (storedDates) {
      parsedDates = JSON.parse(storedDates);
    }

    // Manually set the value of the selectedGPA field when equivalentGPA changes
    form.setFieldsValue({
      ...parsedData,
      selectedGPA: equivalentGPA,
      userinput: userInput,
      gpa: parsedData?.gpa ?? userGPA,
      country: parsedData?.country ?? numberForCountryName,
      dob: parsedData?.dob ?? null,

      //highSchoolGradDate:parsedDates.highSchoolGradDate,
    });
  }, [equivalentGPA, numberForCountryName, form]);

  const handleMarkingSystemChange = (value) => {
    setMarkingSystem(value);
    form.setFieldsValue({
      selectedGPA: null,
      userinput: "",
      gpa: null,
    });
  };
  const handleUserInputChange = (e) => {
    const input = e.target.value;
    setUserInput(input);

    // Automatically calculate and set equivalent GPA
    if (markingSystem === "Percentage") {
      setUserGPA(null);
      setEquivalentGPA(percentageToGPA(input));
    } else if (markingSystem === "10CGPA") {
      setUserGPA(null);
      setEquivalentGPA(cgpaToGPA(input));
    } else {
      setEquivalentGPA(null);
    }
  };

  function percentageToGPA(percentage) {
    if (percentage >= 85 && percentage <= 100) {
      // console.log("GPA is ", "4.0");
      return "4.0";
    } else if (percentage >= 80 && percentage < 85) {
      return "3.7";
    } else if (percentage >= 75 && percentage < 80) {
      return "3.3";
    } else if (percentage >= 70 && percentage < 75) {
      return "3.0";
    } else if (percentage >= 65 && percentage < 70) {
      return "2.7";
    } else if (percentage >= 60 && percentage < 65) {
      return "2.3";
    } else if (percentage >= 55 && percentage < 60) {
      return "2.0";
    } else {
      return "0.0";
    }
  }

  function cgpaToGPA(cgpa) {
    if (cgpa >= 8.5) {
      return "4.0";
    } else if (cgpa >= 8.0 && cgpa < 8.5) {
      return "3.7";
    } else if (cgpa >= 7.5 && cgpa < 8.0) {
      return "3.3";
    } else if (cgpa >= 7.0 && cgpa < 7.5) {
      return "3.0";
    } else if (cgpa >= 6.5 && cgpa < 7.0) {
      return "2.7";
    } else if (cgpa >= 6.0 && cgpa < 6.5) {
      return "2.3";
    } else if (cgpa >= 5.5 && cgpa < 6.0) {
      return "2.0";
    } else {
      return "0.0";
    }
  }

  const renderGPAOptions = () => {
    if (markingSystem === "GPA") {
      return (
        <Form.Item name="gpa" rules={rules.gpa}>
          <Select
            size="large"
            placeholder="Select GPA"
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {!loading &&
              gpa &&
              Object.keys(gpa).map((option) => (
                <Select.Option value={option} key={option}>
                  {gpa[option]}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      );
    } else {
      return (
        <Form.Item name="selectedGPA" rules={rules.markingSystem}>
          <Select size="large" placeholder="Select GPA">
            {!loading &&
              gpa &&
              Object.keys(gpa).map((option) => (
                <Select.Option value={option} key={option}>
                  {gpa[option]}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      );
    }
  };

  function getAge(DOB) {
    var today = new Date();
    var birthDate = new Date(DOB);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  const dateRule = {
    dob: [
      {
        required: true,
        message: "Please select your date of birth!",
      },
      ({ getFieldValue }) => ({
        validator(rule, value) {
          const dob_ = getFieldValue("dob");
          // console.log("form cal", dob_);
          if (dob === null && getFieldValue("dob") > 0) {
            // console.log("form cal", 0);
            return Promise.reject("Please select your date of birth!");
          } else if (getAge(getFieldValue("dob")) < 16) {
            // console.log("form cal", 16);
            return Promise.reject("User should not be less than 16 year!");
          }
          // console.log("form cal", 16);
          return Promise.resolve();
        },
      }),
    ],
    collegeGraduationDate: [
      {
        // required: true,
        // message: "Please fill your Expected college graduation date!",
      },
      {
        validator: (_, value) => {
          if (collegeGraduationDate) {
            return Promise.resolve();
          }
          return Promise.reject();
        },
        message: "You must select both month and year!",
      },
    ],
    collegeEnrolledDate: [
      {
        // required: true,
        // message: "Please fill your college enrolled date!",
      },
      {
        validator: (_, value) => {
          if (collegeEnrolledDate) {
            return Promise.resolve();
          }
          return Promise.reject();
        },
        message: "You must select both month and year!",
      },
    ],
    highSchoolGradDate: [
      {
        // required: true,
        // message: "Please fill your high school graduation date!",
      },
      {
        validator: (_, value) => {
          if (highSchoolGradDate) {
            return Promise.resolve();
          }
          return Promise.reject();
        },
        message: "You must select both month and year!",
      },
    ],
    enrolledInCollege: [
      ({ getFieldValue }) => ({
        validator(rule, value) {},
      }),
    ],
  };

  const onSignUp = () => {
    // console.log("before form ", collegeEnrolledDate);
    form
      .validateFields()
      .then((values) => {
        Setload(true);
        let postData = {
          firstName: userInfo?.firstName ?? "",
          lastName: userInfo?.lastName ?? "",
          email: userInfo?.email ?? "",
          // phone: userInfo?.phone ?? "",
          dob: moment(dob).format("YYYY-MM-DD") ?? "",
          applicantType: values.iAm ?? "STUDENT",
          applicantFields: [
            {
              fieldId: citizenshipId[0]?.id ?? "",
              fieldValue: values.citizenship ?? "",
            },
            {
              fieldId: countryId[0]?.id ?? "",
              fieldValue: values.country ?? "",
            },
            {
              fieldId: ethnicityId[0]?.id ?? "",
              fieldValue: values.ethnicity ?? "",
            },
            {
              fieldId: highSchoolNameId[0]?.id ?? "",
              fieldValue: values.highSchoolName ?? "",
            },
            {
              fieldId: highSchoolGradDateId[0]?.id ?? "",
              fieldValue: moment(highSchoolGradDate).format("YYYY-MM-DD") ?? "",
            },
            {
              fieldId: enrolledInCollegeId[0]?.id ?? "",
              fieldValue: values.enrolledInCollege ?? "",
            },
            {
              fieldId: collegeEnrolledDateId[0]?.id ?? "",
              fieldValue:
                moment(collegeEnrolledDate).format("YYYY-MM-DD") ?? "",
            },
            {
              fieldId: collegeGraduationDateId[0]?.id ?? "",
              fieldValue:
                moment(collegeGraduationDate).format("YYYY-MM-DD") ?? "",
            },
            {
              fieldId: degreeTypeId[0]?.id ?? "",
              fieldValue: values.degreeType ?? "",
            },
            {
              fieldId: gpaId[0]?.id ?? "",
              fieldValue: values.gpa ? values.gpa : values.selectedGPA ?? "",
            },
            {
              fieldId: careerGoalId[0]?.id ?? "",
              fieldValue: values.careerGoal ?? "",
            },
            {
              fieldId: interestedInOnlineId[0]?.id ?? "",
              fieldValue: values.interestedInOnline ?? "",
            },
            {
              fieldId: militaryAffiliationId[0]?.id ?? "",
              fieldValue: values.militaryAffiliation ?? "",
            },
            {
              fieldId: genderId[0]?.id ?? "",
              fieldValue: values.gender + "" ?? "",
            },
            {
              fieldId: fieldOfStudyId[0]?.id ?? "",
              fieldValue: values.fieldOfStudy ?? "",
            },
            {
              fieldId: currentSchoolLevelId[0]?.id ?? "",
              fieldValue: values.currentSchoolLevel ?? "",
            },
            // { fieldId: addressId[0]?.id ?? "", fieldValue: values.address ?? "" },
            {
              fieldId: zipCodeId[0]?.id ?? "",
              fieldValue: values.zipCode ?? "",
            },
            // { fieldId: cityId[0]?.id ?? "", fieldValue: values.city ?? "" },
            // { fieldId: stateId[0]?.id ?? "", fieldValue: values.state ?? "" },
          ],
          isProfileUpdate: "true",
          isScholarshipApplied: "false",
        };

        if (values.enrolledInCollege) {
          postData = {
            ...postData,
            applicantFields: [
              ...postData.applicantFields,
              {
                fieldId: collegeNameId[0].id,
                fieldValue: values.collegeName,
              },
            ],
          };
        } else {
          postData = {
            ...postData,
            applicantFields: [
              ...postData.applicantFields,
              {
                fieldId: interestedCollegesId[0].id,
                fieldValue: values.collegeName1,
              },
              {
                fieldId: interestedCollegesId[0].id,
                fieldValue: values.collegeName2,
              },
              {
                fieldId: interestedCollegesId[0].id,
                fieldValue: values.collegeName3,
              },
            ],
          };
        }
        let token = localStorage.getItem("token");
        fetch(`${API_BASE_URL}api/v1/register-applicants/${userInfo.id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(postData),
        })
          .then((response) => response.json())
          .then((data) => {
            // console.log("🚀 ~ .then ~ res:", data);
            if (data.statusCode == 200) {
              localStorage.removeItem("register2");
              localStorage.removeItem("register1");
              localStorage.setItem(
                "userInfo",
                typeof data.data.applicant === "string"
                  ? data.data.applicant
                  : JSON.stringify(data.data.applicant)
              );
              setSaveBtn(true);
              // console.log("hello");
              // chatThreeDotLoading("Your profile updated successfully.")
              // applyScholarship(
              //   "recommendation",
              //   "Hi, can you please suggest some scholarships for me? "
              // );
              message.success("Details updated successfully!!");
              setTimeout(() => {
                applyScholarship(
                  "recommendation",
                  "Hi, can you please suggest some scholarships for me? "
                );
              }, 2000);
              // setProfileUpdate(() => {
              //   return true;
              // });
              Setload(false);
              // setChatBtn(false);
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            setAlertShow(true);
            setAlertMessage(error?.description || "Server Error");
            setAlertType(false);
          })
          .finally(() => {
            showLoading(false);
            setDisableButton(false);
            Setload(false);
          });
      })
      .catch((info) => {
        setDisableButton(false);
      });
  };

  const onFormChange = (changedValues, allValues) => {
    // Save the entire form data to localStorage
    const { dob, ...otherChangedValues } = changedValues; // Destructure dob from changedValues
    // Log allValues (should contain all fields)
    // console.log(
    //   "form change values except dob",
    //   JSON.stringify(otherChangedValues)
    // ); // Log otherChangedValues (should exclude dob)

    // Save only if there are other changed values
    if (Object.keys(otherChangedValues).length > 0) {
      console.log("form change values inside", allValues);
      localStorage.setItem("formData", JSON.stringify(allValues)); // Save allValues to localStorage
    }
  };

  // const handleSearch = (newValue) => {
  //   setCollegeNameValue(newValue);
  //   setCollegeNameShow(true);
  // };

  // const handleChange = (newValue) => {
  //   setCollegeNameShow(false);
  //   setCollegeNameValue(newValue);
  // };

  // const handleSchoolSearch = (newValue) => {
  //   // console.log("🚀 ~ handleSchoolSearch ~ newValue:", newValue);
  //   setSchoolNameValue(newValue);
  //   setSchoolNameShow(true);
  // };

  // const handleSchoolChange = (newValue) => {
  //   // console.log("🚀 ~ handleSchoolChange ~ newValue:", newValue);
  //   setSchoolNameShow(false);
  //   setSchoolNameValue(newValue);
  // };

  const onDateChange = useCallback((dob) => {
    const loadedDates = localStorage.getItem("dates");
    let updatedDates;
    if (loadedDates) {
      updatedDates = {
        ...JSON.parse(loadedDates),
        dob: dob,
      };
    } else {
      updatedDates = {
        dob: dob,
      };
    }
    // console.log("dob saved", dob);
    localStorage.setItem("dates", JSON.stringify(updatedDates));
    setDob(dob);
  }, []);

  const onHighSchoolGradDateChange = useCallback((dateObject) => {
    //console.log(dateObject); // { month: 5, year: 2020 }
    const { month, year } = dateObject;
    // Check if both month and year are provided
    if (month !== null && year !== null) {
      const loadedDates = localStorage.getItem("dates");
      let updatedDates;
      if (loadedDates) {
        updatedDates = {
          ...JSON.parse(loadedDates),
          highSchoolGradDate: dateObject,
        };
      } else {
        updatedDates = {
          highSchoolGradDate: dateObject,
        };
      }
      localStorage.setItem("dates", JSON.stringify(updatedDates));
      setHighSchoolGradDate(dateObject);
    } else {
      setHighSchoolGradDate(null);
    }
  }, []);

  const onCollegeEnrolledDateChange = useCallback((newCollegeEnrolledDate) => {
    const { month, year } = newCollegeEnrolledDate;
    if (month !== null && year !== null) {
      const loadedDates = localStorage.getItem("dates");
      let updatedDates;
      if (loadedDates) {
        updatedDates = {
          ...JSON.parse(loadedDates),
          collegeEnrolledDate: newCollegeEnrolledDate,
        };
      } else {
        updatedDates = {
          collegeEnrolledDate: newCollegeEnrolledDate,
        };
      }
      // console.log("dob saved collegeEnrolledDate", newCollegeEnrolledDate);
      localStorage.setItem("dates", JSON.stringify(updatedDates));
      setCollegeEnrolledDate(newCollegeEnrolledDate);
    } else {
      setCollegeEnrolledDate(null);
    }
  }, []);

  const onCollegeGraduationDateChange = useCallback((collegeGraduationDate) => {
    const { month, year } = collegeGraduationDate;

    if (month !== null && year !== null) {
      const loadedDates = localStorage.getItem("dates");
      let updatedDates;
      if (loadedDates) {
        updatedDates = {
          ...JSON.parse(loadedDates),
          collegeGraduationDate: collegeGraduationDate,
        };
      } else {
        updatedDates = {
          collegeGraduationDate: collegeGraduationDate,
        };
      }
      // console.log("dob saved collegeGraduationDate", collegeGraduationDate);
      localStorage.setItem("dates", JSON.stringify(updatedDates));
      setCollegeGraduationDate(collegeGraduationDate);
    } else {
      setCollegeGraduationDate(null);
    }
  }, []);

  const onEnrolledInChanged = (e) => {
    setEnrolledInChanged(e);
  };

  const box = (props) => {
    return (
      <Col style={{ ...registerStyle.infoBox }}>
        <Row>
          <Col style={{ alignSelf: "center", marginRight: "5px" }}>
            <img src={`/new/${props.icon}`} style={{ width: "25px" }} />
          </Col>
          <Col>
            <div style={registerStyle.boxHead}>{props.text}</div>
            <div
              style={{
                ...registerStyle.boxSubHead,
              }}
            >
              {props.subText}
            </div>
          </Col>
        </Row>
      </Col>
    );
  };

  const EnrolledInRender = (props) => {
    return (
      <div id="select-form-container">
        <Form.Item name={props.name} rules={rules.collegeName}>
          <Select
            size="large"
            style={{}}
            showSearch
            value={collegeNameValue}
            autoClearSearchValue={false}
            onSearch={handleSearch}
            onChange={handleChange}
            listItemHeight={10}
            mode="single"
            placeholder={"College Name"}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {collegeNameValue && !collegeName[collegeNameValue] && (
              <Select.Option
                value={collegeNameValue}
                selected={collegeNameValue}
                key={collegeNameValue}
              >
                {collegeNameValue}
              </Select.Option>
            )}

            {!loading &&
              collegeName &&
              Object.keys(collegeName).map((option) => (
                <Select.Option value={collegeName[option]} key={option}>
                  {collegeName[option]}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </div>
    );
  };

  const [query, setQuery] = useState("");
  const [schools, setSchools] = useState([]);
  const [highSchoolLoader, setHighSchoolLoader] = useState(false);
  const [collgeLoader, setCollegeLoader] = useState(false);

  const handleSearch = (newValue) => {
    setCollegeNameValue(newValue);
    setCollegeNameShow(true);
  };

  const handleChange = (newValue) => {
    setCollegeNameShow(false);
    setCollegeNameValue(newValue);
  };

  const fetchSchools = async (searchQuery) => {
    setHighSchoolLoader(true);
    try {
      const response = await fetch(
        `https://chat.lenny.ai/api/schools/search?name=${searchQuery}`
      );
      const data = await response.json();
      setSchools(data);
    } catch (error) {
      console.error("Error fetching school data:", error);
    }
    setHighSchoolLoader(false);
  };

  const debouncedFetchSchools = useCallback(
    debounce((value) => fetchSchools(value), 800),
    []
  );

  const handleSchoolSearch = (value) => {
    setQuery(value);
    debouncedFetchSchools(value);
  };

  const isQueryInOptions = schools.some(
    (school) => school.name.toLowerCase() === query.toLowerCase()
  );

  const handleSchoolChange = (newValue) => {
    setSchoolNameShow(false);
    setSchoolNameValue(newValue);
  };

  const [collegeQuery, setCollegeQuery] = useState("");
  const [colleges, setColleges] = useState([]);

  const fetchColleges = async (searchQuery) => {
    setCollegeLoader(true);
    try {
      const response = await fetch(
        `https://chat.lenny.ai/api/college/search?name=${searchQuery}`
      );
      const data = await response.json();
      setColleges(data);
      setCollegeLoader(false);
    } catch (error) {
      console.error("Error fetching college data:", error);
      setCollegeLoader(false);
    }
  };
  const debouncedFetchColleges = useCallback(
    debounce((value) => fetchColleges(value), 800),
    []
  );

  const handleCollegeSearch = (value) => {
    setCollegeQuery(value);
    debouncedFetchColleges(value);
  };
  const handleCollegeChange = (value) => {
    setCollegeNameValue(value);
    if (
      value === collegeQuery &&
      !colleges.some(
        (college) => college.name.toLowerCase() === collegeQuery.toLowerCase()
      )
    ) {
      console.log("Saving new college name:", value);
    } else {
      console.log("Selected college name:", value);
    }
  };

  const isCollegeQueryInOptions = colleges.some(
    (college) => college.name.toLowerCase() === collegeQuery.toLowerCase()
  );

  return (
    <>
      <Form
        form={form}
        style={{}}
        layout="vertical"
        initialValues={{
          iAm: "STUDENT",
          interestedInOnline: 1,
          gender: 1,
          enrolledInCollege: 1,
          fieldOfStudy: userInfo2?.fieldOfStudy,
          currentSchoolLevel: userInfo2.currentSchoolLevel,
          markingSystem: "GPA",
          dob: dob,
          country: numberForCountryName,
          // collegeEnrolledDate : collegeEnrolledDate
          gpa: "3",
        }}
        name="register-form-step3"
        onFinish={onSignUp}
        onValuesChange={onFormChange}
      >
        <div
          style={{
            marginRight: mobileView ? "" : "20px",
            backgroundColor: "white",
            padding: "10px 25px",
            borderRadius: "12px",
          }}
        >
          <Row style={registerStyle.detailDiv}>
            <Text style={registerStyle.detail}>Additional details</Text>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col style={registerStyle.r3Column1}>
              <Text
                style={{
                  marginLeft: "4px",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                {" "}
                I am a{" "}
              </Text>
              <Form.Item name="iAm">
                <Radio.Group>
                  <Radio value={"STUDENT"}>Student</Radio>
                  <Radio value={"PARENT"}>Parent</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col style={registerStyle.r3Column1}>
              <Text
                style={{
                  marginLeft: "4px",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Your birthday
              </Text>
              <Form.Item name="dob" rules={dateRule.dob}>
                <SelectDatepicker
                  selectedDate={dob}
                  labels={{
                    dayPlaceholder: "Day",
                    monthPlaceholder: "Month",
                    yearPlaceholder: "Year",
                  }}
                  maxDate={
                    new Date(
                      new Date().setFullYear(new Date().getFullYear() - 16)
                    )
                  }
                  style={{ height: "40px" }}
                  order="day/month/year"
                  hideLabels={true}
                  onDateChange={onDateChange}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col style={registerStyle.r3Column1}>
              <Text
                style={{
                  marginLeft: "4px",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Citizenship
              </Text>
              <div id="select-form-container">
                <Form.Item name="citizenship" rules={rules.citizenship}>
                  <Select
                    size="large"
                    mode="single"
                    placeholder={"Citizenship"}
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {!loading &&
                      citizenship &&
                      Object.keys(citizenship).map((option) => (
                        <Select.Option value={option} key={option}>
                          {citizenship[option]}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            </Col>

            <Col style={registerStyle.r3Column1}>
              <Text
                style={{
                  marginLeft: "4px",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Ethnicity
              </Text>
              <div id="select-form-container">
                <Form.Item name="ethnicity" rules={rules.ethnicity}>
                  <Select
                    size="large"
                    style={{}}
                    mode="single"
                    placeholder={"Ethnicity"}
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {!loading &&
                      ethnicity &&
                      Object.keys(ethnicity).map((option) => (
                        <Select.Option value={option} key={option}>
                          {ethnicity[option]}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Row>
            <Col style={registerStyle.r3Column1}>
              <Text
                style={{
                  marginLeft: "4px",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Country
              </Text>
              <div id="select-form-container">
                <Form.Item name="country" rules={rules.country}>
                  <Select
                    size="large"
                    style={{}}
                    mode="single"
                    placeholder={"Country"}
                    showSearch
                    filterOption={
                      (input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      // console.log(input, option)
                    }
                  >
                    {!loading &&
                      country &&
                      Object.keys(country).map((option) => (
                        <Select.Option value={option} key={option}>
                          {country[option]}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Col style={registerStyle.r3Column1}>
              <Text
                style={{
                  marginLeft: "4px",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                {" "}
                Gender
              </Text>
              <Form.Item name="gender">
                <Radio.Group>
                  <Radio value={1}>Female</Radio>
                  <Radio value={2}>Male</Radio>
                  <Radio value={3}>Other</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col style={registerStyle.r3Column1}>
              <Text
                style={{
                  marginLeft: "4px",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Zip Code
              </Text>
              <Form.Item name="zipCode" rules={rules.zipCode}>
                <Input
                  placeholder="Zip Code"
                  style={{ borderRadius: "8px", height: "50px" }}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* <Divider /> */}
          <Row
            style={{
              ...registerStyle.detailDiv,
              borderTop: "1px solid #8D96FF",
              // marginLeft: "20px",
            }}
          >
            <Text style={registerStyle.detail}>Educational details</Text>
          </Row>
          <Row>
            <Col style={registerStyle.r3Column1}>
              <Text
                style={{
                  marginLeft: "4px",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Current School Level
              </Text>
              <div id="select-form-container">
                <Form.Item
                  name="currentSchoolLevel"
                  rules={rules.currentSchoolLevel}
                >
                  <Select
                    size="large"
                    style={{}}
                    mode="single"
                    placeholder={"Current school level"}
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {!loading &&
                      schoolLevel &&
                      Object.keys(schoolLevel).map((option) => (
                        <Select.Option value={option} key={option}>
                          {schoolLevel[option]}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            </Col>
            {/* <Col style={registerStyle.r3Column1}>
              <Text
                style={{
                  marginLeft: "4px",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                High School Name
              </Text>
              <div id="select-form-container">
                <Form.Item name="highSchoolName" rules={rules.highSchoolName}>
                 
                  <Select
                    size="large"
                    style={{}}
                    mode="single"
                    placeholder={"High School Name"}
                    showSearch
                    value={schoolNameValue}
                    autoClearSearchValue={false}
                    onSearch={handleSchoolSearch}
                    onChange={handleSchoolChange}
                    listItemHeight={10}
                  // onDropdownVisibleChange={(open) => {
                  //   if (open) {
                  //     setSchoolNameValue('');
                  //   }
                  // }}
                  >
                    {schoolNameValue && !highSchoolName[schoolNameValue] && (
                      <Select.Option
                        value={schoolNameValue}
                        selected={schoolNameValue}
                        key={schoolNameValue}
                      >
                        {schoolNameValue}
                      </Select.Option>
                    )}

                    {!loading &&
                      highSchoolName &&
                      Object.keys(highSchoolName).map((option) => (
                        <Select.Option
                          value={highSchoolName[option]}
                          key={option}
                        >
                          {highSchoolName[option]}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            </Col> */}
            <Col style={registerStyle.r3Column1}>
              <Text
                style={{
                  marginLeft: "4px",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                High School Name <sup>*</sup>
              </Text>
              <div id="select-form-container">
                <Form.Item name="highSchoolName" rules={rules.highSchoolName}>
                  <Select
                    size="large"
                    placeholder="High School Name"
                    showSearch
                    value={schoolNameValue}
                    autoClearSearchValue={false}
                    onSearch={handleSchoolSearch}
                    onChange={handleSchoolChange}
                    notFoundContent={
                      highSchoolLoader ? (
                        <LoadingOutlined style={{ marginRight: 10 }} />
                      ) : null
                    }
                  >
                    {schools.map((school) => (
                      <Select.Option key={school._id} value={school.name}>
                        {school.name}
                      </Select.Option>
                    ))}
                    {query && !isQueryInOptions && !highSchoolLoader && (
                      <Select.Option key="new" value={query}>
                        {`${query}`}
                      </Select.Option>
                    )}
                  </Select>
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Row>
            <Col style={registerStyle.r3Column1}>
              <Text
                style={{
                  marginLeft: "4px",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                High School Graduation Date
              </Text>
              <Form.Item
                name="highSchoolGradDate"
                rules={dateRule.highSchoolGradDate}
              >
                <div>
                  <MonthYearPicker
                    minYear={1990}
                    maxYear={2040}
                    onDateChange={onHighSchoolGradDateChange}
                    initialValue={highSchoolGradDate}
                  />
                </div>
                {/* <SelectDatepicker
                  selectedDate={highSchoolGradDate}
                  order="month/year"
                  maxDate={
                    new Date(
                      new Date().setFullYear(
                        new Date().getFullYear() + 10
                        // new Date().getFullYear() + 50
                      )
                    )
                  }
                  labels={{
                    dayPlaceholder: "Day",
                    monthPlaceholder: "Month",
                    yearPlaceholder: "Year",
                  }}
                  hideLabels={true}
                  onDateChange={onHighSchoolGradDateChange}
                /> */}
              </Form.Item>
            </Col>

            <Col style={registerStyle.r3Column1}>
              <Text
                style={{
                  marginLeft: "4px",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                {" "}
                Enrolled in college
              </Text>
              <Form.Item
                name="enrolledInCollege"
                rules={rules.enrolledInCollege}
              >
                <Radio.Group
                  onChange={(e) => onEnrolledInChanged(e.target.value)}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col style={registerStyle.r3Column1}>
              <Text
                style={{
                  marginLeft: "4px",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                College Enrolled Date
              </Text>
              <Form.Item
                name="collegeEnrolledDate"
                rules={dateRule.collegeEnrolledDate}
              >
                {/* <SelectDatepicker
                  selectedDate={collegeEnrolledDate}
                  maxDate={
                    new Date(
                      new Date().setFullYear(
                        new Date().getFullYear() + 10

                        // new Date().getFullYear() + 50
                      )
                    )
                  }
                  labels={{
                    monthPlaceholder: "Month",
                    yearPlaceholder: "Year",
                  }}
                  order="month/year"
                  hideLabels={true}
                  onDateChange={onCollegeEnrolledDateChange}
                /> */}
                <MonthYearPicker
                  minYear={1990}
                  maxYear={2040}
                  onDateChange={onCollegeEnrolledDateChange}
                  initialValue={collegeEnrolledDate}
                />
              </Form.Item>
            </Col>

            {/* <Col style={registerStyle.r3Column1}>
              <Text
                style={{
                  marginLeft: "4px",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                {enrolledInChanged ? "College Name" : "Potenital College Name"}
              </Text>
              {enrolledInChanged
                ? EnrolledInRender({
                  name: "collegeName",
                })
                : EnrolledInRender({
                  name: "collegeName1",
                })}
              {!enrolledInChanged &&
                EnrolledInRender({
                  name: "collegeName2",
                })}
              {!enrolledInChanged &&
                EnrolledInRender({
                  name: "collegeName3",
                })}
            </Col> */}
            <Col style={registerStyle.r3Column1}>
              <Text
                style={{
                  marginLeft: "4px",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                {enrolledInChanged ? "College Name" : "Potential College Name"}{" "}
                <sup>*</sup>
              </Text>
              <div id="select-form-container">
                <Form.Item
                  name={
                    enrolledInChanged ? "collegeName" : "potentialCollegeName"
                  }
                  rules={rules.collegeName}
                >
                  <Select
                    size="large"
                    placeholder="College Name"
                    showSearch
                    value={collegeNameValue}
                    autoClearSearchValue={false}
                    onSearch={handleCollegeSearch}
                    onChange={handleCollegeChange}
                    notFoundContent={
                      collgeLoader ? (
                        <LoadingOutlined style={{ marginRight: 10 }} />
                      ) : null
                    }
                  >
                    {colleges.map((college) => (
                      <Select.Option key={college._id} value={college.name}>
                        {college.name}
                      </Select.Option>
                    ))}
                    {collegeQuery &&
                      !isCollegeQueryInOptions &&
                      !collgeLoader && (
                        <Select.Option key="new" value={collegeQuery}>
                          {collegeQuery}
                        </Select.Option>
                      )}
                  </Select>
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Row>
            <Col style={registerStyle.r3Column1}>
              <Text
                style={{
                  marginLeft: "4px",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Expected College Graduation Date
              </Text>
              <Form.Item
                name="collegeGraduationDate"
                rules={dateRule.collegeGraduationDate}
              >
                {/* <SelectDatepicker
                  selectedDate={collegeGraduationDate}
                  maxDate={
                    new Date(
                      new Date().setFullYear(
                        new Date().getFullYear() + 15
                        // new Date().getFullYear() + 50
                      )
                    )
                  }
                  labels={{
                    dayPlaceholder: "Day",
                    monthPlaceholder: "Month",
                    yearPlaceholder: "Year",
                  }}
                  order="month/year"
                  hideLabels={true}
                  onDateChange={onCollegeGraduationDateChange}
                /> */}
                <MonthYearPicker
                  minYear={1990}
                  maxYear={2040}
                  onDateChange={onCollegeGraduationDateChange}
                  initialValue={collegeGraduationDate}
                />
              </Form.Item>
            </Col>

            <Col style={registerStyle.r3Column1}>
              <Text
                style={{
                  marginLeft: "4px",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Field of Study
              </Text>
              <div id="select-form-container">
                <Form.Item name="fieldOfStudy" rules={rules.fieldOfStudy}>
                  <Select
                    size="large"
                    style={{}}
                    mode="single"
                    placeholder={"Field of Study"}
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {!loading &&
                      fieldOfStudy &&
                      Object.keys(fieldOfStudy).map((option) => (
                        <Select.Option value={option} key={option}>
                          {fieldOfStudy[option]}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Row>
            <Col style={registerStyle.r3Column1}>
              <Text
                style={{
                  marginLeft: "4px",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Degree Type
              </Text>
              <div id="select-form-container">
                <Form.Item name="degreeType" rules={rules.degreeType}>
                  <Select
                    size="large"
                    style={{}}
                    mode="single"
                    placeholder={"Degree Type"}
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {!loading &&
                      degreeType &&
                      Object.keys(degreeType).map((option) => (
                        <Select.Option value={option} key={option}>
                          {degreeType[option]}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            </Col>

            <Col style={registerStyle.r3Column1}>
              <Text
                style={{
                  marginLeft: "4px",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Grade System
              </Text>
              <div id="select-form-container">
                <Form.Item name="markingSystem">
                  <Select
                    size="large"
                    mode="single"
                    placeholder="GPA"
                    defaultValue={markingSystem}
                    onChange={handleMarkingSystemChange}
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Select.Option value="Percentage">Percentage</Select.Option>
                    <Select.Option value="10CGPA">10CGPA</Select.Option>
                    <Select.Option value="GPA">GPA</Select.Option>
                  </Select>
                </Form.Item>
                {markingSystem !== "GPA" && (
                  <Form.Item name="userinput" rules={rules.gpaInput}>
                    <Input
                      placeholder={`Enter ${
                        markingSystem === "GPA" ? "GPA" : markingSystem
                      }`}
                      onChange={handleUserInputChange}
                      value={userInput}
                    />
                  </Form.Item>
                )}
                {renderGPAOptions()}
              </div>
            </Col>

            {/* <Col style={registerStyle.r3Column1}>
                    <Text
                      style={{
                        marginLeft: "4px",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      GPA
                    </Text>
                    <div id="select-form-container">
                      <Form.Item name="gpa" rules={rules.gpa}>
                        <Select
                          size="large"
                          style={{}}
                          mode="single"
                          placeholder={"GPA"}
                        >
                          {!loading &&
                            gpa &&
                            Object.keys(gpa).map((option) => (
                              <Select.Option value={option} key={option}>
                                {gpa[option]}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </Col> */}
          </Row>

          <Row>
            <Col style={registerStyle.r3Column1}>
              <Text
                style={{
                  marginLeft: "4px",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Career Goal
              </Text>
              <div id="select-form-container">
                <Form.Item name="careerGoal" rules={rules.careerGoal}>
                  <Select
                    size="large"
                    style={{}}
                    mode="single"
                    placeholder={"Career Goal"}
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {!loading &&
                      careerGoal &&
                      Object.keys(careerGoal).map((option) => (
                        <Select.Option value={option} key={option}>
                          {careerGoal[option]}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            </Col>

            <Col style={registerStyle.r3Column1}>
              <Text
                style={{
                  marginLeft: "4px",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Military Affiliation
              </Text>
              <div id="select-form-container">
                <Form.Item
                  name="militaryAffiliation"
                  rules={rules.militaryAffiliation}
                >
                  <Select
                    size="large"
                    style={{}}
                    mode="single"
                    placeholder={"Military Affilation"}
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {!loading &&
                      militaryAffiliation &&
                      Object.keys(militaryAffiliation).map((option) => (
                        <Select.Option value={option} key={option}>
                          {militaryAffiliation[option]}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col style={registerStyle.r3Column1}>
              <Text
                style={{
                  marginLeft: "4px",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                {/* Interested in online study */}
                Are you considering an online Degree?
              </Text>
              <Form.Item name="interestedInOnline">
                <Radio.Group>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                  <Radio value={3}>Maybe</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </div>

        {mobileView && <Divider style={{ margin: "24px 0 0" }} />}

        <Form.Item style={{ textAlignLast: "center" }}>
          <Button
            type="primary"
            htmlType="submit"
            block
            style={{
              ...registerStyle.buttonStyle,
              backgroundColor: "rgb(141, 150, 255)",
              cursor: "pointer",
            }}
            disabled={saveBtn}
          >
            <span style={registerStyle.buttonText}>
              Save{" "}
              {load ? <LoadingOutlined style={{ marginRight: 10 }} /> : null}
            </span>
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default UserDetail;
