import { Button, Form, Input, Modal } from "antd";
import { useState } from "react";
import fetch_ from "../../../interceptors/FetchInterceptor";

const Support = ({ props }) => {
  const mobileView = window.innerWidth < 1200;
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);

  const userInfoString = localStorage.getItem("userInfo");
  let userInfo = {};
  if (userInfoString) {
    try {
      userInfo = JSON.parse(userInfoString);
    } catch (err) {
      console.error("Error parsing userInfo:", err);
    }
  }
  const fullName = userInfo.firstName?.toUpperCase() + " " + userInfo.lastName;
  const { TextArea } = Input;
  const rules = {
    query: [
      {
        required: true,
        message: "Please input your query",
      },
    ],
    description: [
      {
        required: true,
        message: "Please input your description",
      },
    ],
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        let postData = {
          email: userInfo.email,
          name: fullName,
          query: values.query,
          description: values.description,
          applicantId: userInfo.id,
        };

        fetch_({
          url: `/scholarships/add-support`,
          method: "POST",
          data: postData,
        })
          .then((data) => {
            form.resetFields();
            setModalVisible(true);
          })
          .catch((err) => {
            console.log("info", err);
          })
          .finally(() => {});
      })
      .catch((info) => {});
  };

  return (
    <div
      style={{
        background: "#ffffff",
        padding: mobileView ? "20px" : "20px",
        //width: mobileView && window.innerWidth,
      }}
    >
      <Modal
        visible={modalVisible}
        destroyOnClose={true}
        closable={false}
        footer={null}
        width="300px"
        bodyStyle={{
          padding: "20px",
          borderRadius: "10px",
          height: "250px",
        }}
      >
        <div style={{ float: "right" }}>
          <img
            onClick={() => {
              setModalVisible(false);
            }}
            src="/applicantImg/Close.png"
            style={{ height: "18px", marginRight: "4px" }}
          />
        </div>
        <div
          style={{
            textAlign: "center",
            marginTop: "30px",
          }}
        >
          <img
            src="/applicantImg/thankYou.png"
            style={{ height: "80px", marginRight: "4px" }}
          />
          <h2
            style={{
              marginTop: "10px",
            }}
          >
            Thank You!
          </h2>
          <div>We have received your query, we will get back to you soon.</div>
        </div>
      </Modal>

      <Form
        form={form}
        layout="vertical"
        name="register-form-step1"
        initialValues={{
          name: fullName,
          email: userInfo.email,
        }}
        onFinish={onSubmit}
        style={{
          width: !mobileView && "300px",
        }}
      >
        <Form.Item name="name" label="Name" rules={rules.name} hasFeedback>
          <Input
            disabled={true}
            placeholder="Name"
            style={{ height: "40px", borderRadius: "5px" }}
          />
        </Form.Item>
        <Form.Item name="email" label="Email" rules={rules.email} hasFeedback>
          <Input
            disabled={true}
            placeholder="Email"
            style={{ height: "40px", borderRadius: "5px" }}
          />
        </Form.Item>
        <Form.Item name="query" label="Query" rules={rules.query} hasFeedback>
          <Input
            placeholder="Query"
            style={{ height: "40px", borderRadius: "5px" }}
          />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={rules.description}
          hasFeedback
        >
          <TextArea placeholder="Description" style={{ borderRadius: "5px" }} />
        </Form.Item>
        <Form.Item style={{}}>
          <Button type="primary" htmlType="submit" style={{}}>
            <span style={{}}>Submit</span>
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Support;
