import React, { useEffect, useState, useRef, useMemo } from "react";
import "./css/subscription_modal.css";
import PayPalSubscription from "./PayPalSubscription";
import {
  CloseCircleFilled,
  RightOutlined,
  LeftOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import "./css/Popus.css";
import { useHistory } from "react-router-dom";
import useAnalyticsEventTracker from "useAnalyticsEventTracker";
import fetch_ from "interceptors/FetchInterceptor";
import { Spin, Carousel, Button } from "antd";
import PaddleIntegration from "./PaddleIntegration";
import useAxios from "axios-hooks";
import countryList from "../../constants/country-code.json";
import { initializePaddle } from "@paddle/paddle-js";
import mixpanel from "mixpanel-browser";
import { useAssitanceChatBotContext } from "../../context/ChatbotContext";

const Subscription_modal = (props) => {
  const [loading, setLoading] = useState(true);

  const { setInvitationPopUp } = useAssitanceChatBotContext();

  const [paddle, setPaddle] = useState();

  const [isOpen, setIsOpen] = useState(false);
  const [activateBtnLoader, setActivateBtnLoader] = useState(false);
  const [monthlyBtnLoader, setMonthlyBtnLoader] = useState(false);
  const [quarterlyBtnLoader, setQuarterlyBtnLoader] = useState(false);
  const [yearlyBtnLoader, setYearlyBtnLoader] = useState(false);

  const history = useHistory();

  const gaEventTracker = useAnalyticsEventTracker("Subscription");
  // console.log(isOpen)
  const togglePopup = async (redirectValue, btnType) => {
    if (redirectValue) {
      setIsOpen(false);
      await fetchData(btnType);
      setIsOpen(true);

      // setTimeout(() => {
      //   setIsOpen(false);
      // }, 2000);
    } else {
      history.push("/signup");
    }
  };

  const carouselRef = useRef();

  const handlePrev = () => {
    carouselRef.current.prev();
  };

  const handleNext = () => {
    carouselRef.current.next();
  };

  const [yearlyPlan, setYearlyPlan] = useState({});
  const [quarterlyPlan, setQuarterlyPlan] = useState({});
  const [monthlyPlan, setMonthlyPlan] = useState({});
  const [productId, setProductId] = useState("");
  const [paddlePriceId, setPaddlePriceId] = useState("");
  const [price, setPrice] = useState("");

  const [description, setDescription] = useState("");
  const [userCountry, setUserCountry] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [loaded, setLoaded] = useState(true);

  const [userObj, setUserObj] = useState({
    email: "",
    country: "",
    paddleUserId: "",
    userId: "",
  });

  const userInfoString = localStorage.getItem("userInfo");
  let userInfo = {};
  if (userInfoString) {
    try {
      userInfo = JSON.parse(userInfoString);
    } catch (err) {
      console.error("Error parsing userInfo:", err);
    }
  }

  const fetchData = useMemo((btnType) => {
    return async (btnType) => {
      // console.log(btnType)
      try {
        if (btnType === "monthlyPlan") {
          setMonthlyBtnLoader(true);
        } else if (btnType === "quarterlyPlan") {
          setQuarterlyBtnLoader(true);
        } else if (btnType === "yearlyPlan") {
          setYearlyBtnLoader(true);
        }

        const [
          // data,
          // profileData,
          newApi,
        ] = await Promise.all([
          // fetch_("/register-applicants/form-fields"),
          // fetch_("/register-applicants/profile"),
          fetch_("/register-applicants/profile/minimal"),
        ]);
        // console.log("🚀 ~ return ~ newApi:", newApi?.data);
        // console.log("🚀 ~ return ~ newApi:", newApi?.data);
        // let data = await fetch_(`/register-applicants/form-fields`);
        // let countryObj = data.data?.filter((e) => e.key === "country");
        // let zipCodeId = data.data?.filter((e) => e.key === "zipCode");
        // let profileData = await fetch_("/register-applicants/profile");
        // let userInfo = profileData.data;

        // let countryNumber = userInfo?.applicantFields?.filter(
        //   (e) => e.fieldId === countryObj[0]?.id
        // )[0]?.fieldValue;

        let countryName = newApi?.data?.country;
        // let countryName = countryObj[0].options[countryNumber];

        let countryCode = countryList.find((e) => {
          return e.country === countryName;
        })?.code;

        const defaultValues = {
          id: newApi?.data?.id,
          // id: userInfo?.id,
          firstName: newApi?.data?.firstName,
          lastName: newApi?.data?.lastName,
          email: newApi?.data?.email,
          country: countryName,
          countryCode: countryCode,
          paddleUserId: newApi?.data?.paddleUserId,
          paddleAddressId: newApi?.data?.paddleAddressId,
          zipCode: newApi?.data?.zipcode,
          // zipCode: userInfo?.applicantFields?.filter(
          //   (e) => e.fieldId === zipCodeId[0]?.id
          // )[0]?.fieldValue,
        };

        setUserObj({
          email: defaultValues.email,
          country: defaultValues.country,
          paddleUserId: defaultValues.paddleUserId,
          userId: defaultValues.id,
          paddleAddressId: defaultValues.paddleAddressId,
          zipCode: defaultValues.zipCode,
          countryCode: countryCode,
        });
        if (btnType === "monthlyPlan") {
          setMonthlyBtnLoader(false);
        } else if (btnType === "quarterlyPlan") {
          setQuarterlyBtnLoader(false);
        } else if (btnType === "yearlyPlan") {
          setYearlyBtnLoader(false);
        }
      } catch (error) {
        // Handle errors
        console.error("Error fetching data:", error);
        if (btnType === "monthlyPlan") {
          setMonthlyBtnLoader(false);
        } else if (btnType === "quarterlyPlan") {
          setQuarterlyBtnLoader(false);
        } else if (btnType === "yearlyPlan") {
          setYearlyBtnLoader(false);
        }
      } finally {
        setLoaded(false);
        if (btnType === "monthlyPlan") {
          setMonthlyBtnLoader(false);
        } else if (btnType === "quarterlyPlan") {
          setQuarterlyBtnLoader(false);
        } else if (btnType === "yearlyPlan") {
          setYearlyBtnLoader(false);
        }
      }
    };
  }, []);
  const loadData = async () => {
    try {
      let data = await fetch_("/subscription-plans", {
        method: "GET",
      });

      let monthlyPrice, quarterlyPrice, yearlyPrice;
      data.data.forEach((plan) => {
        const { title, price, productId, description, paddlePriceId } = plan;

        const parsedPrice = parseFloat(price.substring(1));

        const isTrialTaken = userInfo?.isTrailTaken ?? true;

        if (isTrialTaken) {
          if (title === "Yearly Plan") {
            yearlyPrice = parsedPrice;
            setYearlyPlan({
              price,
              title,
              productId,
              description,
              paddlePriceId,
            });
          } else if (title === "Quarterly Plan") {
            quarterlyPrice = parsedPrice;
            setQuarterlyPlan({
              price,
              title,
              productId,
              description,
              paddlePriceId,
            });
          } else if (title === "Monthly Plan") {
            monthlyPrice = parsedPrice;
            setMonthlyPlan({
              price,
              title,
              productId,
              description,
              paddlePriceId,
            });
          }
        } else {
          if (title === "Yearly Plan-7 day trial") {
            yearlyPrice = parsedPrice;
            setYearlyPlan({
              price,
              title,
              productId,
              description,
              paddlePriceId,
            });
          } else if (title === "Quarterly Plan-7 day trial") {
            quarterlyPrice = parsedPrice;
            setQuarterlyPlan({
              price,
              title,
              productId,
              description,
              paddlePriceId,
            });
          } else if (title === "Monthly Plan-7 day trial") {
            monthlyPrice = parsedPrice;
            setMonthlyPlan({
              price,
              title,
              productId,
              description,
              paddlePriceId,
            });
          }
        }
        if (monthlyPrice && quarterlyPrice && yearlyPrice) {
          // Calculate total prices for a year
          const totalMonthly = monthlyPrice * 12;
          const totalQuarterly = quarterlyPrice * 4 * 3; // Quarterly x 4 quarters
          const totalYearly = yearlyPrice * 12;

          // Calculate savings
          const savingsQuarterly = totalMonthly - totalQuarterly;
          const savingsYearly = totalMonthly - totalYearly;

          // Update state with calculated discounts
          setYearlyPlan((prevState) => ({
            ...prevState,
            savings: savingsYearly.toFixed(2),
          }));
          setQuarterlyPlan((prevState) => ({
            ...prevState,
            savings: savingsQuarterly.toFixed(2),
          }));
        }
      });
      // await fetchData()
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };
  useEffect(async () => {
    let isMounted = true;
    if (isMounted) {
      loadData(); //load the subscription plans first
      // await fetchData(); //set the user details
    }
    return () => {
      isMounted = false;
    };
  }, []);
  useEffect(() => {
    const initializeAndOpenCheckout = async () => {
      try {
        const paddleInstance = await initializePaddle({
          //environment: "sandbox",
          // token: "test_5542685d1fea3460323c1a07221",

          token: "live_2acf6093a3b4baf59f5ac9e1bbb",
          eventCallback: (data) => {
            // console.log(data);
            if (data.name === "checkout.completed") {
              // closeCheckout();
              localStorage.setItem(
                "addressId",
                data?.data?.customer?.address?.id
              );
              localStorage.setItem("isActiveSubscription", true);
              // history.push("/auth/dashboard/success");
              if (window.trackdesk) {
                window.trackdesk("lennyai", "conversion", {
                  conversionType: "freetrial",
                  amount: {
                    value: price?.substring(1),
                  },
                  customParams: {
                    advS1: "Trial successful",
                  },
                });
              }
            }

            if (data.name === "checkout.close") {
              setInvitationPopUp(true);
              mixpanel.track("Invitation Popup Opened");
            }
          },
        });
        if (paddleInstance) {
          setPaddle(paddleInstance);
          // setLoading(false);
        }
        // console.log(paddleInstance)
      } catch (error) {
        console.error("Error initializing Paddle:", error);
      }
    };

    initializeAndOpenCheckout();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p
          style={{
            color: "black",
            fontFamily: "Poppins",
            margin: "5px",
            display: "inline",
          }}
        >
          {" "}
          Please wait...
          <Spin
            size="small"
            style={{ marginLeft: "5px" }}
            className="custom-spinner"
          />
        </p>
      </div>
    );
  }

  return (
    <div className="subscription_container" style={props.modalStyle}>
      <div className="sub_part1">
        <div className="sub_box2">
          <h3 className="sub_heading">Membership Options</h3>
        </div>
      </div>

      <div className="sub_part2">
        <div className="monthly_plan_box">
          <p
            style={{
              fontSize: "16px",
              float: "right",
              margin: "5px 9px",
              color: "black",
              fontFamily: "Poppins",
              lineHeight: "normal",
            }}
          >
            <b>Monthly Plan</b>
          </p>
          <p className="price" style={{ margin: "0px" }}>
            {" "}
            <span className="sub_box_currencySymbol">$ </span>{" "}
            <span className="sub_box_price">
              {monthlyPlan.price
                ? monthlyPlan.price.substring(1)
                : monthlyPlan.price}
            </span>{" "}
            <span className="sub_box_month">/month + taxes </span>
          </p>
          <div className="plan-desc">
            {monthlyPlan && monthlyPlan.description
              ? monthlyPlan.description.split(",").flatMap((item, index) => [
                  <p
                    key={index}
                    style={
                      index === 0
                        ? {
                            fontSize: "15px",
                            fontWeight: "500",
                          }
                        : {}
                    }
                  >
                    {item}
                  </p>,
                  <hr key={`hr${index}`} className="plan_hr" />,
                ])
              : null}
            <button
              className="activate_btn"
              onClick={async () => {
                let label = {
                  planName: monthlyPlan.title,
                };
                gaEventTracker(
                  "Subscription Purchase activate clicked",
                  JSON.stringify(label)
                );
                mixpanel.track("Subscription Purchase activate clicked");
                // await fetchData();
                setPrice(monthlyPlan.price);
                setPaddlePriceId(monthlyPlan.paddlePriceId);
                setProductId(monthlyPlan.productId);
                togglePopup(props.redirect, "monthlyPlan");
              }}
            >
              {/* {activateBtnLoader ? <Spin indicator={<LoadingOutlined style={{ fontSize: 14 }} spin />} /> : 'Activate 7 Days'} */}
              Activate 7 Days
              {monthlyBtnLoader ? (
                <LoadingOutlined style={{ marginRight: 10 }} />
              ) : null}
            </button>
          </div>
        </div>
        <div className="quaterly_plan_box">
          <img
            src="/images/award.png"
            alt=""
            div
            style={{
              position: "absolute",
              top: "-18px",
              left: "-30px",
              width: "82px",
              height: "82px",
            }}
          />
          <div
            style={{ textAlign: "center", position: "relative", top: "-52px" }}
          >
            {" "}
            <button className="popular_btn">Most Popular</button>
          </div>
          <div style={{ marginTop: "-50px" }}>
            <p
              style={{
                fontSize: "16px",
                float: "right",
                margin: "5px 9px",
                color: "white",
                fontFamily: "Poppins",
                lineHeight: "normal",
              }}
            >
              Quarterly Plan
            </p>
            <p className="price" style={{ color: "white" }}>
              {" "}
              <span
                className="sub_box_currencySymbol"
                style={{
                  color: "white",
                  textShadow: "9px 5px 0px rgb(215 211 211 / 50%)",
                }}
              >
                ${" "}
              </span>{" "}
              <span
                className="sub_box_price"
                style={{
                  color: "white",
                  textShadow: "9px 5px 0px rgb(215 211 211 / 50%)",
                }}
              >
                {quarterlyPlan.price
                  ? quarterlyPlan.price.substring(1)
                  : quarterlyPlan.price}
              </span>{" "}
              <span className="sub_box_month" style={{ color: "white" }}>
                /month + taxes
              </span>
            </p>

            <div className="plan-desc" style={{ color: "white !important" }}>
              {quarterlyPlan && quarterlyPlan.description
                ? quarterlyPlan.description
                    .split(",")
                    .flatMap((item, index) => [
                      <p
                        key={index}
                        style={{
                          color: "white",
                          fontSize: index === 0 ? "15px" : "14px",
                          fontWeight: index === 0 ? 500 : "normal",
                        }}
                      >
                        {item}
                      </p>,
                      <hr
                        key={`hr${index}`}
                        className="plan_hr"
                        style={{
                          background:
                            "-webkit-gradient(linear,0 0,100% 0,from(transparent),to(transparent),color-stop(50%, white))",
                        }}
                      />,
                    ])
                : null}
              <div style={{ top: "50px" }}>
                {quarterlyPlan.savings && (
                  <p className="plan-message" style={{ color: "white" }}>
                    You save ${quarterlyPlan.savings} annually!
                  </p>
                )}
              </div>
              <button
                className="activate_btn"
                style={{
                  backgroundColor: "rgb(254, 193, 45)",
                  color: "black",
                  position: "relative",
                  top: "30px",
                  border: "1px solid white",
                  fontWeight: "600",
                  letterSpacing: "0.5px",
                  width: "auto",
                }}
                onClick={() => {
                  let label = {
                    planName: quarterlyPlan.title,
                  };
                  gaEventTracker(
                    "Subscription Purchase activate clicked",
                    JSON.stringify(label)
                  );
                  mixpanel.track("Subscription Purchase activate clicked");
                  setPrice(quarterlyPlan.price);

                  setPaddlePriceId(quarterlyPlan.paddlePriceId);
                  setProductId(quarterlyPlan.productId);
                  togglePopup(props.redirect, "quarterlyPlan");
                }}
              >
                Activate 7 Days
                {quarterlyBtnLoader ? (
                  <LoadingOutlined style={{ marginRight: 10 }} />
                ) : null}
              </button>
            </div>
          </div>
        </div>
        <div className="yearly_plan_box">
          <p
            style={{
              fontSize: "16px",
              float: "right",
              margin: "5px 9px",
              color: "black",
              fontFamily: "Poppins",
              lineHeight: "normal",
            }}
          >
            <b>Yearly Plan</b>
          </p>

          <p className="price" style={{ margin: "0px" }}>
            {" "}
            <span className="sub_box_currencySymbol">$ </span>{" "}
            <span className="sub_box_price">
              {yearlyPlan.price
                ? yearlyPlan.price.substring(1)
                : yearlyPlan.price}
            </span>{" "}
            <span className="sub_box_month">/month + taxes</span>
          </p>
          <div className="plan-desc">
            {yearlyPlan && yearlyPlan.description
              ? yearlyPlan.description.split(",").flatMap((item, index) => [
                  <p
                    key={index}
                    style={
                      index === 0
                        ? {
                            fontSize: "15px",
                            fontWeight: "500",
                          }
                        : {}
                    }
                  >
                    {item}
                  </p>,
                  <hr key={`hr${index}`} className="plan_hr" />,
                ])
              : null}
            <div style={{ top: "50px" }}>
              {yearlyPlan.savings && (
                <p className="plan-message">
                  You save ${yearlyPlan.savings} annually!
                </p>
              )}
            </div>
            <button
              className="activate_btn yearly_activateBtn"
              onClick={() => {
                let label = {
                  planName: yearlyPlan.title,
                };
                gaEventTracker(
                  "Subscription Purchase activate clicked",
                  JSON.stringify(label)
                );
                mixpanel.track("Subscription Purchase activate clicked");
                setPrice(yearlyPlan.price);

                setPaddlePriceId(yearlyPlan.paddlePriceId);
                setProductId(yearlyPlan.productId);
                togglePopup(props.redirect, "yearlyPlan");
              }}
            >
              Activate 7 Days
              {yearlyBtnLoader ? (
                <LoadingOutlined style={{ marginRight: 10 }} />
              ) : null}
            </button>
          </div>
        </div>
      </div>
      <div className="part3">
        <div className="sub_box1">
          <img
            src="/images/lock.webp"
            alt="lock_icon"
            style={{
              width: "116px",
              height: "135px",
            }}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="sub_part3"
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button onClick={handlePrev} className="no-border-button">
                <LeftOutlined />
              </Button>
              <Carousel ref={carouselRef} autoplay style={{ width: 500 }}>
                <div>
                  <p
                    style={{
                      textAlign: "center",
                      margin: "4px",
                      color: "#293238",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    {" "}
                    Manav Saini purchased a Quarterly membership just 3 days
                    ago, and he was awarded a generous scholarship of $2000.
                  </p>
                  <p
                    style={{
                      color: "#293238",
                      textAlign: "center",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "110%",
                      margin: "0",
                      paddingTop: "18px",
                    }}
                  >
                    Manav Saini
                  </p>
                </div>
                <div>
                  <p
                    style={{
                      textAlign: "center",
                      margin: "4px",
                      color: "#293238",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    {" "}
                    Ananya Sharma enrolled in a Yearly membership just 2 days
                    ago, and she was granted an impressive scholarship of $5000.
                  </p>
                  <p
                    style={{
                      color: "#293238",
                      textAlign: "center",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "110%",
                      margin: "0",
                      paddingTop: "18px",
                    }}
                  >
                    Ananya Sharma
                  </p>
                </div>
              </Carousel>
              <Button onClick={handleNext} className="no-border-button">
                <RightOutlined />
              </Button>
            </div>
          </div>

          <div className="sub_part4">
            <p
              style={{
                color: "#293238",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "110%",
              }}
            >
              {/* Manav Saini */}
            </p>
            <p>Student</p>
            <img src="/images/stars.png" alt="" />
          </div>
        </div>
        <div className="sub_box3">
          <img
            src="/images/check.webp"
            alt="check_icon"
            style={{
              width: "116px",
              height: "135px",
            }}
          />
        </div>
      </div>

      {isOpen && (
        // <div className="popup-overlay">
        //   <div
        //     className="popup-content"
        //     style={{
        //       minWidth: "30%",
        //       padding: "35px",
        //     }}
        //   >
        //     <div
        //       onClick={togglePopup}
        //       style={{
        //         float: "right",
        //         lineHeight: "normal",
        //         cursor: "pointer",
        //       }}
        //     >
        //       {" "}
        //       <CloseCircleFilled />
        //     </div>
        //     <h3>Choose Payment Method</h3>
        //     {/* <PayPalSubscription productId={productId} /> */}
        //   </div>
        // </div>
        <PaddleIntegration
          paddlePriceId={paddlePriceId}
          //togglePopup={togglePopup}
          userDetail={userObj}
          productId={productId}
          price={price}
          paddle={paddle}
        />
      )}
    </div>
  );
};

export default Subscription_modal;
