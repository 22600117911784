import {
  BookOutlined,
  BorderOuterOutlined,
  CheckCircleOutlined,
  DashboardOutlined,
  InboxOutlined,
  QuestionOutlined,
  UpCircleOutlined,
  SettingOutlined,
  LogoutOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import deleteCookie from "utils/deletecookie";
import mixpanel from "../mixpanel/mixpanel";
import { UserAuthService } from "services/user-auth.service";

export const NAV_PATH = {
  login: `${AUTH_PREFIX_PATH}/login`,
  dashboard: `${APP_PREFIX_PATH}/dashboard`,
  personal: `${APP_PREFIX_PATH}/personal`,
  apply: `${APP_PREFIX_PATH}/apply`,
  scholarships: {
    detail: (scholarshipId) =>
      `${APP_PREFIX_PATH}/scholarships/detail/${scholarshipId}`,
    root: `${APP_PREFIX_PATH}/scholarships`,
    wizard: `${APP_PREFIX_PATH}/scholarships/wizard`,
    template: `${APP_PREFIX_PATH}/scholarships/wizard/template`,
    applicant_detail: (id) =>
      `${APP_PREFIX_PATH}/scholarships/applicant_detail/${id}`,
    winners_list: (id) => `${APP_PREFIX_PATH}/scholarships/winners_list/${id}`,
    rank_criteria: (id) =>
      `${APP_PREFIX_PATH}/scholarships/rank_criteria/${id}`,
    edit: (id) => `${APP_PREFIX_PATH}/scholarships/edit/${id}`,
    targetAudience: (id) =>
      `${APP_PREFIX_PATH}/scholarships/wizard/${id}/target_audience`,
    dataCapturing: (id) =>
      `${APP_PREFIX_PATH}/scholarships/wizard/${id}/capturing`,
    deadline: (id) => `${APP_PREFIX_PATH}/scholarships/wizard/${id}/deadline`,
    attributes: (id) =>
      `${APP_PREFIX_PATH}/scholarships/wizard/${id}/attributes`,
    budget: (id) => `${APP_PREFIX_PATH}/scholarships/wizard/${id}/budget`,
    requirements: (id) =>
      `${APP_PREFIX_PATH}/scholarships/wizard/${id}/requirements`,
    escrow: (id) => `${APP_PREFIX_PATH}/scholarships/wizard/${id}/escrow`,
    publish: (id) => `${APP_PREFIX_PATH}/scholarships/wizard/${id}/publish`,
  },
  applicants: {
    detail: (scholarshipId, applicantId) =>
      `${APP_PREFIX_PATH}/scholarships/${scholarshipId}/applicants/${applicantId}`,
  },
};

/*Supper User menu start*/
const userNav = [
  {
    key: "dashboard",
    path: `/auth/dashboard`,
    title: " Dashboard",
    submenu: [],
    icon: DashboardOutlined,
  },
  {
    key: "easyApply",
    path: `/auth/dashboard/easy-apply`,
    title: " Easy Apply",
    submenu: [],
    icon: CheckCircleOutlined,
  },
  {
    key: "recommendation",
    path: `/auth/dashboard/recommendation`,
    title: `Recommendation `,
    submenu: [],
    icon: BookOutlined,
  },
  // {
  //   key: "trackApplications",
  //   path: `/auth/dashboard/trackApplications`,
  //   title: " Track Applications",
  //   submenu: [],
  //   icon: DashboardOutlined,
  // },
  {
    key: "wishlist",
    path: `/auth/dashboard/saved`,
    title: " Wishlist / Saved",
    submenu: [],
    icon: BookOutlined,
  },
  {
    key: "myScholarship",
    path: `/auth/dashboard/myScholarship?tab=applied`,
    title: " My Scholarship",
    submenu: [],
    icon: UpCircleOutlined,
  },
  {
    key: "inbox",
    path: `/auth/dashboard/inbox`,
    title: " Inbox",
    submenu: [],
    icon: InboxOutlined,
  },
  {
    key: "profile",
    path: `/auth/dashboard/profile`,
    title: " Profile",
    submenu: [],
    icon: UpCircleOutlined,
  },
  {
    key: "subscription_support",
    path: `/auth/dashboard/subscription-support`,
    title: "Subscription Support",
    submenu: [],
    icon: MessageOutlined,
  },
  {
    key: "support",
    path: `/auth/dashboard/support`,
    title: " Support",
    submenu: [],
    icon: BorderOuterOutlined,
  },
  {
    key: "assistance",
    path: `/auth/dashboard/assistance`,
    title: " Lenny's Assistance",
    submenu: [],
    icon: QuestionOutlined,
  },
  {
    key: "setting",
    path: `/auth/dashboard/setting`,
    title: "Setting",
    submenu: [],
    icon: SettingOutlined,
  },
  {
    key: "logout",
    // path: `/auth/dashboard/setting`,
    title: "Logout",
    submenu: [],
    icon: LogoutOutlined,
    onClick: async () => {
      const token = localStorage.getItem("token");
      try {
        await UserAuthService.logOut(token);
      } catch (error) {
        console.error("Error logging out:", error);
      }
      localStorage.removeItem("userInfo");
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("isActiveSubscription");
      localStorage.removeItem("profileData");
      localStorage.removeItem("zipCodeId");
      localStorage.removeItem("countryObj");
      localStorage.removeItem("formData");
      localStorage.removeItem("dates");
      localStorage.removeItem("linkId");
      localStorage.removeItem("sourceId");
      localStorage.removeItem("utm_source");
      localStorage.removeItem("utm_content");
      localStorage.removeItem("utm_term");
      localStorage.removeItem("utm_campaign");
      localStorage.removeItem("utm_medium");
      deleteCookie("trakdesk_cid");
      mixpanel.track("Logout Button Clicked");

      window.location.href = "/";
      // window.location.reload();
    },
  },
];

const navigationConfig = [...userNav];

export const navigationConfigMenu = () => {
  const navigationConfig = [];
  navigationConfig.push(...userNav);
  return navigationConfig;
};

export default navigationConfig;
