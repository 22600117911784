export default [
  {
    text1: "Scholarship Matching",
    text2:
      "Students are matched with scholarship opportunities based on their personal information. They don't have to sort through thousands of awards. We narrow their options for them.",
  },
  {
    text1: "Hassle-Free Scholarship Applications",
    text2:
      "Students complete one scholarship application when they sign up for their account. We use this information to complete their subsequent applications so they don't have to waste time with repetitive form filing. This speeds up the application process, allowing students to apply for even more awards and earn more money for college.",
  },
  {
    text1: "Automatic Recurring Applications",
    text2:
      "We automatically send out applications for recurring scholarships so students do not have to watch for deadlines. For example, if an award is renewed once a month, we will submit a student's application every month before the deadline to improve his chances of winning.",
  },
  {
    text1: "Scholarship Management Tools",
    text2:
      "Students can monitor their application status, essays, upcoming award opportunities, and much more through our online management tools. These tools are available anywhere the students have access to the internet.",
  },
  {
    text1: "Dedicated Award Inbox",
    text2:
      "We provide each student with a spam-free scholarship inbox to communicate with providers and keep track of their applications. We have advanced filters to protect our students from promotional content.",
  },
  {
    text1: "Education Center",
    text2:
      "Students can watch videos, read tutorials, see essay and application examples, and find links to a variety of online resources through our education center. Premium members have access to a free eBook that provides a complete guide through the application process.",
  },
  {
    text1: "Essay Writing Assistance",
    text2:
      "Students can see examples of scholarship essays and get editing advice from our experts so they have the best chance at success with their applications. In a recent survey, 75% of respondents confirmed that they would be interested in getting advice for successful essay writing.",
  },
];
