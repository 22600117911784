import React from "react";
import { connect } from "react-redux";
import utils from "utils";
import { Grid } from "antd";

const { useBreakpoint } = Grid;

export const InboxLogo = ({ props, history }) => {
  const isMobile = utils.getBreakPoint(useBreakpoint()).includes("lg");

  return (
    <div
      style={{
        marginRight: "10px",
        marginLeft: "5px",
      }}
      onClick={() => {
        history.push("/auth/dashboard/inbox");
      }}
    >
      <img
        src="/applicantImg/inbox.png"
        style={{
          width: "32px",
          paddingRight: "5px",
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType } = theme;
  return { navCollapsed, navType };
};

export default connect(mapStateToProps)(InboxLogo);
