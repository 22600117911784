import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Row,
  Space,
  Typography,
  Upload,
} from "antd";
import { API_UPLOAD_URL } from "configs/AppConfig";
import React, { useEffect } from "react";
import TextEditor from "./TextEditor";
import { UploadOutlined } from "@ant-design/icons";
import { useAssitanceChatBotContext } from "context/ChatbotContext";
import { set } from "lodash";

const Requirement = ({ details, formRef, scholarshipId, data }) => {
  const { setSaveAndNext, askNextQus, handleNext } =
    useAssitanceChatBotContext();
  let { title, description, optional, limits } = details;
  // console.log("🚀 ~ Requirement ~ details:", JSON.parse(limits));

  let fileTypes = [];
  let fileSize = 0;
  let wordLength = 0;
  let limitStr = "";

  // const renderField = ({ key, name, dataType, optional, limits }) => {

  //   if (limits) {
  //     limits = JSON.parse(limits);
  //     fileTypes = limits?.fileType?.map((item) => `.${item}`).join(",");
  //     fileSize = limits?.fileSize;
  //     wordLength = limits?.wordLength;
  //   }

  //   if (wordLength) {
  //     limitStr = `Limit: ${wordLength} words`;
  //   }

  //   if (fileSize) {
  //     limitStr = `${limitStr}, file size: ${fileSize} MB`;
  //   }

  //   if (fileTypes) {
  //     limitStr = `${limitStr}, File type: ${fileTypes}`;
  //   }

  //   // console.log(limitStr)

  //   switch (true) {
  //     case name === "Essay File":
  //       return (
  //         <Form.Item
  //           rules={[
  //             {
  //               required: optional,
  //               validator: (rule, value) => {
  //                 if (!value) {
  //                   return Promise.reject("Please add essay!");
  //                 }
  //                 return Promise.resolve();
  //               },
  //               validateTrigger: "onsubmit",
  //             },
  //           ]}
  //           name={name}  // Use `key` as name
  //           getValueFromEvent={(val) => {
  //             if (val?.essay)
  //               return {
  //                 text: val?.essay,
  //               };

  //             if (val?.url) {
  //               return {
  //                 url: val?.url,
  //               };
  //             }
  //             return {
  //               url: val?.file?.response?.data,
  //             };
  //           }}
  //           style={{
  //             width: "100%",
  //           }}
  //         >
  //           <TextEditor
  //             scholarshipId={scholarshipId}
  //             details={details}
  //             formRef={formRef}
  //             wordLength={wordLength}
  //             fileSize={fileSize}
  //             fileTypes={fileTypes}
  //             limitStr={limitStr}
  //           />
  //         </Form.Item>
  //       );

  //     case dataType === "file":
  //       return (
  //         <Form.Item
  //           rules={[
  //             {
  //               required: optional,
  //               message: "Please upload!",
  //             },
  //           ]}
  //           name={key}
  //           getValueFromEvent={(val) => {
  //             console.log("🚀 ~ renderField ~ val:", val)
  //             return val?.file?.response?.data?.publicUrl;
  //           }}
  //           showUploadList={{
  //             showRemoveIcon: false,
  //           }}
  //         >
  //           <Upload
  //             action={API_UPLOAD_URL}
  //             accept={fileTypes || ".pdf"}
  //             maxCount={1}
  //             beforeUpload={(file) => {
  //               const isLt2M = file.size / 1024 / 1024 < (fileSize || 1);
  //               if (!isLt2M) {
  //                 message.error(
  //                   `File must be smaller than ${fileSize || 1} MB!`
  //                 );
  //               }
  //               return isLt2M;
  //             }}
  //             onChange={(info) => {
  //               if (info.file.status === "done") {
  //                 message.success(
  //                   `${info.file.name} file uploaded successfully`
  //                 );
  //               } else if (info.file.status === "error") {
  //                 message.error(`${info.file.name} file upload failed.`);
  //               }
  //             }}
  //           >
  //             <Button
  //               type="primary"
  //               style={{
  //                 border: "none",
  //                 background: "rgb(141, 150, 255)",
  //                 padding: "12px 20px",
  //                 borderRadius: "8px",
  //                 display: "flex",
  //                 alignItems: "center",
  //                 height: "40px",
  //               }}
  //             >
  //               <UploadOutlined style={{ color: "white" }} />
  //               Upload {name}
  //             </Button>
  //           </Upload>

  //           {limitStr && (
  //             <Typography.Paragraph
  //               style={{
  //                 color: "#8D96FF",
  //                 marginTop: "8px",
  //                 textDecoration: "underline",
  //                 fontSize: "14px",
  //                 fontWeight: "500",
  //                 letterSpacing: "0.5px",
  //               }}
  //             >
  //               {limitStr}
  //             </Typography.Paragraph>
  //           )}
  //         </Form.Item>
  //       );

  //     case dataType === "link":
  //       return (
  //         <Form.Item
  //           rules={[
  //             {
  //               required: optional,
  //               message: "Please provide!",
  //             },
  //           ]}
  //           name={key}
  //           style={{
  //             width: "100%",
  //           }}
  //         >
  //           <Input
  //             placeholder={name}
  //             style={{
  //               padding: "8px",
  //               width: "100%",
  //               borderRadius: "8px",
  //             }}
  //           />
  //         </Form.Item>
  //       );

  //     case dataType === "checkbox":
  //       return (
  //         <Form.Item
  //           rules={[
  //             {
  //               required: optional,
  //               message: "Please provide!",
  //             },
  //           ]}
  //           name={key}
  //           valuePropName="checked"
  //         >
  //           <Checkbox>{title}</Checkbox>
  //         </Form.Item>
  //       );

  //     case dataType === "text":
  //     case dataType === "editor":
  //       return (
  //         <Form.Item
  //           rules={[
  //             {
  //               required: optional,
  //               validator: (rule, value) => {
  //                 if (!value) {
  //                   return Promise.reject("Please add essay!");
  //                 }
  //                 return Promise.resolve();
  //               },
  //               validateTrigger: "onsubmit",
  //             },
  //           ]}
  //           name={key}
  //           getValueFromEvent={(val) => {
  //             if (val?.essay)
  //               return {
  //                 text: val?.essay,
  //               };

  //             if (val?.url) {
  //               return {
  //                 url: val?.url,
  //               };
  //             }
  //             return {
  //               url: val?.file?.response?.data,
  //             };
  //           }}
  //           style={{
  //             width: "100%",
  //           }}
  //         >
  //           <TextEditor
  //             scholarshipId={scholarshipId}
  //             details={details}
  //             formRef={formRef}
  //             wordLength={wordLength}
  //             fileSize={fileSize}
  //             fileTypes={fileTypes}
  //           />
  //         </Form.Item>
  //       );

  //     default:
  //       return null;
  //   }
  // };

  const renderField = ({ key, name, dataType, optional, limits, formRef }) => {
    if (limits) {
      limits = JSON.parse(limits);
      fileTypes = limits?.fileType?.map((item) => `.${item}`).join(",");
      fileSize = limits?.fileSize;
      wordLength = limits?.wordLength;
    }

    if (wordLength) {
      limitStr = `Limit: ${wordLength} words`;
    }

    if (fileSize) {
      limitStr = `${limitStr}, file size: ${fileSize} MB`;
    }

    if (fileTypes) {
      limitStr = `${limitStr}, File type: ${fileTypes}`;
    }

    switch (name) {
      case "Essay File":
        return (
          <Form.Item
            rules={[
              {
                required: optional,
                validator: (rule, value) => {
                  if (!value) {
                    return Promise.reject("Please add essay!");
                  }
                  return Promise.resolve();
                },
                validateTrigger: "onsubmit",
              },
            ]}
            name={name} // Use `key` as name
            getValueFromEvent={(val) => {
              if (val?.essay)
                return {
                  text: val?.essay,
                };

              if (val?.url) {
                return {
                  url: val?.url,
                };
              }
              return {
                url: val?.file?.response?.data,
              };
            }}
            style={{
              width: "100%",
            }}
          >
            <TextEditor
              scholarshipId={scholarshipId}
              details={details}
              formRef={formRef}
              wordLength={wordLength}
              fileSize={fileSize}
              fileTypes={fileTypes}
              limitStr={limitStr}
            />
          </Form.Item>
        );

      // default:
      //   return null;
    }

    switch (dataType) {
      case "file":
        return (
          <Form.Item
            rules={[
              {
                required: optional,
                message: "Please upload!",
              },
            ]}
            name={key}
            getValueFromEvent={(val) => {
              return val?.file?.response?.data?.publicUrl;
            }}
            showUploadList={{
              showRemoveIcon: false,
            }}
          >
            <Upload
              action={API_UPLOAD_URL}
              accept={fileTypes || ".pdf"}
              // accept=".doc,.docx,.pdf"
              maxCount={1}
              beforeUpload={(file) => {
                const isLt2M = file.size / 1024 / 1024 < (fileSize || 1); // 2MB file size limit
                if (!isLt2M) {
                  message.error(
                    `File must be smaller than ${fileSize || 1} MB!`
                  );
                }
                return isLt2M; // Return false to prevent upload if the file is too large
              }}
              onChange={(info) => {
                if (info.file.status === "done") {
                  message.success(
                    `${info.file.name} file uploaded successfully`
                  );
                  // console.log(formRef)

                  if (info.file.response.data) {
                    const currentObj = {
                      [key]: info.file.response.data.publicUrl,
                    };

                    let reqData =
                      JSON.parse(localStorage.getItem("reqData")) || {};
                    reqData = { ...reqData, ...currentObj };
                    localStorage.setItem("reqData", JSON.stringify(reqData));

                    return formRef?.setFieldsValue(currentObj);
                  }

                  // setSaveAndNext(false);
                } else if (info.file.status === "error") {
                  message.error(`${info.file.name} file upload failed.`);
                }
              }}
            >
              <Button
                type="primary"
                style={{
                  // backgroundColor: "rgb(141, 150, 255)",
                  // border: "none",
                  border: "none",
                  background: "rgb(141, 150, 255)",
                  padding: "12px 20px", // Adjusted padding to be more balanced
                  borderRadius: "8px",
                  display: "flex", // Added display flex
                  alignItems: "center", // Centered items vertically
                  height: "40px",
                }}
              >
                <UploadOutlined
                  style={{
                    color: "white",
                  }}
                />
                Upload {name}
              </Button>
            </Upload>

            {limitStr && (
              <Typography.Paragraph
                style={{
                  color: "#8D96FF",
                  marginTop: "8px",
                  textDecoration: "underline",
                  fontSize: "14px",
                  fontWeight: "500",
                  letterSpacing: "0.5px",
                }}
              >
                {limitStr}
              </Typography.Paragraph>
            )}
          </Form.Item>
        );

      case "link":
        return (
          <Form.Item
            rules={[
              {
                required: optional,
                message: "Please provide!",
              },
            ]}
            name={key}
            style={{
              width: "100%",
            }}
          >
            <Input
              placeholder={name}
              style={{
                padding: "8px",
                width: "100%",
                borderRadius: "8px",
              }}
              onChange={(e) => {
                const inputValue = e.target.value.trim();
                if (inputValue === "") {
                  // setSaveAndNext(() => true); // Input is empty, set state to false
                } else {
                  // setSaveAndNext(() => false); // Input is not empty, set state to true
                }
              }}
            />
          </Form.Item>
        );

      case "checkbox":
        return (
          <Form.Item
            rules={[
              {
                required: optional,
                message: "Please provide!",
              },
            ]}
            name={key}
            valuePropName="checked"
          >
            <Checkbox
              onChange={(e) => {
                const checked = e.target.checked;
                // console.log("🚀 ~ renderField ~ checked:", checked);

                // if (checked) {
                //   setSaveAndNext(false);
                // } else {
                //   setSaveAndNext(true);
                // }
              }}
            >
              {title}
            </Checkbox>
          </Form.Item>
        );

      case "text":
      case "editor":
        return (
          <Form.Item
            rules={[
              {
                required: optional,
                validator: (rule, value) => {
                  if (!value) {
                    return Promise.reject("Please add essay!");
                  }
                  return Promise.resolve();
                },
                validateTrigger: "onsubmit",
              },
            ]}
            name={key}
            getValueFromEvent={(val) => {
              if (val?.essay)
                return {
                  text: val?.essay,
                };

              if (val?.url) {
                return {
                  url: val?.url,
                };
              }
              return {
                url: val?.file?.response?.data,
              };
            }}
            style={{
              width: "100%",
            }}
          >
            <TextEditor
              scholarshipId={scholarshipId}
              details={details}
              formRef={formRef}
              wordLength={wordLength}
              fileSize={fileSize}
              fileTypes={fileTypes}
            />
          </Form.Item>
        );

      default:
        return null;
    }
  };

  function capitalizeFirstLetter(word) {
    if (!word) return ""; // Return empty string if input is empty or null
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  return (
    <>
      {/* <Col */}
      {/* // span={12} */}
      {/* > */}
      <h3 style={{ fontWeight: "600" }}>
        {capitalizeFirstLetter(title)}{" "}
        {!optional ? "(optional)" : <span style={{ color: "red" }}>*</span>}
      </h3>
      <Typography.Paragraph>{description}</Typography.Paragraph>
      {renderField({
        key: details?.field?.key,
        name: details?.field?.name,
        dataType: details?.field?.dataType,
        optional,
        title,
        limits,
        formRef,
      })}

      {/* </Col> */}
    </>
  );
};

export default Requirement;
