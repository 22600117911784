import HeaderNav from "components/layout-components/HeaderNav";
import Logo from "components/layout-components/Logo";
import MobileLogo from "components/layout-components/MobileLogo";
import React from "react";
import "./style.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Navbar = () => {
  const mobileView = window.innerWidth < 915;

  const history = useHistory();
  const checkToken = localStorage.getItem("token");
  return (
    <div>
      <div className="chatNav">
        {/* <Logo /> */}
        <div
          style={{
            // width: `${getLogoWidthGutter(props, isMobile)}`,
            marginRight: "20px",
            cursor: "pointer",
          }}
          onClick={() => {
            if (checkToken) {
              history.push("/auth/dashboard");
            } else {
              history.push("/");
            }
          }}
        >
          <img
            src="/new/oldLogo1.png"
            // src="/new/oldLogo.webp"
            alt="Lenny.AI"
            style={{ width: mobileView ? "150px" : "200px" }}
          />
        </div>
        {/* <HeaderNav /> */}
        <button
          className="chatNavBtn"
          onClick={() => {
            localStorage.setItem("login", "true");
            history.push("/chatbot");
          }}
          style={{
            display: mobileView ? "none" : "block",
          }}
        >
          Login with LennyAI
        </button>
      </div>
    </div>
  );
};

export default Navbar;
