import Loading from "components/shared-components/Loading";
import { AUTH_PREFIX_PATH } from "configs/AppConfig";
import { lazy, Suspense } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import ComingSoon from "views/app-views/scholarships/ComingSoon.js";
import EasyApplyWithCv from "views/app-views/scholarships/EasyApplyWithCv.js";
import FilterApply from "views/app-views/scholarships/FilterApply.js";
import Recommendation from "views/app-views/scholarships/Recommendation.js";
import MyScholarship from "views/app-views/scholarships/MyScholarship.js";
import Saved from "views/app-views/scholarships/Saved.js";
import ScholarshipDetail from "views/app-views/scholarships/ScholarshipDetail";
import UploadEssay from "views/app-views/scholarships/UploadEssay.js";
import useGaTracker from "../../useGaTracker.js";
import Assistance from "./assistance";
import AssistanceChat from "./assistance/AssistanceChat.js";
import Navbar from "./authentication/navbar/Navbar.js";
import PrivacyPolicy from "./authentication/PrivacyPolicy.js";
import Profile from "./authentication/profile";
import RegisterStep1 from "./authentication/RegisterStep1.js";
import TermsConditions from "./authentication/TermsConditions.js";
import Faq from "./authentication/faq";
import Contact from "./authentication/contact";
import Partners from "./authentication/partners";
import Pricing from "./authentication/Pricing";
import AboutUs from "./authentication/AboutUs";
// import Dashboard from "./dashboard";
import Inbox from "./inbox";
import { MsgSummary } from "./inbox/MsgSummary.js";
import Support from "./support";
import Subscription_support from "./subscription-support";
// import Setting from "./setting";
// import Success from "./success";
// import UserPlan from "./userPlan";
import Subscription from "components/layout-components/Subscription.js";
import SubscriptionMobileDesign from "components/layout-components/SubscriptionMobileDesign.js";
import SubscriptionPlan from "./subscription-plan";
import ChatBot from "./authentication/chatbot/index.js";
import LennyAssistance from "./authentication/LennyAssistance";
import ForgotPassword from "./authentication/forgotPassword/index.js";
import AssistanceChatBot from "./assistance/AssistanceChatBot.js";

export const AuthViews = (props) => {
  // console.log("🚀 ~ AuthViews ~ props:", props);
  const phoneView = window.innerWidth < 800;
  // console.log(phoneView);

  const Pricing = lazy(() => import("./authentication/Pricing"));
  const Dashboard = lazy(() => import("./dashboard"));
  const UserPlan = lazy(() => import("./userPlan"));
  const Success = lazy(() => import("./success"));
  const SubscriptionPlan = lazy(() => import("./subscription-plan"));
  const AssistanceChatBot = lazy(() =>
    import("./assistance/AssistanceChatBot.js")
  );
  const ChatBot = lazy(() => import("./authentication/chatbot/index.js"));

  const Setting = lazy(() => import("./setting"));
  const UnsubscribePage = lazy(() => import("./authentication/unsubscribe"));

  useGaTracker();
  const history = useHistory();
  return (
    <Suspense fallback={<Loading cover="page" />}>
      {history.location.pathname !== "/auth/login" && (
        <Navbar
          props={{
            pathname: history.location.pathname,
            setSideBars: props.setSideBars,
          }}
        />
      )}
      <Switch>
        <Route
          path={`/b2b`}
          component={lazy(() => import(`./authentication/Home.js`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/login`}
          component={lazy(() => import(`./authentication/RegisterStep2.js`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/register1`}
          component={lazy(() => import(`./authentication/RegisterStep2.js`))}
        />
        <Route
          path={`/signup`}
          component={lazy(() => import(`./authentication/RegisterStep1.js`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/userdetails`}
          component={lazy(() => import(`./authentication/RegisterStep3.js`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/register4`}
          component={lazy(() => import(`./authentication/RegisterStep4.js`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/apply`}
          component={lazy(() =>
            import(`views/app-views/scholarships/apply-easy.js`)
          )}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/profile`}
          component={() => <Profile props={props} />}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/dashboard/profile`}
          component={() => <Profile props={props} />}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/dashboard/assistanceChat`}
          component={() => (
            //  <AssistanceChat props={props} />
            <AssistanceChatBot props={props} />
          )}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/dashboard/easyApplyWithCv`}
          component={() => <EasyApplyWithCv props={props} />}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/dashboard/recommendation`}
          component={() => <Recommendation props={props} />}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/dashboard/myScholarship`}
          component={() => <MyScholarship props={props} />}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/scholarshipDetail`}
          component={() => (
            <ScholarshipDetail history={history} props={props} />
          )}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/dashboard/trackApplications`}
          component={() => <ComingSoon />}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/dashboard/subscription-support`}
          component={() => <Subscription_support />}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/dashboard/support`}
          component={() => <Support />}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/dashboard/setting`}
          component={() => <Setting />}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/dashboard/success`}
          component={() => <Success props={props} history={history} />}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/dashboard/subscription-plan`}
          component={() => <SubscriptionPlan props={props} history={history} />}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/dashboard/plan`}
          component={() => <UserPlan props={props} history={history} />}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/dashboard/assistance`}
          component={() => <Assistance />}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/uploadEssay`}
          component={() => <UploadEssay history={history} props={props} />}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/dashboard/saved`}
          component={() => <Saved props={props} />}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/dashboard/inbox`}
          component={() => <Inbox props={props} />}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/dashboard/message`}
          component={() => <MsgSummary history={history} props={props} />}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/inbox`}
          component={() => <Inbox history={history} props={props} />}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/message`}
          component={() => <MsgSummary history={history} props={props} />}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/dashboard/easy-apply`}
          component={() => <FilterApply props={props} />}
        />

        <Route
          path={`${AUTH_PREFIX_PATH}/error-1`}
          component={lazy(() => import(`./errors/error-page-1`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/error-2`}
          component={lazy(() => import(`./errors/error-page-2`))}
        />

        <Route
          path={`${AUTH_PREFIX_PATH}/applicants`}
          component={lazy(() => import(`views/app-views/applicants`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/applicants_review`}
          component={lazy(() => import(`views/app-views/applicants/review`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/rank-criteria`}
          component={lazy(() => import(`views/app-views/rank-criteria`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/winner`}
          component={lazy(() => import(`views/app-views/winner`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/dashboard`}
          component={() => <Dashboard history={history} props={props} />}
          // component={() => <Dashboard history={history} props={props} />}
        />
        <Route
          path={`/privacy`}
          component={() => <PrivacyPolicy history={history} props={props} />}
        />
        <Route
          path={`/terms`}
          component={() => <TermsConditions history={history} props={props} />}
        />
        <Route
          path={`/faq`}
          component={() => <Faq history={history} props={props} />}
        />
        <Route
          path={`/contact`}
          component={() => <Contact history={history} props={props} />}
        />
        <Route
          path={"/unsubscribe"}
          component={() => <UnsubscribePage history={history} props={props} />}
        />
        <Route
          path={"/lenny-terms-policy"}
          component={lazy(() =>
            import("./authentication/LennyTermAndPolicy/index.js")
          )}
        />
        <Route
          path={`/do-not-sell-my-personal-information`}
          component={lazy(() =>
            import("./authentication/DoNotSellInfo/index.js")
          )}
        />
        <Route
          path={`/partners`}
          component={() => <Partners history={history} props={props} />}
        />
        <Route
          path={`/pricing`}
          component={() => <Pricing history={history} props={props} />}
        />
        <Route
          path={`/about-us`}
          component={() => <AboutUs history={history} props={props} />}
        />
        <Route
          path={`/chatbot`}
          component={() => <ChatBot history={history} props={props} />}
        />
        <Route
          path={`/lennyAssistance`}
          component={() => <LennyAssistance history={history} props={props} />}
        />
        <Route
          path={`/forgot-password`}
          component={() => <ForgotPassword history={history} props={props} />}
        />
        {/* <Route
          path={`${AUTH_PREFIX_PATH}/forgot-password`}
          component={lazy(() => import(`./authentication/forgot-password`))}
        /> */}
        <Route
          path={`/`}
          component={lazy(() => import(`./authentication/RegisterStep2.js`))}
        />
      </Switch>
    </Suspense>
  );
};

export default AuthViews;
