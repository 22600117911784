import { useHistory } from "react-router-dom";
import React, { useState, useCallback } from "react";
import { Row, Col } from "antd";
import partnerData from "./partnerData.js";
import "./style.css";
import Footer from "../footer.js";

const Index = () => {
  const [selected, setSelected] = useState(0);
  const mobileView = window.innerWidth < 481;

  const centerView = ({ text1, text2 }) => {
    return (
      <li
        style={{
          gap: "30px",
          justifyContent: "center",
          flexDirection: "row",
          // maxWidth: "450px",
          display: "flex",
        }}
      >
        <img
          src="/fileContact.png"
          alt="Scholarship Matching"
          // height="9vh"
          // height="100px"
          style={{ paddingTop: "10px", height: "100px" }}
        />
        <div>
          <h3
            style={{
              color: "black",
              fontFamily: "Poppins",
              fontSize: "24px",
              lineHeight: "26px",
            }}
          >
            {text1}
          </h3>
          <p
            style={{
              color: "black",
              fontFamily: "Poppins",
              fontSize: "18px",
              fontWeight: "400",
              lineHeight: "26.66px",
              letterSpacing: "-0.2px",
            }}
          >
            {text2}
          </p>
        </div>
      </li>
    );
  };
  return (
    <div>
      <div
        className="term_container"
        style={{
          background:
            "linear-gradient(180deg, #DFE2FF 0%, #FFF 60.3%), url(/img/pricing/bg.png)",
          padding: "50px 50px 0 50px",
        }}
      >
        <h2
          style={{
            fontFamily: "Poppins",
            fontSize: "32px",
            letterSpacing: "-0.96px",
          }}
        >
          {" "}
          Become Our Partner
        </h2>

        <p>
          Partner with ScholarshipOwl, and get rewarded. We offer a wide range
          of lucrative partnership opportunities and payout models, so you can
          select the campaign that's right for you. Promote a great product and
          help students get matched with scholarships. Get in touch today and
          lets discuss how we can work together.
        </p>
      </div>
      <div
        className="term_container"
        style={{
          padding: "0 50px",
        }}
      >
        <h3
          style={{
            fontFamily: "Poppins",
            fontSize: "24px",
            letterSpacing: "-0.96px",
          }}
        >
          {" "}
          What You'll Be Promoting
        </h3>

        <p>
          ScholarshipOwl partners have easy opportunities to earn money because
          of the outstanding products and services we provide. To get a better
          understanding of what you'll be promoting, check out our core
          services.
        </p>
      </div>
      <div className="partner">
        <ul className="centerTab">{partnerData.map((e) => centerView(e))}</ul>
      </div>
      <div
        className="term_container"
        style={{
          padding: "0 50px",
        }}
      >
        <h3
          style={{
            fontFamily: "Poppins",
            fontSize: "24px",
            letterSpacing: "-0.96px",
          }}
        >
          {" "}
          How To Get Paid
        </h3>

        <p>
          We have several payout models to choose from in our partners program.
          You could choose to get paid at a certain time each month, or you
          could set up automated deposits once your earnings reach a
          pre-determined amount. Review our flexible payout options in your
          online account when you sign up today.
        </p>
      </div>

      <Footer />
    </div>
  );
};

export default Index;
