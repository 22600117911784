import { Steps } from "antd";
import React from "react";

const ApplicationStatus = ({
  requirements = [],
  completedSteps,
  applicationstatus,
}) => {
  const { Step } = Steps;
  const mobileView = window.innerWidth < 1200;

  return (
    <div
      style={{
        backgroundColor: "#F0F1FF",
        padding: "16px",
        borderRadius: "16px",
      }}
    >
      <h3> Application Status </h3>
      <Steps
        current={completedSteps}
        size="small"
        direction={mobileView ? "horizontal" : "vertical"}
        labelPlacement={!mobileView ? "horizontal" : "vertical"}
        style={{
          color: "rgb(141, 150, 255)",
        }}
      >
        <Step
          title={
            applicationstatus === "Received"
              ? "APPLIED"
              : "Candidate Eligibility"
          }
        />
        {/* {requirements.map((item) => (
          <Step
            title={item.title}
            style={{
              color: "rgb(141, 150, 255)",
            }}
          />
        ))} */}
        <Step
          title={
            applicationstatus === "Received" ? "ACCEPTED" : "Submit appliation"
          }
        />
      </Steps>
    </div>
  );
};

export default ApplicationStatus;
