export default [
  "Does Lenny.ai offer scholarships?",
  "Who is behind Lenny.ai?",
  "What is the difference between Lenny.ai and ScholarshipOwl?",
  "What makes Lenny.ai stand out from other online scholarship databases?",
  "Who can join Lenny.ai?",
  "Do I have to enter personal information to register with Lenny.ai? And if so, why?",
  "Can I avoid getting unwanted emails from scholarship providers. And, can I unsubscribe?",
  "Can I unsubscribe from all or some of the emails I get from Lenny.ai?",
  "Does Lenny.ai increase my chances of winning a scholarship?",
  "Is Lenny.ai legit?",
  "Does it matter when I apply for scholarships?",
  "How can I increase my own chances at winning scholarships?",
  "What can I do if I forgot my login information and cannot log in?",
  "It says that my profile is not 100% complete. What do I need to do to complete it?",
  "How does Lenny.ai protect my privacy?",
  "How do students get matched to scholarships?",
  "Can international students apply? And does Lenny support students in all countries?",
  "Can adult students apply?",
  "How do I delete my account?",
  "What do I need to do to apply for scholarships?",
  "Do I have to put my valid email address and phone number on the application?",
  "What makes me eligible for a scholarship?",
  "How long will it take to apply for all the scholarships I am eligible for?",
  "Why are there so many essays to write?",
  "How does Lenny.ai help with application Essays?",
  "How does Lenny.ai work?",
  "Are there any scholarships that do not require essays?",
  "What is the difference between scholarships, grants, and student loans?",
  "On my list of scholarships that I am eligible for, I have a scholarship that I applied to before. Should I apply again?",
  "Can Lenny find newly added scholarships and submit my application automatically?",
  "Can I automatically re-apply to recurring scholarships?",
  "Where can I view the status of my applications?",
  "Do I get an email confirmation that my scholarship application has been submitted?",
  "When do I find out if I have won a scholarship?",
  "If I won a scholarship, how will I be notified?",
  "If I win a scholarship, how are the funds transferred? Are they sent to my school or directly to me?",
  "Do I need to update my account details if my contact details have changed?",
  "I haven’t been contacted by any of the scholarship providers; does that mean I haven’t won?",
  "Can I complete a scholarship application later?",
  "What else should I do to prepare for college?",
  "Is there a limit to how much money I can receive in scholarships?",
  "What is the cost of studying?",
  "Are there scholarships for students with low GPAs?",
  "Are there scholarships for minorities?",
  "Can my scholarships pay for past student loans?",
  "Are scholarships school-specific?",
  "What is the best way to earn scholarships?",
];
