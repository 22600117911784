import {
  EditOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  ShopOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import Can from "casl/Can";
import Icon from "components/util-components/Icon";
import Avatar from "react-avatar";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { signOut } from "redux/actions/Auth";
import deleteCookie from "utils/deletecookie";
import mixpanel from "../../mixpanel/mixpanel";
import fetch_ from "interceptors/FetchInterceptor";
import { UserAuthService } from "services/user-auth.service";

const menuItem = [
  {
    title: "Edit Profile",
    icon: EditOutlined,
    path: "/",
  },
  {
    title: "Account Setting",
    icon: SettingOutlined,
    path: "/",
  },
  {
    title: "Billing",
    icon: ShopOutlined,
    path: "/",
  },
  {
    title: "Help Center",
    icon: QuestionCircleOutlined,
    path: "/",
  },
];

export const NavProfile = ({ signOut }) => {
  const profileImg = "/img/avatars/thumb-1.jpg";
  const history = useHistory();
  const userInfoString = localStorage.getItem("userInfo");
  let userInfo = {};
  if (userInfoString) {
    try {
      userInfo = JSON.parse(userInfoString);
    } catch (err) {
      console.error("Error parsing userInfo:", err);
    }
  }
  const fullName = userInfo.firstName?.toUpperCase() + " " + userInfo.lastName;

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={profileImg} />
          <div className="pl-3">
            <h4 className="mb-0">Charlie Howard</h4>
            <span className="text-muted">Frontend Developer</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}

          <Can I="create" a="super_user" passThrough>
            <Menu.Item key={menuItem.length + 1} onClick={(e) => {}}>
              <span>
                <UserOutlined />
                <span className="font-weight-normal">Switch to Admin</span>
              </span>
            </Menu.Item>
          </Can>
          <Menu.Item key={menuItem.length + 2} onClick={(e) => signOut()}>
            <span>
              <LogoutOutlined />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );

  const logout = async () => {
    const token = localStorage.getItem("token");
    try {
      await UserAuthService.logOut(token);
    } catch (error) {
      console.error("Error logging out:", error);
    }

    localStorage.removeItem("userInfo");
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("isActiveSubscription");
    localStorage.removeItem("profileData");
    localStorage.removeItem("zipCodeId");
    localStorage.removeItem("countryObj");
    localStorage.removeItem("linkId");
    localStorage.removeItem("sourceId");

    localStorage.removeItem("utm_source");
    localStorage.removeItem("utm_content");
    localStorage.removeItem("utm_term");
    localStorage.removeItem("utm_campaign");
    localStorage.removeItem("utm_medium");
    deleteCookie("trakdesk_cid");
    history.push("/auth/register");
    mixpanel.track("Logout Button Clicked");
    window.location.reload();
  };

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          history.push({
            pathname: "/auth/profile",
          });
        }}
      >
        My Profile
      </Menu.Item>
      <Menu.Item onClick={logout}>Logout</Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      placement="bottomRight"
      overlay={menu}
      trigger={["click"]}
      style={{
        margin: "0",
      }}
    >
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar round={true} size="35" name={fullName} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default connect(null, { signOut })(NavProfile);
