import { Col, Row } from "antd";
import React from "react";
import DOMPurify from "dompurify";

const ScholarshipInfo = ({ scholarship }) => {
  const mobileView = window.innerWidth < 1200;
  const mView = window.innerWidth < 600;
  const view = ({ icon, text1, text2, number, display = "inline-flex" }) => {
    return (
      <Col
        span={6}
        style={
          number !== "4"
            ? {
                width: mobileView ? "100%" : "25%",
                borderRightWidth: !mobileView && "1px",
                borderRightStyle: !mobileView && "double",
                borderRightColor: !mobileView && "rgba(29, 37, 45, 0.13)",
                marginLeft: number !== "1" && !mobileView && "20px",
                display: mobileView && display,
                marginBottom: mobileView && "10px",
              }
            : {
                marginLeft: !mobileView && "20px",
                display: mobileView && display,
                width: mobileView && "100%",
              }
        }
      >
        <img src={icon} style={{ height: "36px", marginRight: "14px" }} />
        <div style={{ width: "100%" }}>
          <h2
            style={{ fontSize: "16px", marginBottom: "0px", fontWeight: "500" }}
          >
            {text1}
          </h2>
          <span style={{ fontSize: "14px", fontWeight: "400" }}>{text2}</span>
        </div>
      </Col>
    );
  };

  const buttonViews = () => {
    return (
      <Row style={{ marginBottom: "20px", marginTop: "20px" }}>
        <Col
          style={{
            width: mobileView ? "100%" : "47%",
            background: "#EFFCEF",
            borderRadius: "12px",
          }}
        >
          <Row style={{ padding: "15px" }}>
            <Col style={{ alignSelf: "center" }}>
              <img
                src="/applicantImg/contact.png"
                style={{ height: "60px", marginRight: "4px" }}
              />
            </Col>
            <Col
              style={{
                textAlign: "left",
                paddingLeft: "5px",
                alignSelf: "center",
              }}
            >
              <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                BeArt-Presets
              </span>
              <br />
              <span style={{ fontSize: "14px" }}>Scholarship Provider</span>
            </Col>
          </Row>
        </Col>
        <Col style={{ width: "1%" }} />
        <Col
          style={{
            width: mobileView ? "100%" : "48%",
            marginTop: mobileView && "10px",
            background: "#FFEFE2",
            borderRadius: "12px",
          }}
        >
          <Row style={{ padding: "15px" }}>
            <Col style={{ alignSelf: "center" }}>
              <img
                src="/applicantImg/money.png"
                style={{ height: "60px", marginRight: "4px" }}
              />
            </Col>
            <Col
              style={{
                textAlign: "left",
                paddingLeft: "5px",
                alignSelf: "center",
              }}
            >
              <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                Funds are paid to college
              </span>
              <br />
              <span style={{ fontSize: "14px" }}>Winner payment</span>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };
  function extractTextFromHTML(html) {
    // Create a temporary element
    const tempElement = document.createElement("div");
    // Set the HTML content of the temporary element
    tempElement.innerHTML = html;
    // Return the text content of the temporary element
    return tempElement.textContent || tempElement.innerText || "";
  }

  function capitalizeFirstLetter(word) {
    if (!word) return ""; // Return empty string if input is empty or null
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  return (
    <>
      <h2
        style={{
          font: "Poppins",
          fontWeight: "600",
          fontSize: "24px",
          marginTop: "10px",
        }}
      >
        {capitalizeFirstLetter(scholarship.title) || "No Name"}
      </h2>
      {/* <Row style={{
        // paddingTop: "25px",
        paddingBottom: "25px"
      }}> */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {view({
          icon: "/applicantImg/date.png",
          text1: scholarship?.recurrenceConfig?.deadline || "DD / MM / YYYY",
          text2: "Scholarship Deadline",
          number: "1",
        })}
        {view({
          icon: "/applicantImg/icon2.png",
          text1:
            (scholarship?.amounts &&
              scholarship.amounts.reduce((acc, curr) => acc + curr, 0)) ||
            scholarship?.awards,
          text2: "Budget",
          number: "2",
        })}
        {/* {view({
          icon: "/applicantImg/phone.png",
          text1: scholarship?.ccWinnerEmail?.length,
          text2: "Estimated Participants",
          number: "3",
        })} */}
        {view({
          icon: "/applicantImg/file.png",
          text1: scholarship?.requirements?.length,
          text2: "Requirement",
          number: "4",
          display: mView ? "none" : "inline-flex",
        })}
      </div>
      {/* </Row> */}
      <p
        style={{
          marginTop: "16px",
          fontWeight: "600",
          fontFamily: "Poppins",
          fontSize: "24px",
          color: "black",
          marginBottom: "0",
        }}
      >
        Description
      </p>
      <p style={{ color: "black", fontWeight: "400", fontSize: "16px" }}>
        {extractTextFromHTML(DOMPurify.sanitize(scholarship?.description))}
      </p>
      {/* <p
        style={{ color: "black", fontWeight: "400" }}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(scholarship?.description),
        }}
      ></p> */}
      {false && buttonViews()}
    </>
  );
};

export default ScholarshipInfo;
