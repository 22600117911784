export const searchForMessages = (val, data) => {
  const searchKey = val?.toLowerCase();

  const output = data?.filter((item) => {
    if (item?.fromName?.S?.toLowerCase().indexOf(searchKey) >= 0) return true;

    if (item?.fromEmail?.S?.toLowerCase().indexOf(searchKey) >= 0) return true;

    if (item?.bodyAsText?.S?.toLowerCase().indexOf(searchKey) >= 0) return true;

    if (item?.bodyAsHtml?.S?.toLowerCase().indexOf(searchKey) >= 0) return true;

    if (item?.subject?.S?.toLowerCase().indexOf(searchKey) >= 0) return true;

    return false;
  });

  return output;
};
