import { PUBLIC_REQUEST_KEY, API_PATH } from "../constants/common.constant";
import fetch_ from "../interceptors/FetchInterceptor";

const {
  USER_LOGIN,
  EligibilityStats,
  USER_GOOGLE_LOGIN,
  USERS_PROFILE,
  USER_LOGOUT,
  USER_PASSWORD,
  USER_PASSWORD_LINK,
  USER_REGISTER,
  UPDATE_USER_APPLICANT,
} = API_PATH;

export const UserAuthService = {
  login: (data) => {
    return fetch_({
      url: USER_LOGIN,
      method: "POST",
      headers: {
        [PUBLIC_REQUEST_KEY]: true,
      },
      data,
    });
  },

  getEligibilityStats: (filter) => {
    return fetch_({
      url: EligibilityStats,
      method: "GET",
      params: filter,
    });
  },

  googleLogin: (data) => {
    return fetch_({
      url: USER_GOOGLE_LOGIN,
      method: "POST",
      headers: {
        [PUBLIC_REQUEST_KEY]: true,
      },
      data,
    });
  },
  register: (data) => {
    return fetch_({
      url: USER_REGISTER,
      method: "POST",
      headers: {
        [PUBLIC_REQUEST_KEY]: true,
      },
      data,
    });
  },
  resetPasswordLink: (data) => {
    return fetch_({
      url: USER_PASSWORD_LINK,
      method: "POST",
      headers: {
        [PUBLIC_REQUEST_KEY]: true,
      },
      data,
    });
  },
  resetPassword: (data) => {
    return fetch_({
      url: USER_PASSWORD,
      method: "POST",
      headers: {
        [PUBLIC_REQUEST_KEY]: true,
      },
      data,
    });
  },
  logOut: (token) => {
    return fetch_({
      url: USER_LOGOUT,
      method: "POST",
      data: { token },
    });
  },
  getProfile: () => {
    return fetch_({
      url: USERS_PROFILE,
      method: "GET",
    });
  },
  updateUserApplicant: (applicantId, data) => {
    return fetch_({
      url: UPDATE_USER_APPLICANT(applicantId),
      method: "PUT",
      headers: {
        [PUBLIC_REQUEST_KEY]: true,
      },
      data,
    });
  },
};
