import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Checkbox,
  Alert,
  Spin,
  Typography,
  Divider,
  Steps,
} from "antd";
import Utils from "utils/common";
import { UserAuthService } from "services/user-auth.service";
import { useHistory } from "react-router-dom";
import useAxios from "axios-hooks";
import registerStyle from "./registerStyle.js";
import { motion } from "framer-motion";
import { showLoading } from "redux/actions/Auth";
import rules from "./rules.js";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/en/world.json";
import "antd/dist/antd.css";
import "antd-country-phone-input/dist/index.css";
import useAnalyticsEventTracker from "../../../useAnalyticsEventTracker";
import "./register.scss";
import mixpanel from "mixpanel-browser";
import { LoadingOutlined } from "@ant-design/icons";
import EligibilityStats from "./EligibilityStats.js";
import SignupSlider from "components/layout-components/SignupSlider.js";

const RegisterStep1 = () => {
  const { Step } = Steps;
  const { Text } = Typography;
  const mobileView = window.innerWidth < 961;
  const [form] = Form.useForm();
  const history = useHistory();
  const [checkBox1, setCheckBox1] = useState("");
  const [country, setCountry] = useState("");
  const [checkBoxTerm, setcheckBoxTerm] = useState("");
  const formValues = localStorage.getItem("register1")
    ? JSON.parse(localStorage.getItem("register1"))
    : {};
  const [alertShow, setAlertShow] = useState();
  const [, setDisableButton] = useState(false);
  const [alertMessage, setAlertMessage] = useState();
  const [alertType, setAlertType] = useState(true);
  const [loader, setLoader] = useState(false);
  const [countryLoader, setCountryLoader] = useState(false);
  // const originaleligibiltyValues = localStorage.getItem("register2")
  //   ? JSON.parse(localStorage.getItem("register2"))
  //   : {};
  // const fieldMappings = {
  //   "b9a172af-372b-4208-9036-470f5ff39b9b": "currentSchoolLevel",
  //   "6a9b2a3d-30f8-4980-af37-0cec301577e9": "fieldOfStudy",
  //   "8cba9cc2-3813-11ee-9f7a-16dca43b6f0b": "dob",
  // };

  // Create updated eligibilityValues object
  // const eligibilityValues = {};
  // for (const fieldId in fieldMappings) {
  //   const originalField = fieldMappings[fieldId];
  //   eligibilityValues[`field.${fieldId}`] =
  //     originaleligibiltyValues[originalField];
  // }

  //const eligibilityFiltersQueryParam = eligibilityValues;

  //  const [{ data: stats = { counts: 0, totalValue: 0 } }] = useAxios(
  //   `scholarships/eligible-scholarships/stats?eligiblityFilters=${eligibilityFiltersQueryParam}`,
  //   {
  //     headers: {
  //       accept: "*/*"
  //     }
  //   }
  // );

  const [stats, setStatsData] = useState({
    counts: 0,
    totalValue: 0,
  });
  const bigSc = window.innerWidth < 1220;

  useEffect(() => {
    mixpanel.track("User Signup Page visited");
    // getEligibilityStats();
  }, []);

  // const getEligibilityStats = () => {
  //   const filter = {
  //     eligiblityFilters: eligibilityValues,
  //   };
  //   // console.log("filter", filter);
  //   return UserAuthService.getEligibilityStats(filter)
  //     .then((response) => {
  //       // console.log("fields data ", response);
  //       setStatsData(response.data);
  //     })
  //     .finally();
  // };
  const gaEventTracker = useAnalyticsEventTracker("Signup Detail");

  function getCookie(name) {
    // Construct the name pattern to search for, followed by an '=' sign
    let nameEQ = name + "=";
    // Split document.cookie into individual cookies
    let cookies = document.cookie.split(";");

    // Loop through each cookie
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();
      // If the cookie's name matches the name we're looking for
      if (cookie.indexOf(nameEQ) === 0) {
        // Return the cookie's value
        return cookie.substring(nameEQ.length, cookie.length);
      }
    }
    // If the cookie is not found, return null
    return null;
  }

  const registerConversion = () => {
    if (window.trackdesk) {
      window.trackdesk("lennyai", "conversion", {
        conversionType: "lead",
        amount: {
          value: "0",
        },
        customParams: {
          advS1: "Account created",
        },
      });
    }
  };

  const userInfoString2 = localStorage.getItem("register2");

  let userInfo2 = {};
  if (userInfoString2) {
    try {
      userInfo2 = JSON.parse(userInfoString2);
    } catch (err) {
      console.error("Error parsing userInfo:", err);
    }
  }

  const onSignUp = () => {
    let label = {
      screenName: "signup ",
    };
    gaEventTracker("Account clicked", JSON.stringify(label));
    mixpanel.track("Sign Up button clicked", {
      buttonName: "Continue ",
      pageName: "User Sign Up",
    });
    const linkId = localStorage.getItem("linkId");
    const sourceId = localStorage.getItem("sourceId");
    const utmSource = localStorage.getItem("utm_source");
    const utmContent = localStorage.getItem("utm_content");
    const utmTerm = localStorage.getItem("utm_term");
    const utmCampaign = localStorage.getItem("utm_campaign");
    const utmMedium = localStorage.getItem("utm_medium");

    const utmObj = {};
    if (utmSource) utmObj.utm_source = utmSource;
    if (utmContent) utmObj.utm_content = utmContent;
    if (utmTerm) utmObj.utm_term = utmTerm;
    if (utmCampaign) utmObj.utm_campaign = utmCampaign;
    if (utmMedium) utmObj.utm_medium = utmMedium;

    if (checkBoxTerm && checkBox1) {
      setDisableButton(true);

      form
        .validateFields()
        .then((values) => {
          setLoader(true);

          let postData = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: "+" + values.contactNo.code + values.contactNo.phone,
            password: values.password,
            applicantType: "STUDENT",
            isProfileUpdate: "false",
            isScholarshipApplied: "false",
            isTrailTaken: "false",
          };

          if (userInfo2?.dob) {
            postData.dob = userInfo2.dob;
          }

          if (userInfo2.currentSchoolLevel && userInfo2?.fieldOfStudy) {
            postData.applicantFields = [
              {
                fieldId: "b9a172af-372b-4208-9036-470f5ff39b9b",
                fieldValue: userInfo2?.currentSchoolLevel,
              },
              {
                fieldId: "6a9b2a3d-30f8-4980-af37-0cec301577e9",
                fieldValue: userInfo2?.fieldOfStudy,
              },
            ];
          }

          if (linkId !== null && sourceId !== null) {
            const trackdesk_cid = JSON.parse(getCookie("trakdesk_cid"));

            if (trackdesk_cid) {
              postData = {
                ...postData,
                trackdeskClickId: trackdesk_cid?.cid ?? "",
              };
            }

            postData = {
              ...postData,
              affiliateId: sourceId,
            };
          }

          if (utmSource && !sourceId) {
            postData = {
              ...postData,
              affiliateId: utmSource,
            };
          }

          localStorage.setItem("register1", JSON.stringify(values));
          window.fbq("track", "Lead", {
            value: 0.05,
            currency: "$",
          });
          UserAuthService.register(postData)
            .then((data) => {
              // console.log("🚀 ~ .then ~ data:", data.data.clientIp);
              localStorage.setItem("clientIp", data?.data?.clientIp);
              if (data.statusCode === 201) {
                // registerConversion();
                localStorage.setItem(
                  "refresh_token",
                  typeof data.data.refreshToken === "string"
                    ? data.data.refreshToken
                    : JSON.stringify(data.data.refreshToken)
                );
                localStorage.setItem(
                  "token",
                  typeof data.data.accessToken === "string"
                    ? data.data.accessToken
                    : JSON.stringify(data.data.accessToken)
                );
                localStorage.setItem(
                  "userInfo",
                  JSON.stringify(data.data.user)
                );
                localStorage.setItem("shortCountry", country);
                // console.log("here");

                // all events

                mixpanel.track("User Sign Up success", {
                  pageName: "User SignUp",
                });

                gaEventTracker(
                  "Account created",
                  JSON.stringify({
                    utmSource,
                  })
                );

                // gaEventTracker(
                //   "Account created", // Action
                //   "UTM Details",    // Label - keep it concise
                //   utmObj            // Pass `utmObj` as custom parameters
                // );
                if (utmObj.utm_source) {
                  mixpanel.track("Account created", {
                    ...utmObj,
                  });
                }
                //events end
                history.push({
                  pathname: "/auth/userdetails",
                  params: {
                    ...history.location.params,
                    ...values,
                    password: "",
                    confirm: "",
                  },
                });
              }
            })
            .catch((info) => {
              mixpanel.track("User Sign Up error", {
                error: info?.description || "Server Error",
              });
              setAlertShow(true);
              setAlertMessage(info.description || "Server Error");
              setAlertType(false);
            })
            .finally(() => {
              showLoading(false);
              setDisableButton(false);
              setLoader(false);
            });
        })
        .catch((info) => {
          setDisableButton(false);
          console.log("Validate Failed:", info);
          setLoader(false);
        });
    } else {
      checkBox1 === "" && setCheckBox1(false);
      checkBoxTerm === "" && setcheckBoxTerm(false);
    }
  };

  const onCheckBox1 = (e) => {
    setCheckBox1(e.target.checked);
  };

  const onCheckBoxTerm = (e) => {
    setcheckBoxTerm(e.target.checked);
  };

  // function getIp(callback) {
  //   fetch("https://ipinfo.io/json?token=d580b9741ba3c2")
  //     .then((resp) => {
  //       return resp.json();
  //     })
  //     .catch((err) => {
  //       return {
  //         country: "us",
  //       };
  //     })
  //     .then((resp) => callback(resp.country));
  // }

  // getIp((country) => {
  //   setCountry(country);
  // });

  const getIp = async () => {
    setCountryLoader(true);
    try {
      const response = await fetch(
        "https://ipinfo.io/json?token=d580b9741ba3c2"
      );
      const data = await response.json();
      setCountry(data.country);
    } catch (err) {
      setCountry("us");
    } finally {
      setCountryLoader(false);
    }
  };

  useEffect(() => {
    const fetchCountryAndStats = async () => {
      await getIp();
    };

    fetchCountryAndStats();
  }, []);

  const getFlag = (short) => {
    const data = require(`world_countries_lists/data/flags/24x24/${short.toLowerCase()}.png`);
    if (typeof data === "string") {
      return data;
    }
    // for CRA
    return data.default;
  };

  if (country === "") {
    return (
      <div
        style={{
          // position: "absolute",
          // left: mobileView ? window.innerWidth / 2 : "720px",
          // top: window.innerHeight / 2,
          display: "grid",
          placeItems: "center",
          height: "100vh",
        }}
      >
        <Spin size={"large"} />
      </div>
    );
  }

  // const showText1 = () => {
  //   return (
  //     <Text
  //       style={{
  //         ...registerStyle.headingText,
  //         padding: mobileView ? "0 5%" : "0 15%",
  //       }}
  //     >
  //       {/* You have the opportunity to apply for numerous scholarships, worth of
  //       millions awaiting you! */}
  //       We would need more detail in order to give you a perfects scholarship!
  //     </Text>
  //   );
  // };

  // const showText = () => {
  //   // console.log("stats value ", stats.counts);
  //   const formattedValue = Utils.formatAsUSD(stats.totalValue);
  //   return (
  //     <>
  //       <si
  //         style={{
  //           ...registerStyle.headingText,
  //           padding: mobileView ? "0 5%" : "0 15%",
  //         }}
  //       >
  //         You qualify to apply for up to{" "}
  //       </Text>
  //       <Text
  //         style={{
  //           ...registerStyle.headingText,
  //           padding: mobileView ? "0 5%" : "0 15%",
  //           fontWeight: "600",
  //         }}
  //       >
  //         {stats?.counts} scholarships, with a total value of {formattedValue}
  //         <Text
  //           style={{
  //             ...registerStyle.headingText2,
  //             padding: mobileView ? "0 1%" : "0 1%",
  //           }}
  //         ></Text>
  //       </Text>
  //     </>
  //   );
  // };

  return (
    <div style={{ background: "#ffffff" }}>
      <Row
        style={{
          // flexDirection: mobileView && "column",
          // height: !mobileView && window.innerHeight,

          height: "calc(100vh - 80px)",
          rowGap: "0px",
          overflowY: "auto",
        }}
      >
        <Col
          style={
            mobileView
              ? { background: "#ffffff", width: "100vw" }
              : { width: "50%" }
          }
        >
          <EligibilityStats />
          {!mobileView && (
            <div
              style={{
                width: "300px",
                // margin: "auto",

                backgroundImage: 'url("/new/chatNavbg.png")',
                backgroundSize: "cover",
                width: "100%",
                alignItems: bigSc ? "center" : "flex-start",
                justifyContent: bigSc ? "center" : "flex-start",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  position: "relative",

                  zIndex: "2",
                  display: bigSc ? "none" : "block",
                }}
              >
                <SignupSlider />
              </div>

              <div
                style={{
                  width: bigSc ? "300px" : "185px",
                  position: "absolute",
                  bottom: "0",
                  zIndex: "1",
                }}
              >
                <img
                  src="/applicantImg/signup2_register.png"
                  style={{ width: "100%", margin: "" }}
                />
              </div>
            </div>
          )}
        </Col>
        <Col
          style={{
            marginTop: "25px",
            padding: mobileView ? "0 5%" : "0 20px 0 30px",
            width: mobileView ? "" : "50%",
            background: "#F0F1FF",
          }}
        >
          <Steps
            current={1}
            style={{ width: "auto", marginTop: "16px" }}
            size="small"
            labelPlacement="vertical"
            //progressDot={customDot}
            responsive={false}
          >
            {mobileView ? (
              <>
                <Step title="Scholarship " description="Eligibility" />
                <Step title="Signup " description="Details" />
                <Step title="Additional " description="Details" />
              </>
            ) : (
              <>
                <Step title="Scholarship Eligibility" />
                <Step title="Signup Details" />
                <Step title="Additional Details" />
              </>
            )}
          </Steps>

          {
            <motion.div
              initial={{ opacity: 0, marginBottom: 0 }}
              animate={{
                opacity: alertShow ? 1 : 0,
                marginBottom: alertShow ? 20 : 0,
              }}
            >
              <Alert
                type={alertType ? "success" : "error"}
                showIcon
                message={alertMessage}
              ></Alert>
            </motion.div>
          }
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Text
              style={{
                fontSize: "18px",
                fontWeight: "600",
                lineHeight: "24px",
              }}
            >
              Sign up details{" "}
            </Text>
            <Text
              style={{
                textDecoration: "underline",
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "24px",
                color: "#7A83EB",
                fontWeight: "500",
                cursor: "pointer",
              }}
              onClick={() => {
                localStorage.setItem("login", false);

                history.push("/chatbot");
              }}
            >
              Back to assistant
            </Text>
          </div>
          <Form
            form={form}
            layout="vertical"
            name="register-form-step1"
            initialValues={
              formValues && {
                ...formValues,
              }
            }
            onFinish={onSignUp}
          >
            <Row
              style={
                mobileView
                  ? { flexDirection: "column", marginTop: "25px" }
                  : { marginTop: "25px" }
              }
            >
              <Col style={registerStyle.column1st}>
                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={rules.firstName}
                  hasFeedback
                >
                  <Input
                    style={{ borderRadius: "8px", height: "50px" }}
                    placeholder=" First Name"
                  />
                </Form.Item>
              </Col>
              <Col style={registerStyle.column2nd}>
                <Form.Item
                  name="lastName"
                  label="Last Name"
                  rules={rules.lastName}
                  hasFeedback
                >
                  <Input
                    style={{ borderRadius: "8px", height: "50px" }}
                    placeholder=" Last Name"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row style={mobileView && { flexDirection: "column" }}>
              <Col style={registerStyle.column1st}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={rules.email}
                  hasFeedback
                >
                  <Input
                    style={{ borderRadius: "8px", height: "50px" }}
                    placeholder=" Email"
                  />
                </Form.Item>
              </Col>
              <Col style={registerStyle.column2nd}>
                <ConfigProvider
                  locale={en}
                  areaMapper={(area) => {
                    return {
                      ...area,
                      emoji: (
                        <img
                          alt="flag"
                          style={{
                            width: 18,
                            height: 18,
                            verticalAlign: "sub",
                          }}
                          src={getFlag(area.short)}
                        />
                      ),
                    };
                  }}
                >
                  <Form.Item
                    name="contactNo"
                    label="Phone number"
                    initialValue={{
                      short: country,
                    }}
                    rules={rules.contactNo}
                    hasFeedback
                  >
                    <CountryPhoneInput
                      style={{ borderRadius: "8px", height: "50px" }}
                    />
                  </Form.Item>
                </ConfigProvider>
              </Col>
            </Row>
            <Row style={mobileView && { flexDirection: "column" }}>
              <Col style={registerStyle.column1st}>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={rules.password}
                  hasFeedback
                >
                  <Input.Password
                    placeholder=" Password"
                    style={{ borderRadius: "8px", height: "50px" }}
                  />
                </Form.Item>
              </Col>
              <Col style={registerStyle.column2nd}>
                <Form.Item
                  name="confirm"
                  label="Confirm Password"
                  rules={rules.confirm}
                  hasFeedback
                >
                  <Input.Password
                    placeholder="Confirm Password"
                    style={{ borderRadius: "8px", height: "50px" }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row style={mobileView && { flexDirection: "column" }}>
              <Col style={registerStyle.column1st}>
                {checkBoxTerm === false && (
                  <div style={{ color: "#ff6b72" }}>Please Select!</div>
                )}
                <p
                  style={{
                    color: "black",
                    fontWeight: "400",
                    marginTop: "10px",
                  }}
                >
                  <Checkbox
                    style={{ marginRight: "10px" }}
                    onChange={onCheckBoxTerm}
                  />
                  By clicking, I provide my electronic signature and consent for
                  calls/texts regarding offers at the phone number.
                </p>
              </Col>
              <Col
                style={{
                  ...registerStyle.column2nd,
                  marginTop: mobileView ? "" : "10px",
                }}
              >
                <span>
                  {checkBox1 === false && (
                    <div style={{ color: "#ff6b72" }}>Please Select!</div>
                  )}
                  <Checkbox
                    style={{ marginRight: "10px" }}
                    onChange={onCheckBox1}
                  />
                  I agree with the
                  <a
                    onClick={() => {
                      history.push("/terms");
                    }}
                  >
                    {" "}
                    Terms of Use, Privacy, Policy
                  </a>
                  and the
                  <a
                    onClick={() => {
                      history.push("/lenny-terms-policy");
                    }}
                  >
                    {" "}
                    offical rules of scholarships
                  </a>
                </span>
              </Col>
            </Row>

            {mobileView && <Divider style={{ margin: "24px 0 0" }} />}

            <Form.Item style={{ textAlignLast: "center" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={registerStyle.buttonStyle}
                background="#8D96FF"
                //onClick={registerConversion}
              >
                <span style={registerStyle.buttonText}>
                  Continue{" "}
                  {loader ? (
                    <LoadingOutlined style={{ marginRight: 10 }} />
                  ) : null}{" "}
                </span>
              </Button>
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "14px",
                  color: "#4CAF50",
                  textAlign: "center",
                  marginTop: "8px",
                  fontFamily: "Poppins",
                  letterSpacing: "0.5px",
                }}
              >
                Login Daily, Unlock 3 Scholarships
              </p>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default RegisterStep1;
