import {
  Button,
  Col,
  Form,
  Row,
  Space,
  Spin,
  notification,
  Modal,
  Steps,
} from "antd";
import useAxios from "axios-hooks";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import Flex from "components/shared-components/Flex";
import { useEffect, useState } from "react";
import { ScholarshipService_ } from "services/scholarship.service";
import ApplicationStatus from "./ApplicationStatus";
import Requirement from "./Requirement";
import ScholarshipInfo from "./ScholarshipInfo";
import ThankYouModal from "./ThankYouModal";
import { createPayload, getInitialValues } from "./utils";
import HtmlPopup from "../components/HtmlPopUp";
import Scrollbars from "react-custom-scrollbars-2";
import RequirementAss from "./RequirementAss";
import { useAssitanceChatBotContext } from "context/ChatbotContext";
import MobileReqAss from "components/layout-components/MobileReqAss";
import { LeftOutlined, LoadingOutlined } from "@ant-design/icons";
import FirstThankyouPopUp from "components/layout-components/FirstThankYouPopup";

const ScholarshipDetail = ({ history, props }) => {
  // console.log("🚀 ~ ScholarshipDetail ~ props:", props);

  const {
    showChat,
    setShowChat,
    reqAssMaxView,
    setReqMaxView,
    mobReqAssMaxView,
    setMobReqMaxView,
    qusCounter,
    currentRequirementIndex,
    setCurrentRequirementIndex,
    saveAndNext,
    setSaveAndNext,
    askNextQus,
    handleNext,
  } = useAssitanceChatBotContext();

  const { Step } = Steps;
  const [hideSaveAndNext, setHideSaveAndNext] = useState(false);

  const id = history?.location?.search?.slice(1);
  const [{ data = {}, loading }] = useAxios(`/scholarships/${id}`);
  const [{ data: existingData, loading: loadingExistingData }] = useAxios(
    `/scholarships/${id}/applicants/get-applicant-details`
  );
  const [completedSteps, setCompletedSteps] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [form] = Form.useForm();
  const mobileView = window.innerWidth < 1200;
  const tabView = window.innerWidth < 950;
  const mView = window.innerWidth < 500;
  const [essayReq, setEssayReq] = useState([]);

  const [startChat, setStartChat] = useState(false);
  const [assMessages, setAssMessages] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [draftLoader, setDraftLoader] = useState(false);
  // const [isFormValid, setIsFormValid] = useState(false)
  const [firstScholarshipPop, setFirstScholarshipPop] = useState(false);

  useEffect(() => {
    // console.log("🚀 ~ existingData:", existingData);
    if (data?.requirements) {
      // console.log("🚀 ~ useEffect ~ data?.requirements:", data?.requirements)
      let isEssayReq = data?.requirements?.filter((elm) => {
        return (
          elm.field.dataType === "text" ||
          elm.field.dataType === "editor" ||
          elm.field.name === "Essay File"
        );
      });
      // console.log("🚀 ~ isEssayReq ~ isEssayReq:", isEssayReq);
      setEssayReq(isEssayReq);

      if (data?.requirements.length === 0) {
        // console.log(
        //   "🚀 ~ useEffect ~ data?.requirements:",
        //   data?.requirements.length
        // );
        setHideSaveAndNext(() => true);
      }
    }
  }, [data]);

  useEffect(() => {
    if (props?.setSideBars) {
      props.setSideBars(false);
    }
  }, []);

  // console.log("🚀 ~ ScholarshipDetail ~ isModalVisible:", isModalVisible);

  const userInfoString = localStorage.getItem("userInfo");
  let userInfo = {};
  if (userInfoString) {
    try {
      userInfo = JSON.parse(userInfoString);
    } catch (err) {
      console.error("Error parsing userInfo:", err);
    }
  }

  const onFormFieldsChange = async (changedValues, values) => {
    // console.log("🚀 ~ onFormFieldsChange ~ changedValues:", changedValues, values)
    let reqData = JSON.parse(localStorage.getItem("reqData")) || {};
    reqData = { ...reqData, ...values };
    localStorage.setItem("reqData", JSON.stringify(reqData));

    let completed = 1;

    data?.requirements?.forEach((item, idx) => {
      if (values[item?.field?.name]) {
        completed = idx + 2;
      }
    });

    setCompletedSteps(completed);
  };

  const onSubmitForm = (values, e) => {
    setSubmitLoader(() => true);
    try {
      form
        .validateFields()
        .then(() => {})
        .catch((error) => {
          throw new Error("Validation failed:", error);
        });

      const formData = JSON.parse(localStorage.getItem("reqData")) || {};

      const payload = createPayload(formData, data);
      // console.log("🚀 ~ onSubmitForm ~ payload:", payload);
      ScholarshipService_.submitIndividualScholarship(payload)
        .then((res) => {
          userInfo.scholarshipCount = res?.data?.scholarshipCount;
          localStorage.setItem("userInfo", JSON.stringify(userInfo));

          if (userInfo.scholarshipCount === 2) {
            setFirstScholarshipPop(true);
            setSubmitLoader(false);
            return;
          }

          setModalOpen(true);
          setSubmitLoader(false);
        })
        .catch((err) => {
          notification.open({
            message: "Something went wrong",
            type: "error",
          });
          setSubmitLoader(false);
        });
    } catch (error) {
      console.log("🚀 ~ onSubmitForm ~ error:", error);
      setSubmitLoader(false);
    }
  };
  useEffect(() => {
    console.log(submitLoader);
  }, [submitLoader]);

  const onSubmitDraft = () => {
    // const values = form.getFieldsValue();
    setDraftLoader(true);

    const values = JSON.parse(localStorage.getItem("reqData")) || {};

    let payload = createPayload(values, data);
    payload = { ...payload, status: "Draft" };
    ScholarshipService_.submitIndividualScholarship(payload)
      .then((res) => {
        notification.open({
          message: "Saved draft",
          type: "success",
        });
        setDraftLoader(false);
      })
      .catch((err) => {
        notification.open({
          message: "Something went wrong",
          type: "error",
        });
        setDraftLoader(false);
      });
  };

  const onCloseModal = () => {
    setModalOpen(false);
    // history.goBack();
    history.push("/auth/dashboard");
  };

  if (loading) {
    return (
      <div
        style={{
          position: "absolute",
          left: mobileView ? window.innerWidth / 2 : "720px",
          top: "241px",
        }}
      >
        <Spin size={"large"} />
      </div>
    );
  }

  // const handleNext = (dataType) => {
  //   const key = dataType.field.dataType
  //   console.log("🚀 ~ handleNext ~ key:", key);

  //   if (currentRequirementIndex < data.requirements.length - 1) {
  //     setCurrentRequirementIndex(currentRequirementIndex + 1);
  //     if (key === 'editor' || key === 'text') {
  //       askNextQus()
  //     }
  //     setSaveAndNext(true)
  //   }
  // };
  // console.log(currentRequirementIndex === data.requirements.length - 1)

  const handleSave = () => {
    // Implement your save logic here
    // console.log("Saved:", data.requirements[currentRequirementIndex]);
    handleNext(); // Automatically go to the next requirement after saving
  };

  const handlePrevious = () => {
    if (currentRequirementIndex > 0) {
      setCurrentRequirementIndex(currentRequirementIndex - 1);
    }
  };
  const scholarshipReqStyle = {
    display: mView ? "flex" : "inline",
    flexDirection: "column",
  };

  const ScholarshipReq = () => {
    return (
      <>
        <ScholarshipInfo scholarship={data} />
        <div style={scholarshipReqStyle}>
          <Steps
            current={currentRequirementIndex}
            style={{
              width: "350px",
              position: mView ? "block" : "relative",
              top: "0px",
              // overflow: 'scroll hidden',
              float: mView ? "left" : "right",
            }}
            size="small"
            labelPlacement="vertical"
            responsive={false}
            className="custom-steps"
          >
            {data.requirements?.map((item, index) => (
              <Step
                key={index}
                style={{
                  color: "rgb(141, 150, 255)",
                  marginBottom: "0px",
                  padding: "0px",
                }}
              />
            ))}
          </Steps>
          <Form
            name="scholarship-details-form"
            onFinish={onSubmitForm}
            onFinishFailed={() => {
              notification.open({
                message: "Please fill all required fields",
                type: "error",
              });
            }}
            onValuesChange={onFormFieldsChange}
            form={form}
            initialValues={getInitialValues(existingData?.applicantFields)}
            style={{
              width: "100%",
            }}
          >
            {/* {mobileView && (<br />)} */}

            {data?.requirements && data?.requirements.length > 0 ? (
              <Space
                direction="vertical"
                size="large"
                style={{ display: "flex", marginBottom: "15px" }}
              >
                <p
                  style={{
                    marginBottom: "0px",
                    fontWeight: "600",
                    fontSize: "24px",
                    color: "black",
                  }}
                >
                  Requirements
                </p>
                {/* 
                {data.requirements.map((item) => (
                  <Requirement
                    key={item.id}
                    details={item}
                    formRef={form}
                    scholarshipId={data.id}
                  />
                ))} */}

                <Requirement
                  key={data.requirements[currentRequirementIndex]?.id}
                  details={data.requirements[currentRequirementIndex]}
                  formRef={form}
                  scholarshipId={data?.id}
                  data={data}
                />
                <div style={{ marginTop: "8px", display: "flex" }}>
                  <button
                    onClick={handlePrevious}
                    disabled={currentRequirementIndex === 0}
                    style={{
                      width: "max-content",
                      backgroundColor: "#E6E6E6",
                      border: "none",
                      marginRight: "8px",
                      cursor: "pointer",
                      display: currentRequirementIndex === 0 ? "none" : "block",
                    }}
                  >
                    <LeftOutlined />
                  </button>
                  <button
                    onClick={async () => {
                      try {
                        // Validate form fields
                        const formData = await form.validateFields();
                        console.log("🚀 ~ onClick={ ~ data:", formData);

                        if (
                          data &&
                          data.requirements &&
                          data.requirements?.length > currentRequirementIndex
                        ) {
                          // Proceed with the next step if validation is successful
                          handleNext(
                            data,
                            data.requirements[currentRequirementIndex].field
                              .dataType
                          );

                          // Check if this is the last requirement
                          if (
                            currentRequirementIndex ===
                            data.requirements.length - 1
                          ) {
                            setHideSaveAndNext(true);
                          }
                        } else {
                          // Handle the case where requirements are not defined or index is out of range
                          console.error(
                            "Invalid requirements data or index out of range."
                          );
                          // Optionally: Show an error message to the user
                        }
                      } catch (error) {
                        // Handle validation errors or other issues
                        console.error("Validation failed:", error);
                        // Optionally: Show an error message to the user
                      }
                    }}
                    style={{
                      marginRight: "30px",
                      width: mView ? "100%" : "max-content",
                      backgroundColor: saveAndNext
                        ? "#8087d7"
                        : "rgb(141, 150, 255)",

                      // float: "right",
                      borderColor: "transparent",
                      color: "white",
                      fontWeight: "500",
                      borderRadius: "5px",
                      borderColor: "rgb(141, 150, 255)",
                      height: "40px",
                      padding: "0 50px",
                      cursor: saveAndNext ? "not-allowed" : "pointer",
                      display: hideSaveAndNext ? "none" : "block",
                    }}
                    // disabled={currentRequirementIndex === data.requirements.length - 1}
                    disabled={
                      !data.requirements[currentRequirementIndex]?.optional
                        ? false
                        : saveAndNext
                    }
                    type="button"
                  >
                    Save and Next
                  </button>
                </div>
              </Space>
            ) : null}
            {hideSaveAndNext && existingData?.status !== "Received" && (
              <Flex
                flexDirection={window.innerWidth < 500 ? "column" : "row"}
                style={{
                  marginBottom: "10px",
                }}
              >
                {data?.requirements && data?.requirements.length > 0 ? (
                  <Button
                    htmlType="button"
                    name="submit-draft"
                    style={{
                      marginRight: "30px",
                      width: mView ? "100%" : "max-content",

                      // float: "right",
                      borderColor: "transparent",
                      color: "black",
                      fontWeight: "500",
                      borderRadius: "5px",
                      borderColor: "rgb(141, 150, 255)",
                      height: "40px",
                      padding: "0 50px",
                      marginBottom: "10px",
                    }}
                    onClick={onSubmitDraft}
                  >
                    Save draft{" "}
                    {draftLoader ? (
                      <LoadingOutlined style={{ marginRight: 10 }} />
                    ) : null}
                  </Button>
                ) : null}
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: mView ? "100%" : "max-content",
                    // float: "right",
                    borderRadius: "5px",
                    backgroundColor: "rgb(141, 150, 255)",
                    border: "none",
                    height: "40px",
                    padding: "0 50px",
                    marginTop: window.innerWidth < 500 && "8px",
                    marginBottom: "10px",
                  }}
                >
                  Submit application{" "}
                  {submitLoader ? (
                    <LoadingOutlined style={{ marginRight: 10 }} />
                  ) : null}
                </Button>
              </Flex>
            )}

            {/* <Space
              direction="vertical"
              style={{
                marginBottom: !mView && "80px",
              }}
            >
              <p
                style={{
                  marginBottom: "0px",
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "black",
                }}
              >
                Terms and Conditions
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: mView ? "column" : "row",
                  justifyContent: "flex-start",
                  marginBottom: "10px",
                  width: "100%",
                }}
              >
                <HtmlPopup
                  buttonLabel="Terms of use"
                  htmlContent={data?.terms}
                />
                <HtmlPopup
                  buttonLabel="Privacy Policy"
                  htmlContent={data?.privacy}
                />
                <HtmlPopup
                  buttonLabel="Affidavit"
                  htmlContent={data?.affidavit}
                />
              </div>
            </Space> */}

            <Form.Item
              // className=" fixed-bottom"
              style={{
                display: "none",
                padding: "15px 20px",
                width: mobileView ? "" : "max-content",
                background: "#fff",
                margin: 0,
                borderTop: "1px solid rgba(29, 37, 45, 0.1)",
                position: mView ? "static" : "fixed",
                bottom: 0,
                zIndex: 1000,
                right: mobileView ? 0 : "auto",
                left: mobileView ? 0 : "auto",
              }}
            >
              {existingData?.status !== "Received" && (
                <Flex
                  flexDirection={window.innerWidth < 500 ? "column" : "row"}
                  style={{
                    marginBottom: "10px",
                  }}
                >
                  {data?.requirements && data?.requirements.length > 0 ? (
                    <Button
                      htmlType="button"
                      name="submit-draft"
                      style={{
                        marginRight: "30px",
                        width: mView ? "100%" : "max-content",

                        // float: "right",
                        borderColor: "transparent",
                        color: "black",
                        fontWeight: "500",
                        borderRadius: "5px",
                        borderColor: "rgb(141, 150, 255)",
                        height: "40px",
                        padding: "0 50px",
                        marginBottom: "10px",
                      }}
                      onClick={onSubmitDraft}
                    >
                      Save draft{" "}
                      {draftLoader ? (
                        <LoadingOutlined style={{ marginRight: 10 }} />
                      ) : null}
                    </Button>
                  ) : null}
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      width: mView ? "100%" : "max-content",
                      // float: "right",
                      borderRadius: "5px",
                      backgroundColor: "rgb(141, 150, 255)",
                      border: "none",
                      height: "40px",
                      padding: "0 50px",
                      marginTop: window.innerWidth < 500 && "8px",
                      marginBottom: "10px",
                    }}
                  >
                    Submit application{" "}
                    {submitLoader ? (
                      <LoadingOutlined style={{ marginRight: 10 }} />
                    ) : null}
                  </Button>
                </Flex>
              )}
            </Form.Item>
          </Form>

          <ThankYouModal open={modalOpen} handleOnCloseModal={onCloseModal} />
          {firstScholarshipPop && (
            <>
              <div className="popup-overlay">
                <div
                  className="popup-content"
                  style={{
                    // minWidth: "60%",
                    maxWidth: window.innerWidth < 800 ? "90%" : "60%",
                    minHeight: tabView ? "auto" : "70%",
                    padding: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <div
                    onClick={() => {
                      setFirstScholarshipPop(false);
                      // setScholarshipPop(false);
                    }}
                    style={{
                      color: "rgb(113, 122, 245)",
                      fontSize: "24px",
                      cursor: "pointer",
                      float: "right",
                      lineHeight: "normal",
                      marginRight: "10px",
                      marginTop: "10px",
                      // display: "none",
                    }}
                  >
                    {" "}
                    <CloseCircleFilled />
                  </div>
                  <FirstThankyouPopUp
                    setFirstScholarshipPop={setFirstScholarshipPop}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <Row style={{ minHeight: "calc(100vh - 90px)", overflow: "hidden" }}>
        <Col
          style={{
            // width: mobileView ? "100%" : "70%",
            padding: mobileView ? "0 16px" : "0 24px",
            background: "#ffffff",
            position: "relative",
            boxSizing: "border-box",
          }}
          // span={18}
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={18}
        >
          <a
            onClick={() => {
              props.setSideBars(false);
              history.goBack();
            }}
            style={{ color: "#1D252D", fontSize: "20px", fontWeight: "500" }}
          >
            <img
              src="/applicantImg/back.png"
              style={{ height: "18px", marginRight: "4px" }}
            />
            Back to Scholarships
          </a>
          {/* {mobileView && (
            <ApplicationStatus
              requirements={data?.requirements}
              completedSteps={completedSteps}
            />
          )} */}
          {mobileView ? (
            <>
              <ScholarshipReq />
            </>
          ) : (
            <Scrollbars
              style={{
                height: "calc(100vh - 100px)",
                padding: "0 20px",
                overflowY: "hidden",
              }}
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              // autoHeight
              // autoHeightMax="calc(100vh - 110px)"
            >
              <ScholarshipReq />
            </Scrollbars>
          )}
        </Col>
        {!mobileView && (
          <Col
            style={{
              // width: "25%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              height: "calc(100vh - 110px)",
              overflow: "hidden",
              position: reqAssMaxView ? "absolute" : "static",
              width: reqAssMaxView ? "-webkit-fill-available" : "25%",
              margin: reqAssMaxView ? "auto" : "0",

              bottom: 0,
              zIndex: 1000,
            }}
            // offset={3}
            span={reqAssMaxView ? 24 : 6}
          >
            <div
              style={{
                flex: "1 1 auto",
                overflowY: "auto",
                marginBottom: "10px",
                display: reqAssMaxView ? "none" : "block",
              }}
            >
              <ApplicationStatus
                requirements={data?.requirements}
                completedSteps={completedSteps}
                applicationstatus={existingData?.status}
              />
            </div>
            <div
              style={{
                flex: "0 0 auto",
              }}
            >
              {/* {true && essayReq.length > 0 && ( */}
              {showChat && essayReq.length > 0 && (
                <RequirementAss
                  essayReq={essayReq}
                  assMessages={assMessages}
                  setAssMessages={setAssMessages}
                />
              )}
              {/* {startChat ? <AssistanceChatView /> : <AssitanceChatMiniView />} */}
            </div>
          </Col>
        )}
      </Row>
      <div
        style={{
          position: "sticky",
          bottom: "145px",
          cursor: "pointer",
          right: "20px",
          float: "right",
          display: tabView && essayReq.length > 0 ? "block" : "none",
        }}
        onClick={() => {
          // history.push("/auth/dashboard/assistanceChat");
          // setIsModalVisible(true)
          setShowChat(true);
        }}
      >
        <img
          src="/applicantImg/robo2.png"
          style={{
            width: "75px",
          }}
          alt=""
        />
      </div>
      {tabView && showChat && essayReq.length > 0 && (
        <div
          style={{
            position: mobReqAssMaxView ? "absolute" : "static",
            width: mobReqAssMaxView ? "97%" : "25%",
            margin: mobReqAssMaxView ? "auto" : "0",
          }}
        >
          <RequirementAss
            isMobileView={true}
            essayReq={essayReq}
            assMessages={assMessages}
            setAssMessages={setAssMessages}
          />
        </div>
      )}
    </>
  );
};

export default ScholarshipDetail;
