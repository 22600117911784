function getCookieInfo(cookieName) {
  const cookies = document.cookie.split(";");
  for (let cookie of cookies) {
    cookie = cookie.trim();
    if (cookie.startsWith(cookieName + "=")) {
      const value = cookie.substring(cookieName.length + 1);
      return {
        name: cookieName,
        value: value,
        // Domain and Path cannot be directly retrieved
        domain: window.location.hostname,
        path: "/",
        // Expiry is not available via JS
        expires: null,
        // Secure and HttpOnly flags are not available via JS
        secure: document.location.protocol === "https:",
        httpOnly: false,
      };
    }
  }
  return null;
}

function deleteCookie(cookieName) {
  const cookieInfo = getCookieInfo(cookieName);
  if (cookieInfo) {
    let cookieString = `${cookieInfo.name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=${cookieInfo.path};`;

    if (cookieInfo.domain) {
      cookieString += ` domain=${cookieInfo.domain};`;
    }

    if (cookieInfo.secure) {
      cookieString += " secure;";
    }

    document.cookie = cookieString;
  }
}

// Example usage:
export default deleteCookie;
