import { Row, Col, Button, Spin } from "antd";
import { useHistory } from "react-router-dom";
import useAxios from "axios-hooks";
import React, { useState, useCallback } from "react";
import { SquareView } from "./SquareView.js";
import useAnalyticsEventTracker from "../../../useAnalyticsEventTracker";

const Assistance = ({ props }) => {
  const mobileView = window.innerWidth < 1200;
  const gaEventTracker = useAnalyticsEventTracker("Assistance");
  const [{ data: arr, loading }] = useAxios({ url: `scholarships/assistance` });
  // console.log(arr);

  const history = useHistory();

  if (loading)
    return (
      <div
        style={{
          position: "absolute",
          left: mobileView ? window.innerWidth / 2 : "520px",
          top: "241px",
        }}
      >
        <Spin size={"large"} />
      </div>
    );

  return (
    <div
      style={{
        background: "#ffffff",
        padding: mobileView ? "20px" : "20px",
        width: mobileView && window.innerWidth,
      }}
    >
      <h2>
        Lenny's Assistance
        <img
          src="/applicantImg/eyess.png"
          style={{ width: "35px", marginLeft: "5px" }}
        />
      </h2>
      <Row>
        {arr?.map((e) => (
          <SquareView data={e} history={history} />
        ))}
      </Row>
    </div>
  );
};

export default Assistance;
