import React from "react";
import "./style.css";
import Footer from "../footer.js";
import Subscription from "components/layout-components/Subscription";
import SubscriptionMobileDesign from "components/layout-components/SubscriptionMobileDesign";

const index = ({ history }) => {
  const userInfoString = localStorage.getItem("userInfo");
  let userInfo = {};
  if (userInfoString) {
    try {
      userInfo = JSON.parse(userInfoString);
    } catch (err) {
      console.error("Error parsing userInfo:", err);
    }
  }

  const tabView = window.innerWidth < 961;
  return (
    <div className="pricing_mainContainer">
      <div
        className="pricing_container"
        style={{
          background:
            "linear-gradient(180deg, #DFE2FF 0%, #FFF 60.3%), url(/img/pricing/bg.png)",
        }}
      >
        <div
          className="pricing_box headd"
          style={{
            display: "flex",
            flexDirection: "column",
            // justifyContent: 'flex-end',
            marginTop: "65px",
            paddingLeft: "100px",
          }}
        >
          <h2>All the best things in life are free</h2>
          <p>
            The basic features in our Scholarship Campaign Manager are free.
            You’ll only pay when you sponsor a Scholarship Campaign. From
            creating your own scholarship to managing it. From selecting the
            applicants and notifying them about their award. What’s more, even
            the scholarship page builder is free.
          </p>
        </div>
        <div className="pricing_box">
          <img
            src="/img/pricing/robo.png"
            alt="Your Image"
            style={{
              float: "right",
              width: "550px",
            }}
            className="robopic"
          />
        </div>
      </div>
      <div
        style={{
          paddingLeft: "95px",
          marginTop: "35px",
          width: "90%",
          // marginRight: '200px'
        }}
        className="cont"
      >
        <div className="pricing_box">
          <h2
            style={{
              fontSize: "24px",
              lineHeight: "26.4px",
            }}
          >
            Distribution through Lenny.AI
          </h2>
          <p
            style={{
              lineHeight: "26.4px",
            }}
          >
            Meet over 8 million students looking for scholarships. Scholarship
            Campaign Manager is helping you do that by showing your scholarship
            only to the relevant applicants. (e.g. if your scholarship is open
            to the New York area Lenny.AI will show your program only to the
            students that are in New York. This algorithm is helping you get
            more quality from the applications)
          </p>
        </div>
        <div className="pricing_box">
          <h2
            style={{
              fontSize: "24px",
              lineHeight: "26.4px",
            }}
          >
            Hundreds of integrations (Zapier, API, directly embeddable into your
            website)
          </h2>
          <p
            style={{
              lineHeight: "26.4px",
            }}
          >
            You can embed the application form directly into your website to
            streamline your award process or connect your scholarship
            application with more than 1,000 apps through Zapier. Add your new
            applications to your CRM, or Tweet about them, or even send them a
            personalized email. You have endless possibilities to interact and
            communicate with the students that apply for your scholarship.
          </p>
        </div>
        <div className="pricing_box">
          <h2
            style={{
              fontSize: "24px",
              lineHeight: "26.4px",
            }}
          >
            Application scoring
          </h2>
          <p
            style={{
              lineHeight: "26.4px",
            }}
          >
            Score all your applications that come in based on the criteria that
            you define.
          </p>
        </div>
        <div className="pricing_box">
          <h2
            style={{
              fontSize: "24px",
              lineHeight: "26.4px",
            }}
          >
            Unlimited users
          </h2>
          <p
            style={{
              lineHeight: "26.4px",
            }}
          >
            Score all your applications that come in based on the criteria that
            you define.
          </p>
        </div>
        <div className="pricing_box">
          <h2
            style={{
              fontSize: "24px",
              lineHeight: "26.4px",
            }}
          >
            Sounds too good to be true?
          </h2>
          <p
            style={{
              lineHeight: "26.4px",
            }}
          >
            We’re giving you access to Scholarship Campaign Manager for free,
            because we’re counting on you to increase the number of scholarships
            available to students. We’re committed to provide you with the tools
            and capabilities that help you achieve your business goals while
            helping Gen Z achieve their goals.
          </p>
        </div>
        <p
          style={{
            color: "black",
            fontFamily: "Poppins",
            fontSize: "18px",
            lineHeight: "37.5px",
          }}
        >
          “Scholarship Campaign Manager is a well-developed tool to launch your
          scholarships with a great user interface. The provided information and
          support is optimal and the operations performed by the tool are more
          than expected”
        </p>

        <h2
          style={{
            fontSize: "24px",
            lineHeight: "26.4px",
          }}
        >
          You still have some questions?
        </h2>
        <p
          style={{
            color: "#2D2D2E",
            fontFamily: "Poppins",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "27px",
          }}
        >
          We've got a pen and paper. Let's jump on a quick call and we'll answer
          any and all of your questions.
        </p>
      </div>
      <div
        style={{
          backgroundColor: "rgb(240, 242, 253)",
          // marginBottom: '-40px'
          padding: "5px",
        }}
      >
        {/* <Subscription redirect={false} modalStyle={{
                    border: 'none',
                    borderRadius: '0',
                    boxShadow: 'none'
                }} /> */}

        {tabView ? (
          <div
            // className="popup-overlay"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              margin: "40px 0",
            }}
          >
            <p
              style={{
                color: "black",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "24px",
                padding: "0 20px",
              }}
            >
              {`${userInfo?.firstName ?? "Manav"}`} get a membership for free
              for 7 days to activate automatic application to your 234
              scholarship matches. Let us do the hard work for you!
            </p>

            <SubscriptionMobileDesign
              // toggleMobilePopup={togglePopup}
              redirect={false}
              noThanksDisplay={true}
            />
          </div>
        ) : (
          <Subscription
            redirect={false}
            modalStyle={{
              border: "none",
              borderRadius: "0",
              boxShadow: "none",
            }}
          />
        )}
      </div>
      <Footer />
    </div>
  );
};

export default index;
