import React from "react";
import ReactGA from "react-ga4";

// const useAnalyticsEventTracker = (category = "Blog category") => {
//   const eventTracker = (action = "test action", label = "test label") => {
//     ReactGA.event({ category, action, label });
//   };
//   return eventTracker;
// };

const useAnalyticsEventTracker = (category = "Blog category") => {
  const eventTracker = (
    action = "test action",
    label = "test label",
    customParams = {}
  ) => {
    // Including customParams to track additional parameters
    ReactGA.event({
      category,
      action,
      label,
      ...customParams, // Spread custom parameters here
    });
  };
  return eventTracker;
};

export default useAnalyticsEventTracker;
