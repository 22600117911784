import React, { useRef } from "react";
import Navbar from "./Navbar";
import Scrollbars from "react-custom-scrollbars-2";
import ChatBot from "components/layout-components/ChatBot";
import SignupSlider from "components/layout-components/SignupSlider";

const Index = () => {
  const mobileView = window.innerWidth < 915;
  const chatnav1Ref = useRef(null);

  const rightMargin = "25px";
  const bigSc = window.innerWidth < 1220;

  return (
    <div>
      <Navbar />
      <div className="chatNavContainer">
        <div
          className="chatNav1"
          style={{
            display: "flex",
            alignItems: bigSc ? "center" : "flex-start",
            justifyContent: bigSc ? "center" : "space-between",
            flexDirection: "column",
            padding: mobileView ? "0 10px" : "0",
            height: "calc(100vh - 81px)",
            backgroundImage: 'url("/new/back.png")',
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          ref={chatnav1Ref}
        >
          <div>
            <h2
              style={{
                fontSize: mobileView ? "20px" : "24px",
                marginTop: "25px",
                fontWeight: "500",
                lineHeight: "normal",
                color: "black",
                marginLeft: rightMargin,
                // paddingTop: "65px"
              }}
            >
              Looks great!!!
            </h2>

            <p
              style={{
                color: "black",
                // color: "#373636",
                fontSize: mobileView ? "14px" : "16px",
                // textAlign: "center",
                fontWeight: "500",
                marginLeft: rightMargin,
                width: "90%",
                paddingBottom: "5px",
              }}
            >
              We would need more detail in order to give you a perfects
              scholarship!
            </p>

            <p
              style={{
                backgroundColor: "#E0ECFF",
                color: "#1F32D9",
                padding: "8px",
                borderRadius: "5px",
                fontWeight: "500",
                fontSize: "22px",
                // marginLeft: rightMargin,
                textAlign: "center",
                margin: "2px auto",
                width: "92%",
              }}
            >
              Why register for one if you can apply to many
            </p>
          </div>

          <div
            style={{
              position: "absolute",
              // top: "60px"
              bottom: "140px",
              display: bigSc ? "none" : "block",
            }}
          >
            <SignupSlider />
          </div>

          <div
            style={{
              width: "100%",
              // display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-start",
              display: mobileView ? "none" : "flex",
              zIndex: "10",
            }}
          >
            <img
              src="/new/signupRobo.png"
              alt=""
              style={{
                width: bigSc ? "50%" : "25%",
                // height: "485px",
                // margin: '130px 0 0 0'
              }}
            />
          </div>
        </div>
        <div
          style={{
            flexGrow: "1",
            flexShrink: "1",
            // flexBasis: '50%'
            flexBasis: mobileView ? "100%" : "50%",
            // height: 'calc(100vh - 61px)'
          }}
        >
          <ChatBot chatnav1Ref={chatnav1Ref} />
        </div>
      </div>
    </div>
  );
};

export default Index;
