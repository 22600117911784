import React, { useEffect, useState, useCallback } from "react";
import { CalendarOutlined, CloseCircleFilled } from "@ant-design/icons";
import Utils from "utils/common";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import fetch_ from "interceptors/FetchInterceptor";
import { HeartOutlined, HeartFilled } from "@ant-design/icons";
import { Col, message } from "antd";
import ChatUserDetails from "./ChatUserDetails.js";
import Subscription from "components/layout-components/Subscription";
import SubscriptionMobileDesign from "components/layout-components/SubscriptionMobileDesign";
import "./css/Popus.css";
import { useAssitanceChatBotContext } from "context/ChatbotContext.js";
import moment from "moment";
// import { FREE_SCHOLARSHIP_COUNT } from "constants/common.constant.js";

const ChatStructure = ({
  type,
  scholarshipData,
  dashbordHide = false,
  setChatBtn,
  applyScholarship,
  chatThreeDotLoading,
  setProfileUpdate,
}) => {
  const {
    maximizeView,
    setFilledData,
    setAssMessages,
    setShowChat,
    setForceStopREnder,
    freeScholarshipCount,
  } = useAssitanceChatBotContext();
  console.log("🚀 ~ maximizeView:", maximizeView);
  const history = useHistory();
  const mobileView = window.innerWidth < 915;
  const phoneView = window.innerWidth < 962;
  const storedValue = localStorage.getItem("isActiveSubscription");
  const isActiveSubscription = storedValue === "true";
  const [isOpen, setIsOpen] = useState(false);

  const userInfoString = localStorage.getItem("userInfo");
  let userInfo = {};
  if (userInfoString) {
    try {
      userInfo = JSON.parse(userInfoString);
    } catch (err) {
      console.error("Error parsing userInfo:", err);
    }
  }

  const togglePopup = () => {
    if (isActiveSubscription) {
      history.push("/auth/dashboard/plan");
    } else {
      // setIsOpen(!isOpen);
      // console.log("a");
      if (phoneView) {
        setIsOpen(!isOpen);
        // console.log(isOpen, phoneView);
        // console.log("b");
      } else {
        history.push("/auth/dashboard/subscription-plan");
        // console.log("c");
      }
    }
  };
  const [dashboardView, setDashboardView] = useState(dashbordHide);
  useEffect(() => {
    if (history.location.pathname?.includes("assistanceChat")) {
      setDashboardView(false);
    }
  }, [history.location.pathname]);

  const viewDetail = (data) => {
    history.push({
      pathname: "/auth/scholarshipDetail",
      search: data.scholarshipId || data.id || data.dataValues.id,
      // params: props.type,
    });
    // props.setSideBars(true);
  };

  const amountView = (data) => {
    // console.log(data);
    const totalAmount =
      (data?.amounts && data.amounts.reduce((acc, curr) => acc + curr, 0)) ||
      (data?.scholarship?.amounts &&
        data?.scholarship?.amounts.reduce((acc, curr) => acc + curr, 0)) ||
      (data?.dataValues?.amounts &&
        data.dataValues.amounts.reduce((acc, curr) => acc + curr, 0)) ||
      data?.scholarship?.awards ||
      data?.awards ||
      data?.dataValues?.awards;
    return (
      <Col style={{ width: "11%" }}>
        <h2 style={{ margin: "0" }}>{Utils.formatAsUSD(totalAmount)}</h2>
      </Col>
    );
  };

  function extractTextFromHTML(html) {
    // Create a temporary element
    const tempElement = document.createElement("div");
    // Set the HTML content of the temporary element
    tempElement.innerHTML = html;
    // Return the text content of the temporary element
    return tempElement.textContent || tempElement.innerText || "";
  }

  const TableStruct = ({ data }) => {
    // console.log("🚀 ~ TableStruct ~ data:", data);
    const [tempData, setTempData] = useState({ ...data });

    const likeClick = () => {
      if (tempData.wishlist.length > 0) {
        setTempData({ ...data, wishlist: [] });
        let scholarshipId = data.id;
        fetch_({
          url: `scholarships/remove-wishlist`,
          method: "PUT",
          data: { scholarshipId: scholarshipId },
        })
          .then(({ data }) => {})
          .catch((error) => {});
      } else {
        let scholarshipId = data.id;
        setTempData({ ...data, wishlist: [{ scholarshipId: scholarshipId }] });
        fetch_({
          url: `scholarships/add-wishlist`,
          method: "PUT",
          data: { scholarshipId: scholarshipId },
        })
          .then(({ data }) => {})
          .catch((error) => {});
      }
      // window.location.reload();
    };

    const inputDate = moment(data?.recurrenceConfig?.deadline);
    const formattedDate = inputDate.format("DD MMMM YYYY");

    const mView = () => {
      return (
        <div
          style={{
            display: "flex",
            backgroundColor: "white",
            padding: "16px",
            gap: "20px",
            alignItems: "center",
            width: "max-content",
            marginBottom: "10px",
            width: "98%",
            flexWrap: maximizeView
              ? "nowrap"
              : dashboardView
              ? "wrap"
              : mobileView
              ? "wrap"
              : "nowrap",
            borderRadius: "8px",
            justifyContent: "space-between",
          }}
        >
          <div
            className="b1"
            style={{
              width: dashboardView ? "100%" : mobileView ? "100%" : "70%",
            }}
          >
            <div>
              <p
                style={{
                  color: "black",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                {data?.title}{" "}
              </p>
            </div>
            <div>
              <p
                style={{
                  color: "black",
                  fontWeight: "400",
                  fontSize: "14px",
                }}
              >
                {" "}
                {extractTextFromHTML(data?.description)?.slice(0, 150)}...{" "}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: dashboardView ? "row" : "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>{amountView(data)}</div>
              <div>
                <CalendarOutlined /> Deadiline : {formattedDate}
              </div>
            </div>
          </div>
          {/* <div
        className="b2"
        style={{
          width: dashboardView ? "max-content" : "15%",
        }}
      >
        {amountView(data)}
      </div> */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%", // Ensure the parent container takes the full available width
            }}
          >
            <div
              style={{
                padding: "4px 7px 5px 7px",
                flexShrink: 0, // Prevent the heart icon from shrinking
              }}
              onClick={likeClick}
            >
              {tempData?.wishlist?.length > 0 ? (
                <HeartFilled style={{ color: "#8D96FF" }} />
              ) : (
                <HeartOutlined style={{}} />
              )}
            </div>
            <div
              className="b3"
              style={{
                flexGrow: 1, // Allow this div to grow and take up the remaining space
                marginLeft: "10px", // Optional: Add some space between the heart icon and the button
              }}
            >
              <p
                style={{
                  padding: "8px",
                  backgroundColor: "#8D96FF",
                  color: "white",
                  borderRadius: "8px",
                  textAlign: "center",
                  fontWeight: "500",
                  cursor: "pointer",
                  fontSize: "12px",
                  width: "100%", // Make sure the button takes the full width of its container
                  boxSizing: "border-box", // Include padding and border in the element's total width and height
                  margin: "0", // Remove default margins to ensure full width usage
                }}
                onClick={() => {
                  // if (isActiveSubscription === true) {
                  //   viewDetail(data);
                  // } else {
                  //   togglePopup();
                  // }
                  if (isActiveSubscription === true) {
                    viewDetail(data);
                    setFilledData(() => {
                      return {};
                    });
                    setShowChat(false);
                    setForceStopREnder(true);
                    setAssMessages(() => {
                      return [];
                    });
                  } else {
                    if (userInfo?.scholarshipCount < freeScholarshipCount) {
                      viewDetail(data);
                      setFilledData(() => {
                        return {};
                      });
                      setShowChat(false);
                      setForceStopREnder(true);
                      setAssMessages(() => {
                        return [];
                      });
                    } else {
                      message.error(
                        `You have exceeded the limit of free scholarship views. Please subscribe to continue accessing more scholarships.`
                      );
                      togglePopup();
                    }
                  }
                  localStorage.removeItem("reqData");
                }}
              >
                View Details
              </p>
            </div>
          </div>

          {isOpen &&
            (phoneView ? (
              <div
                className="popup-overlay"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <p
                  style={{
                    color: "#FFF",
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "24px",
                    padding: "0px 20px",
                  }}
                >
                  {`${userInfo?.firstName}`} get a membership for free 7 days to
                  activate automatic application to your 234 scholarship
                  matches. Lets us do the hard work for you !
                </p>

                <SubscriptionMobileDesign
                  toggleMobilePopup={togglePopup}
                  redirect={true}
                />
              </div>
            ) : (
              <div className="popup-overlay">
                <div className="popup-content">
                  <div
                    onClick={togglePopup}
                    style={{
                      color: "rgb(113, 122, 245)",
                      fontSize: "24px",
                      cursor: "pointer",
                      float: "right",
                      lineHeight: "normal",
                    }}
                  >
                    {" "}
                    <CloseCircleFilled />
                  </div>
                  <Subscription
                    modalStyle={{
                      border: "none",
                      boxShadow: "none",
                      margin: "0px",
                    }}
                    redirect={true}
                  />
                </div>
              </div>
            ))}
        </div>
      );
    };

    const webView = () => {
      return (
        <div
          style={{
            display: "flex",
            backgroundColor: "white",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "10px",
            borderRadius: "8px",
            padding: "0 8px",
            maxWidth: "80%",
          }}
        >
          <div
            style={{
              display: "flex",
              backgroundColor: "white",
              padding: "16px",
              gap: "20px",
              alignItems: "center",
              width: "max-content",
              marginBottom: "10px",
              width: "98%",
              flexWrap: maximizeView
                ? "nowrap"
                : dashboardView
                ? "wrap"
                : mobileView
                ? "wrap"
                : "nowrap",
              borderRadius: "8px",
              justifyContent: "space-between",
            }}
          >
            <div
              className="b1"
              style={{
                width: dashboardView ? "100%" : mobileView ? "100%" : "70%",
              }}
            >
              <div>
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    fontSize: "16px",
                  }}
                >
                  {data?.title}{" "}
                </p>
              </div>
              <div>
                <p
                  style={{
                    marginLeft: dashboardView ? "0" : "20px",
                    color: "black",
                    fontWeight: "400",
                  }}
                >
                  {/* <img
                    src="/applicantImg/start2.png"
                    style={{
                      width: "16px",
                    }}
                  /> */}
                  {extractTextFromHTML(data?.description)?.slice(0, 150)}...{" "}
                </p>
              </div>
            </div>
          </div>
          <div
            style={{
              color: "black",
              fontSize: "14px",
              fontWeight: "500",
              marginRight: "10px",
            }}
          >
            Sponsored by
            {" " + data?.user?.firstName}
          </div>
          <div
            className="b2"
            style={{
              width: dashboardView ? "max-content" : "15%",
            }}
          >
            {amountView(data)}
          </div>
          <div
            style={{
              padding: "4px 7px 5px 7px",
            }}
            onClick={likeClick}
          >
            {tempData?.wishlist?.length > 0 ? (
              <HeartFilled style={{ color: "#8D96FF" }} />
            ) : (
              <HeartOutlined style={{}} />
            )}
          </div>
          <div
            className="b3"
            style={{
              width: dashboardView ? "50%" : mobileView ? "auto" : "15%",
            }}
          >
            <p
              style={{
                padding: "8px",
                backgroundColor: "#8D96FF",
                color: "black",
                borderRadius: "32px",
                textAlign: "center",
                fontWeight: "500",
                cursor: "pointer",
                // width: "max-content",
                color: "white",
                fontSize: "12px",
              }}
              onClick={() => {
                localStorage.removeItem("reqData");

                if (isActiveSubscription === true) {
                  viewDetail(data);
                  setFilledData(() => {
                    return {};
                  });
                  setShowChat(false);
                  setForceStopREnder(true);
                  setAssMessages(() => {
                    return [];
                  });
                } else {
                  if (userInfo?.scholarshipCount < freeScholarshipCount) {
                    viewDetail(data);
                    setFilledData(() => {
                      return {};
                    });
                    setShowChat(false);
                    setForceStopREnder(true);
                    setAssMessages(() => {
                      return [];
                    });
                  } else {
                    message.error(
                      `You have exceeded the limit of free scholarship views. Please subscribe to continue accessing more scholarships.`
                    );
                    togglePopup();
                  }
                }
              }}
            >
              View Details
            </p>
          </div>
          {isOpen &&
            (phoneView ? (
              <div
                className="popup-overlay"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <p
                  style={{
                    color: "#FFF",
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "24px",
                    padding: "0px 20px",
                  }}
                >
                  {`${userInfo?.firstName}`} get a membership for free 7 days to
                  activate automatic application to your 234 scholarship
                  matches. Lets us do the hard work for you !
                </p>

                <SubscriptionMobileDesign
                  toggleMobilePopup={togglePopup}
                  redirect={true}
                />
              </div>
            ) : (
              <div className="popup-overlay">
                <div className="popup-content">
                  <div
                    onClick={togglePopup}
                    style={{
                      color: "rgb(113, 122, 245)",
                      fontSize: "24px",
                      cursor: "pointer",
                      float: "right",
                      lineHeight: "normal",
                    }}
                  >
                    {" "}
                    <CloseCircleFilled />
                  </div>
                  <Subscription
                    modalStyle={{
                      border: "none",
                      boxShadow: "none",
                      margin: "0px",
                    }}
                    redirect={true}
                  />
                </div>
              </div>
            ))}
        </div>
      );
    };
    return !maximizeView ? <>{mView()}</> : <>{webView()}</>;
  };

  if (type == "scholarshipList") {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {scholarshipData.length > 0 &&
          scholarshipData.map((data) => {
            // console.log("🚀 ~ scholarshipData.length>0&&scholarshipData.map ~ data:", data);
            return <TableStruct data={data} />;
          })}
      </div>
    );
  }

  if (type == "userDetails") {
    return (
      <>
        <ChatUserDetails
          setChatBtn={setChatBtn}
          setProfileUpdate={setProfileUpdate}
          applyScholarship={applyScholarship}
        />
      </>
    );
  }

  return (
    <div>
      <h1>table data</h1>
    </div>
  );
};

export default ChatStructure;
