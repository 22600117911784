import useAxios from "axios-hooks";
import React from "react";
import { useParams } from "react-router-dom";
import { NAV_PATH } from "configs/NavigationConfig";
import { Row, Col, Form, Button, Select, Steps, Spin } from "antd";

const ComingSoon = ({ history, props }) => {
  return <div style={{}}>Coming Soon...</div>;
};

export default ComingSoon;
