import {
  Button,
  Col,
  Drawer,
  message,
  Modal,
  Row,
  Space,
  Upload,
  Typography,
} from "antd";
import { API_UPLOAD_URL } from "configs/AppConfig";
import React, { useEffect, useState } from "react";
import UploadEssay from "../UploadEssay";
import useAnalyticsEventTracker from "useAnalyticsEventTracker";
import { UploadOutlined } from "@ant-design/icons";
import mixpanel from "mixpanel-browser";
import { useAssitanceChatBotContext } from "context/ChatbotContext";
import { filter, set } from "lodash";
import { jsPDF } from "jspdf";
import axios from "axios";

const TextEditor = ({
  onChange,
  value,
  error,
  details,
  scholarshipId,
  formRef,
  wordLength,
  fileSize,
  fileTypes,
  limitStr,
  ...moreProps
}) => {
  const {
    wordLengthData,
    setWordLengthData,
    localData,
    filledData,
    setFilledData,
    setShowChat,
    setChatBtnType,
    reqAssQusArr,
    setReqQusArr,
    setLocalData,
    askNextQus,
    setSaveAndNext,
  } = useAssitanceChatBotContext();
  const [open, setOpen] = useState(false);
  const gaEventTracker = useAnalyticsEventTracker("Scholarship Detail");
  const { field, title, description, optional } = details;

  const fieldName = field?.name === "Essay File" ? field?.name : field?.key;

  const existData = filledData ? filledData[fieldName] : "";
  // console.log("🚀 ~ existData:", existData, filledData, field?.key)

  const completionStatus =
    field.key === "essay"
      ? value?.text
        ? "Edit Essay"
        : "Effortless Essay Completion"
      : value?.text
      ? "Edit Answer"
      : "Effortless Question Completion";
  const uploadStatus =
    field.key === "essay"
      ? value?.url
        ? "Edit uploaded Essay"
        : "Upload an Essay"
      : value?.url
      ? "Edit uploaded Answer"
      : "Upload Answer";

  const onFileUploadClick = (e) => {
    let label = {
      screenName: "Scholarship Detail",
      scholarshipId,
    };
    gaEventTracker("Upload file clicked", JSON.stringify(label));
    mixpanel.track("Upload file clicked");

    const { file } = e;

    if (file.status === "done") {
      const getReqData = JSON.parse(localStorage.getItem("reqData"));

      // setSaveAndNext(false);

      message.success(`${file.name} file uploaded successfully`);
      // let reqData = filledData || {};

      // delete reqData[field?.field?.key];
    }
    onChange(e);
  };

  const showDrawer = () => {
    let label = {
      screenName: "Scholarship Detail",
      scholarshipId,
    };
    gaEventTracker("Write Lenny Easy clicked", JSON.stringify(label));
    mixpanel.track("Write Lenny Easy clicked");

    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const uploadProps = !!value?.text
    ? {
        itemRender: () => null,
      }
    : {};

  useEffect(() => {}, [localData]);
  const createAndUploadPDF = async (textFromChatGPT) => {
    try {
      // Step 1: Create PDF
      const pdf = new jsPDF();
      pdf.text(textFromChatGPT, 10, 10);

      // Step 2: Convert PDF to Blob
      const pdfBlob = pdf.output("blob");

      // Check: Ensure Blob is not empty
      if (pdfBlob.size > 0) {
        console.log("PDF created successfully!");

        // Step 3: Upload the PDF Blob
        const formData = new FormData();
        formData.append("file", pdfBlob, "generated.pdf");

        const response = await axios.post(API_UPLOAD_URL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        // console.log("🚀 ~ createAndUploadPDF ~ response:", response)

        const fileUrl = response?.data?.data; // Adjust based on your API's response
        // console.log('File uploaded successfully. URL:', fileUrl);

        return fileUrl;
      } else {
        console.error("Failed to create PDF.");
      }
    } catch (error) {
      console.error("Error during PDF creation or upload:", error);
    }
  };

  useEffect(async () => {
    if (existData) {
      const publicUrl = await createAndUploadPDF(existData);
      if (publicUrl) {
        onChange({ url: publicUrl });
      } else {
        onChange({ essay: existData });
      }
    }
  }, []);

  return (
    <div>
      <div>
        <div
          style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
        >
          <div
            style={{
              flex: "1 1 -1%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Upload
              action={API_UPLOAD_URL}
              accept={fileTypes || ".pdf"}
              maxCount={1}
              beforeUpload={(file) => {
                const isLt2M = file.size / 1024 / 1024 < (fileSize || 1); // 2MB file size limit
                if (!isLt2M) {
                  message.error(
                    `File must be smaller than ${fileSize || 1} MB!`
                  );
                }
                return isLt2M; // Return false to prevent upload if the file is too large
              }}
              onChange={onFileUploadClick}
              onRemove={() => onChange(null)}
              showUploadList={{
                showRemoveIcon: false,
              }}
              {...moreProps}
              {...uploadProps}
            >
              <Button
                style={{
                  borderColor: "black",
                  background: "transparent",
                  padding: "12px 20px",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  height: "60px",
                  marginBottom: "8px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                  }}
                >
                  <img
                    src="/applicantImg/upload.png"
                    style={{ width: "36px", marginRight: "8px" }}
                  />
                  <p
                    style={{
                      color: "black",
                      fontSize: "16px",
                      fontWeight: "500",
                      margin: 0,
                    }}
                  >
                    {uploadStatus}
                  </p>
                </div>
              </Button>
            </Upload>
          </div>

          <span
            style={{
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            or
          </span>

          <div
            style={{
              flex: "1 1 -1%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              type="primary"
              onClick={(e) => {
                const fieldKey = e.currentTarget.dataset.fieldKey;

                askNextQus(fieldKey);

                setWordLengthData(wordLength);

                setChatBtnType(() => {
                  return fieldKey;
                });
                setShowChat(() => true);
              }}
              data-field-key={
                field?.name === "Essay File" ? field?.name : field?.key
              }
              style={{
                border: "none",
                background: "rgb(141, 150, 255)",
                padding: "12px 20px",
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                height: "60px",
                marginRight: "8px",
                marginTop: window.innerWidth < 900 && "8px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  whiteSpace: "nowrap",
                }}
              >
                <img
                  src="/applicantImg/robo_head.png"
                  alt="lennyLogo"
                  style={{ width: "36px", marginRight: "8px" }}
                />
                <p
                  style={{
                    color: "white",
                    fontSize: "16px",
                    fontWeight: "500",
                    margin: "0",
                  }}
                >
                  Take help of Assistant
                </p>
              </div>
            </Button>

            <Button
              onClick={showDrawer}
              style={{
                width: "100%",
                color: "#727EC4",
                margin: "0",
                lineHeight: "1px",
                fontSize: "16px",
                fontWeight: "500",
                cursor: "pointer",
                border: "none",
                display: existData ? "block" : "none",
              }}
            >
              <u>View Essay</u>
            </Button>
          </div>
        </div>
        {limitStr && (
          <Typography.Paragraph
            style={{
              color: "#8D96FF",
              marginTop: "8px",
              textDecoration: "underline",
              fontSize: "14px",
              fontWeight: "500",
              letterSpacing: "0.5px",
            }}
          >
            {limitStr}
          </Typography.Paragraph>
        )}
      </div>
      <Modal
        centered
        closable={false}
        visible={open}
        onCancel={onClose}
        footer={null}
        width="80%"
        bodyStyle={{
          height: "80vh",
          overflowY: "auto",
          padding: "0px",
          borderRadius: "24px",
        }}
        className="custom-modal"
      >
        <UploadEssay
          details={details}
          onSave={(val) => {
            // const getReqData = localStorage.getItem("reqData");
            // let reqData = JSON.parse(getReqData) || {};
            let reqData = filledData || {};

            // reqData = {
            //   ...reqData,
            //   [field?.key]: { text: val },
            // };
            // console.log(reqData);
            const localStorData = JSON.parse(localStorage.getItem("reqData"));

            if (localStorData) {
              const updateData = {
                ...localStorData,
                [fieldName]: { text: val },
                // [field?.key]: { text: val },
              };

              localStorage.setItem("reqData", JSON.stringify(updateData));
            } else {
              localStorage.setItem(
                "reqData",
                JSON.stringify({
                  [fieldName]: { text: val },
                })
              );
            }

            reqData[field?.fieldName] = { text: val };

            setFilledData(() => {
              return reqData;
            });

            onChange({ essay: val });
            onClose();
          }}
          onGoBack={onClose}
          existingValue={value?.text || (!value?.url ? value : "")}
          drawerOpen={open}
        />
      </Modal>
    </div>
  );
};

export default TextEditor;
