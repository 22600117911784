import React, { useState } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";

const MobileReqAss = ({ modalContent, setIsModalVisible, isModalVisible }) => {
  console.log(
    "🚀 ~ MobileReqAss ~ setIsModalVisible:",
    setIsModalVisible,
    isModalVisible
  );
  // const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1000,
        }}
        onClick={handleClose}
      ></div>
      <div
        style={{
          position: "fixed",
          bottom: "0px",
          margin: "0px",
          width: "98%",
          border: "2px solid rgb(141, 150, 255)",
          backgroundColor: "rgb(240, 241, 255)",
          padding: "10px",
          height: "50%",
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
          zIndex: 1001,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <p
              style={{
                fontWeight: "500",
                fontSize: "18px",
                color: "black",
                margin: "0",
              }}
            >
              Lenny's Assistant
            </p>
          </div>
          <div
            style={{
              fontSize: "21px",
              fontWeight: "bold",
              marginRight: "8px",
              cursor: "pointer",
            }}
            onClick={() => {
              setIsModalVisible(() => false);
            }}
          >
            <CloseCircleOutlined />
          </div>
        </div>
        {modalContent}
      </div>
    </>
  );
};

export default MobileReqAss;
