import { message } from "antd";
import React, { memo, useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Form } from "antd";
import { DeleteOutlined, ArrowUpOutlined } from "@ant-design/icons";
import BouncingDotsLoader from "components/layout-components/BouncingDotsLoader";
import ChatStructure from "components/layout-components/ChatStructure";
import { useAssitanceChatBotContext } from "context/ChatbotContext";
import { API_CHAT_URL } from "configs/AppConfig";
import DOMPurify from "dompurify";
// import './AssitanceChatBot.css'
const AssistanceChatBot = ({ dashbordHide = false }) => {
  const {
    hint,
    setHint,
    first,
    setFirst,
    chatType,
    messages,
    setMessages,
    render,
    setRender,
    setStartChat,
    hideIcons,
    setHideIcons,
    enableChat,
    setEnableChat,
    setMaximizeView,
    maximizeView,
    topRef,
    applyScholarshipId,
    setApplyScholarshipId,
    chatAssBtn,
    setChatAssBtn,
  } = useAssitanceChatBotContext();

  const inputRef = useRef(null);

  // const { handleScrollToTop } = useAssitanceChatBotContext()

  // const [enableChat, setEnableChat] = useState(false);
  // const [hideIcons, setHideIcons] = useState(false);
  const [scholarshipTryBtn, setScholarshipTryBtn] = useState(false);
  const [mobileView, setMobileView] = useState(window.innerWidth < 915);
  const messagesEndRef = useRef(null);
  const userInfo = localStorage.getItem("userInfo");
  const userData = JSON.parse(userInfo);
  const token = localStorage.getItem("token");
  const history = useHistory();
  const [profileUpdate, setProfileUpdate] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);
  const [inputField, setInputField] = useState("");

  const toggleMaximize = () => {
    setMaximizeView(!maximizeView);
    setIsMaximized(!isMaximized);
  };
  useEffect(() => {
    // console.log(chatType)
    if (render) {
      const yesFn = () => {
        console.log("object");
        chatThreeDotLoading(
          true,
          null,
          "Here are some recommended scholarships according to your profile."
        );
        // applyScholarship(
        //   "recommendation",
        //   "Hi, can you please suggest some recommended scholarships for me? "
        // );

        applyScholarship(
          "easy_apply",
          "Hi, can you please suggest some easy apply scholarships for me? "
        );
      };
      const noFn = () => {
        chatThreeDotLoading(true, null, "You can ask questions");
      };

      // const text = [
      //   {
      //     chat_type: "yesNoBtns",
      //     data: `Hi ${userData.firstName}, Do you need any help in applying the scholarships?`,
      //     extra: {
      //       yesFn: yesFn,
      //       noFn: noFn,
      //     },
      //   },
      // ];

      chatThreeDotLoading(
        true,
        null,
        `Hi ${userData.firstName}, I'm Lenny your friendly AI assistant.`
      );
      chatThreeDotLoading(
        true,
        null,
        `Do you need any help in applying the scholarships?`
      );
      setHint("Yes...");
      setRender(false);
      // setChatAssBtn(true)

      // if (chatType == "recommedation") {
      //   applyScholarship(
      //     "recommendation",
      //     "Hi, can you please suggest some recommended scholarships for me? "
      //   );
      //   setRender(false);
      // }
      // if (chatType == "applyScholarship") {
      //   // chatThreeDotLoading(true, null, `Hi ${userData.firstName}, Do you need any help in applying the scholarships?`)
      //   // chatThreeDotLoading(true, null, `Here are some recommended scholarships according to your profile.`)
      //   applyScholarship(
      //     "easy_apply",
      //     "Hi, can you please suggest some easy apply scholarships for me? "
      //   );
      //   setRender(false);
      // }

      // setRender(false)
    }
  }, [chatType]);

  useEffect(() => {
    const handleResize = () => {
      setMobileView(window.innerWidth < 915);
    };
    window.addEventListener("resize", handleResize);

    if (messages.length > 0) {
      setHideIcons(true);
      setEnableChat(true);
    }
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [messages]);

  const handleScrollToTop = () => {
    if (topRef.current) {
      topRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const boxStyle = {
    border: "2px solid rgb(141, 150, 255)",
    borderRadius: "10px",
    width: "100%",
    alignItems: "center",
    flexShrink: "0",
    flexBasis: "25%",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    padding: "5px",
    textAlign: "center",
    background: "transparent",
  };

  //chat structure function to show chat in chatbot
  const chatStructure = (text, sender, extra) => {
    if (text == "loading") {
      return <BouncingDotsLoader />;
    } else if (text?.[0]?.chat_type == "scholarshipList") {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ChatStructure
            type="scholarshipList"
            scholarshipData={text[0]?.data}
            dashbordHide
          />
          <div>
            <div
              style={{
                display: scholarshipTryBtn
                  ? "none"
                  : text[0]?.data.length === 0
                  ? "none"
                  : "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <button
                style={{
                  width: "auto",
                  background: "white",
                  border: "none",
                  // background: "transparent",
                  // border: "2px solid #8d96ff",
                  padding: "8px 20px",
                  fontSize: "13px",
                  fontWeight: "600",
                  cursor: "pointer",
                  marginRight: "20px",
                }}
                onClick={() => {
                  applyScholarship(
                    "easy_apply",
                    "Show me some easy apply scholarships based on my profile "
                  );
                }}
              >
                Easy Apply
              </button>
              <button
                style={{
                  width: "auto",
                  background: "white",
                  border: "none",
                  padding: "8px 20px",
                  fontSize: "13px",
                  fontWeight: "600",
                  cursor: "pointer",
                  marginRight: "20px",
                }}
                onClick={async () => {
                  applyScholarship(
                    "essay_only",
                    "Suggest me some  scholarship with apply with cv only. "
                  );
                }}
              >
                Apply Scholarships with CV Only
              </button>
            </div>
          </div>
          <div
            style={{
              display: text[0]?.data.length === 0 ? "block" : "none",
            }}
          >
            <div
              style={{
                backgroundColor:
                  sender === "receiver"
                    ? text === "loading"
                      ? "transparent"
                      : "white"
                    : "#D3D6FF",
                padding: "10px",
                fontSize: mobileView ? "12px" : "12px",
                fontWeight: "500",
                maxWidth: sender === "receiver" ? "90%" : "90%",
                whiteSpace: "normal",
                overflowWrap: "break-word",
                wordWrap: "break-word",
                borderRadius: "8px",
              }}
            >
              Oops!! No Scholarship Found. Select from the other options.
              {/* {setChatBtn(true)} */}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <button
                style={{
                  width: "auto",
                  background: "white",
                  border: "none",
                  padding: "8px 20px",
                  fontSize: "13px",
                  fontWeight: "600",
                  cursor: "pointer",
                  marginRight: "20px",
                }}
                onClick={() => {
                  applyScholarship(
                    "easy_apply",
                    "Show me some easy apply scholarships based on my profile "
                  );
                }}
              >
                Easy Apply
              </button>
              <button
                style={{
                  width: "auto",
                  background: "white",
                  border: "none",
                  padding: "8px 20px",
                  fontSize: "13px",
                  fontWeight: "600",
                  cursor: "pointer",
                  marginRight: "20px",
                }}
                onClick={async () => {
                  applyScholarship(
                    "recommendation",
                    "recommendeded scholarships "
                  );
                }}
              >
                Best Recommended Scholarships
              </button>
            </div>
          </div>
        </div>
      );
    } else if (text?.[0]?.chat_type == "userDetails") {
      return (
        <div
          style={{
            maxWidth: "97%",
          }}
        >
          <ChatStructure
            type="userDetails"
            dashbordHide
            setChatBtn={setChatAssBtn}
            applyScholarship={applyScholarship}
            chatThreeDotLoading
            setProfileUpdate={setProfileUpdate}
          />
        </div>
      );
    } else if (text?.[0]?.chat_type === "yesNoBtns") {
      return (
        <div style={{}}>
          <div
            style={{
              backgroundColor:
                sender === "receiver"
                  ? text === "loading"
                    ? "transparent"
                    : "white"
                  : "#D3D6FF",
              padding: "10px",
              fontSize: mobileView ? "12px" : "12px",
              fontWeight: "500",
              maxWidth: sender === "receiver" ? "90%" : "90%",
              whiteSpace: "normal",
              overflowWrap: "break-word",
              wordWrap: "break-word",
              borderRadius: "8px",
            }}
          >
            {text?.[0]?.data}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
              width: "80%",
            }}
          >
            <button
              onClick={() => {
                text[0]?.extra.yesFn();
              }}
              style={{
                width: "max-content",
                background: "white",
                border: "none",
                color: "black",
                fontWeight: "600",
                cursor: "pointer",
              }}
            >
              Yes
            </button>
            <button
              onClick={() => {
                text[0].extra?.noFn();
              }}
              style={{
                width: "max-content",
                background: "white",
                border: "none",
                color: "black",
                fontWeight: "600",
                cursor: "pointer",
              }}
            >
              No
            </button>
          </div>
        </div>
      );
    } else if (text[0]?.chat_type === "html_string") {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(processHtmlString(text[0]?.data)),
          }}
          style={{
            backgroundColor:
              sender === "receiver"
                ? text === "loading"
                  ? "transparent"
                  : "white"
                : "#D3D6FF",
            padding: "10px",
            fontSize: mobileView ? "12px" : "12px",
            fontWeight: "500",
            maxWidth: sender === "receiver" ? "90%" : "90%",
            whiteSpace: "normal",
            overflowWrap: "break-word",
            wordWrap: "break-word",
            borderRadius: "8px",
          }}
        />
      );
    } else {
      return (
        <div
          style={{
            backgroundColor:
              sender === "receiver"
                ? text === "loading"
                  ? "transparent"
                  : "white"
                : "#D3D6FF",
            padding: "10px",
            fontSize: mobileView ? "12px" : "12px",
            fontWeight: "500",
            maxWidth: sender === "receiver" ? "90%" : "90%",
            whiteSpace: "normal",
            overflowWrap: "break-word",
            wordWrap: "break-word",
            borderRadius: "8px",
          }}
        >
          {text}
        </div>
      );
    }
  };

  //message component to render messages
  const Message = ({ sender, text, extra }) => (
    <div
      className={`message ${
        sender === "receiver" ? "message-left" : "message-right"
      }`}
      // ref={messagesEndRef}
      style={{
        width: "100%",
        justifyContent: sender === "receiver" ? "normal" : "flex-end",
        overflowX: "hidden",
      }}
      ref={messagesEndRef}
    >
      {sender === "receiver" && (
        <span
          style={{
            display: "inline-block",
            width: "30px",
            height: "37px",
            marginRight: "5px",
            backgroundImage: `url('/new/chatrob.png')`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        ></span>
      )}
      {false ? (
        <BouncingDotsLoader />
      ) : (
        <>{chatStructure(text, sender, extra)}</>
      )}
      {sender === "user" && (
        <span
          style={{
            display: "inline-block",
            width: "25px",
            height: "37px",
            marginLeft: "5px",
            backgroundImage: `url('/new/peroson.png')`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        ></span>
      )}
    </div>
  );
  //general function to append message in the messages array
  const updateMsg = (senderType, text) => {
    const updatedMessages = [...messages, { sender: senderType, text: text }];
    setMessages(() => {
      return updatedMessages;
    });

    return updatedMessages;
  };

  const chatThreeDotLoading = async (
    skipCall,
    apiCall,
    msg,
    chatBtnState = false
  ) => {
    setChatAssBtn(true);
    const loadingMessage = {
      sender: "receiver",
      text: "loading",
    };
    setMessages((prevMessages) => [...prevMessages, loadingMessage]);

    try {
      let responseText;
      if (skipCall) {
        responseText = msg;
      } else {
        responseText = await apiCall();
      }
      // setTimeout(() => {
      // Update the message with the API response
      setMessages((prevMessages) => {
        // Remove the loading message
        const newMessages = prevMessages.filter((e) => e.text !== "loading");
        // Add the new message with the API response
        return [...newMessages, { sender: "receiver", text: responseText }];
      });
      // }, 500);
    } catch (error) {
      // Handle any errors
      console.error("API call failed:", error);

      // Update the message with an error message
      setMessages((prevMessages) => {
        // Remove the loading message
        const newMessages = prevMessages.filter((e) => e.text !== "loading");
        // Add an error message
        return [
          ...newMessages,
          { sender: "receiver", text: "Error loading response" },
        ];
      });

      // setChatBtn(false);
    } finally {
      setChatAssBtn(() => {
        return false;
      });
    }
  };

  const askUserDetails = async () => {
    const newText = {
      sender: "receiver",
      text: [
        {
          chat_type: "userDetails",
          data: "Nothing",
        },
      ],
    };
    setMessages((prevMessages) => {
      const newMessages = [...prevMessages];

      return [...newMessages, newText];
    });
  };

  const applyScholarship = async (type, qus) => {
    const userInfo = localStorage.getItem("userInfo");
    const userData = JSON.parse(userInfo);

    const userBody = {
      userID: userData.id,
      questionAnswer: {
        scholarship_type: type,
        question: qus,
      },
    };
    setHideIcons(true);
    setEnableChat(true);
    // updateMsg("user", qus);

    if (!userData?.isProfileUpdate) {
      await chatThreeDotLoading(
        true,
        null,
        `Hi ${userData?.firstName}, to get your personalised scholarships, you need to complete your signup details first.`
      );
      // setChatBtn(true);

      await askUserDetails();
      return;
    }

    // await chatThreeDotLoading(
    //   true,
    //   null,
    //   `Hi ${userData?.firstName}, here are some scholarships according to your interest.`
    // );
    first
      ? await chatThreeDotLoading(
          false,
          () => initiateSholarshipApplyId(userBody),
          ""
        )
      : await chatThreeDotLoading(
          false,
          () => scholarshipChatFlow(applyScholarshipId, userBody),
          ""
        );
    // first
    //   ? await initiateSholarshipApplyId(userBody)
    //   : await scholarshipChatFlow(applyScholarshipId, userBody);
  };
  // console.log(first)

  const writeEssay = () => {
    setHideIcons(true);
    setEnableChat(true);
    setMessages(() => {
      return [];
    });
    updateMsg("user", "Write Essay");
    message.error("This feature not available yet!!");
  };

  if (profileUpdate) {
    applyScholarship(
      "recommendation",
      "Hi, can you please suggest some scholarships for me? "
    );
    setProfileUpdate(false);
  }

  const initiateSholarshipApplyId = async (bodyData) => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(`${API_CHAT_URL}api/scholarships/chats`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (response.status === 201) {
        setFirst(false);
        setApplyScholarshipId(data?.chat_id);
        let text;

        if (data?.data_type === "list") {
          text = [
            {
              chat_type: "scholarshipList",
              data: data?.data,
            },
          ];
          setHint("Recommended Scholarships...");
        } else if (data?.data_type === "html_string") {
          text = [
            {
              chat_type: "html_string",
              data: data?.data.answer,
            },
          ];
          setHint("Easy Apply Scholarships...");
        } else {
          text = data?.data.answer;
          setHint("Tell me how lenny can help me...");
        }

        // await chatThreeDotLoading(true, null, text);
        return text;
      }
    } catch (error) {
      console.log(
        "error while fetching scholarship list in lenny's assistace",
        error
      );
      message.error("Some Error Occured!!!");
      clearChat();
      return "Some error occured!!!";
    }
  };

  function processHtmlString(htmlString) {
    // Remove the ```html from the start and end
    const cleanHtmlString = htmlString
      .replace(/^```html\n/, "")
      .replace(/```$/, "");

    // Create a temporary DOM element to parse the HTML
    const parser = new DOMParser();
    const doc = parser.parseFromString(cleanHtmlString, "text/html");

    // Apply the style to all <p> elements
    const paragraphs = doc.querySelectorAll("p");
    paragraphs.forEach((p) => {
      p.style.color = "black";
    });

    // Convert the modified DOM back to an HTML string
    return doc.body.innerHTML;
  }

  const scholarshipChatFlow = async (chatId, bodyData) => {
    try {
      const response = await fetch(
        `${API_CHAT_URL}api/scholarships/chats?id=${chatId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (response.status === 201) {
        setApplyScholarshipId(data?.chat_id);

        let text;

        if (data?.data_type === "list") {
          text = [
            {
              chat_type: "scholarshipList",
              data: data?.data,
            },
          ];
          setHint("Recommended Scholarships...");
        } else if (data?.data_type === "html_string") {
          text = [
            {
              chat_type: "html_string",
              data: data?.data.answer,
            },
          ];
          setHint("Easy Apply Scholarships...");
        } else {
          text = data?.data.answer;
          setHint("Tell me how lenny can help me...");
        }

        return text;
      }
    } catch (error) {
      console.log(
        "error while fetching scholarship list in lenny's assistace",
        error
      );
      message.error("Some Error Occured!!!");
      return "Some error occured";
    }
  };
  const clearChat = () => {
    setMaximizeView(false);
    setStartChat(false);
    setHideIcons(false);
    setEnableChat(false);
    setMessages([]);
    setRender(true);
  };

  const sendQuestions = () => {};

  const submitChat = () => {
    const userBody = {
      userID: userData.id,
      questionAnswer: {
        scholarship_type: "userInput",
        question: inputField,
      },
    };
    updateMsg("user", inputField);
    setInputField("");

    if (applyScholarshipId) {
      chatThreeDotLoading(
        false,
        () => scholarshipChatFlow(applyScholarshipId, userBody),
        ""
      );
    } else {
      chatThreeDotLoading(false, () => initiateSholarshipApplyId(userBody), "");
    }
    // chatThreeDotLoading(false, () => scholarshipChatFlow(applyScholarshipId, userBody), "")
  };

  return (
    <div
      style={{
        backgroundColor: "rgb(240, 241, 255)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderTopLeftRadius: "17px",
        borderTopRightRadius: "17px",
        border: "2px solid rgb(141, 150, 255)",
        borderBottom: "0",
        // width: "97%",
        width: "-webkit-fill-available",
        height: mobileView ? "auto" : "auto",
        margin: mobileView ? "12px auto" : "auto",
        position: mobileView ? "static" : "absolute",
        bottom: "0",
        alignItems: "stretch",
        paddingBottom: "20px",
        marginRight: mobileView ? "0" : maximizeView ? "8px" : "0",
      }}
      className={`chatbot-container ${isMaximized ? "maximized" : "minimized"}`}
      // ref={topRef}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          padding: "10px 20px",
          borderBottom: "2px solid rgb(141, 150, 255)",
        }}
      >
        <h3
          style={{
            margin: "0",
            fontFamily: "Poppins",
          }}
        >
          Lenny's AI
        </h3>

        <div>
          <DeleteOutlined
            style={{
              cursor: "pointer",
              fontSize: "20px",
              // display: history.location.pathname.includes("assistanceChat")
              //   ? "inline"
              //   : "none",
            }}
            onClick={clearChat}
          />
          <img
            src="/new/arr.png"
            style={{
              width: "20px",
              marginLeft: "20px",
              cursor: "pointer",
              display: history.location.pathname.includes("assistanceChat")
                ? "inline"
                : "inline",
            }}
            alt="icon_image"
            title="Maximize"
            onClick={() => {
              if (history.location.pathname.includes("assistanceChat")) {
                history.push("/auth/dashboard");
              } else {
                toggleMaximize();
              }
              //  else if (history.location.pathname.includes("dashboard")) {
              //   history.push("/auth/dashboard/assistanceChat");
              // } else {
              //   history.push("/auth/dashboard/assistanceChat");
              // }
              toggleMaximize();
            }}
          />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            display: hideIcons ? "none" : "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: mobileView ? "90%" : "50%",
            margin: "auto",
            height: "40%",
          }}
        >
          <h1
            style={{
              fontFamily: "Poppins",
              textAlign: "center",
            }}
          >
            Welcome to Lenny's Assistant
          </h1>
          <p
            style={{
              textAlign: "center",
              color: "black",
              fontFamily: "Poppins",
              fontWeight: "500",
            }}
          >
            I'm Lenny, your friendly AI assistant here to help. Whether you have
            questions, need assistance, or just want to chat, I'm here
            24/7.Let's get started - feel free to ask me anything!
          </p>
        </div>
        <div
          style={{
            height: "50%",
            display: hideIcons ? "none" : "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              width: mobileView ? "50%" : "25%",
              margin: mobileView ? "auto" : "0 75px",
            }}
          >
            <img
              src="/new/chatrobo1.png"
              alt=""
              style={{
                width: "100%",
              }}
            />
          </div>
          <div
            style={{
              display: hideIcons ? "none" : "block",
              width: mobileView ? "95%" : "60%",
              margin: "auto",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: mobileView ? "column" : "row",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              <div style={{ flex: "1 1 auto" }}>
                <button
                  onClick={() => {
                    applyScholarship(
                      "recommendation",
                      "Show me some recommended scholarships based on my profile."
                    );
                  }}
                  style={{
                    ...boxStyle,
                    color: "black",
                    margin: "0",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  Best Recommended Scholarships
                </button>
              </div>

              <div style={{ flex: "1 1 auto" }}>
                <button
                  onClick={() => {
                    applyScholarship(
                      "easy_apply",
                      "Show me some easy apply scholarships based on my profile."
                    );
                  }}
                  style={{
                    ...boxStyle,
                    color: "black",
                    margin: "0",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  Easy Apply
                </button>
              </div>

              <div style={{ flex: "1 1 auto" }}>
                <button
                  onClick={() => {
                    applyScholarship(
                      "essay_only",
                      "Suggest me some scholarships to apply with CV only."
                    );
                  }}
                  style={{
                    ...boxStyle,
                    color: "black",
                    margin: "0",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  Apply Scholarships with CV Only
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* {enableChat && (
          <Scrollbars
            style={{
              width: "100%",
              height: maximizeView
                ? "515px"
                : dashbordHide
                  ? "calc( 100vh - 360px)"
                  : mobileView
                    ? "calc(100vh - 215px)"
                    : "calc(100vh - 215px)",
              marginBottom: "10px",
              display: "flex",
              backgroundImage: 'url("/new/chatbot-support-2.png")',
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              top: "8px",
              overflowX: "hidden",
            }}
          >
            {enableChat &&
              messages.map((message, index) => {
                return (
                  <Message
                    key={index}
                    sender={message.sender}
                    text={message.text}
                    extra={message.extra}
                  />
                );
              })}
          </Scrollbars>
        )} */}
        {enableChat && (
          <Scrollbars
            style={{
              width: "100%",
              height: maximizeView
                ? "515px"
                : dashbordHide
                ? "calc( 100vh - 360px)"
                : mobileView
                ? "calc(100vh - 215px)"
                : "calc(100vh - 215px)",
              marginBottom: "10px",
              display: "flex",
              position: "relative", // Add relative positioning for the overlay
              backgroundImage: 'url("/new/chatbot-support-2.png")',
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              top: "8px",
              overflowX: "hidden",
            }}
          >
            {/* Semi-transparent overlay */}
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(255, 255, 255, 0.5)", // Adjust opacity here
                zIndex: 1,
                pointerEvents: "none", // Allow interactions with the content below
              }}
            ></div>

            {/* Your chat messages */}
            <div style={{ position: "relative", zIndex: 2, width: "100%" }}>
              {enableChat &&
                messages.map((message, index) => {
                  return (
                    <Message
                      key={index}
                      sender={message.sender}
                      text={message.text}
                      extra={message.extra}
                    />
                  );
                })}
            </div>
          </Scrollbars>
        )}

        <Form onFinish={submitChat}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
              width: "100%",
              alignItems: "center",
              marginBottom: "0px !important",
            }}
          >
            <input
              ref={inputRef}
              type="text"
              style={{
                flex: "1",
                margin: mobileView ? "10px" : "0 10px",
                padding: "8px",
                fontSize: "14px",
                flexBasis: "90%",
              }}
              className="chatInp"
              placeholder={hint ? hint : "Recommneded Scholarships..."}
              value={inputField}
              onChange={(e) => setInputField(e.target.value)}
              required
            />

            <button
              style={{
                flex: "1 1 4%",
                cursor: "pointer",
                border: "none",
                padding: "0",
                margin: "0",
                cursor: chatAssBtn ? "not-allowed" : "pointer",
                background: "transparent",
              }}
              disabled={chatAssBtn}
              type="submit"
            >
              <img
                src="/new/forward.png"
                alt="Button Image"
                style={{
                  width: "35px",
                  opacity: chatAssBtn ? "0.5" : "1",
                  // cursor: chatBtn ? "not-allowed" : "pointer",
                }}
              />
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default memo(AssistanceChatBot);
